import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERIFY_ID } from "../../../config/api";

import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import { fetchUserProfile } from "./getProfile";
import { toast } from "react-toastify";

export const verifyID = createAsyncThunk(
  "profile/verifyID",
  async ({ data, cb, failed }, { dispatch }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_VERIFY_ID,
        data,
      });

      dispatch(fetchUserProfile());
      cb();
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);

      failed(msg);
    }
  }
);

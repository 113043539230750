import React from "react";
import Loader from "../Utils/Loader";

const AuthButton = ({
  mainClasses,
  title,

  height,
  bg,
  color,
  leftIcon,
  rightIcon,
  textStyles,
  onClick,
  loading,
  disabled,
}) => {
  return (
    <button
      onClick={onClick}
      className={`bg-[#6935D3] hover:scale-[99%] transition ease-in-out delay-[50ms] hover:bg-[#6935D3]/90  duration-[500ms] rounded-[8px] mt-10 mb-[15px] w-full lg:w-[493px] h-[45px] text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline shadow-custom ${mainClasses}`}
      type="button"
    >
      {loading ? <Loader size={"small"} /> : title}
    </button>
  );
};

export default AuthButton;

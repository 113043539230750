import React from "react";
import real1 from "../Assets/Images/real1.png";
import real2 from "../Assets/Images/real2.png";
import real3 from "../Assets/Images/real3.png";
import real4 from "../Assets/Images/real4.png";

const PropertyImages = () => {
  return (
    <div className="p-y-5 gap-y-4 w-[530px] flex flex-wrap justify-between items-center">
      <img
        src={real1}
        alt="Property 1"
        className="w-[251px] h-[219px] rounded-[10px]"
      />
      <img
        src={real2}
        alt="Property 2"
        className="w-[251px] h-[219px] rounded-[10px]"
      />
      <img
        src={real3}
        alt="Property 3"
        className="w-[251px] h-[219px] rounded-[10px]"
      />
      <img
        src={real4}
        alt="Property 4"
        className="w-[251px] h-[219px] rounded-[10px]"
      />
    </div>
  );
};

export default PropertyImages;

import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_LOGIN } from "../../../config/api";
import { toast } from "react-toastify";
import { resendOTP } from "./resendotp";
// import { processErrorMessage } from "../../../Helper/processErrorMessage";
import mixpanel from "mixpanel-browser";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import {
  removeAccessToken,
  storeAccessToken,
} from "../../../helper/manageAccessToken";
import { setUserForOnboarding } from "../../reducers/onboardingSlice";

export const login = createAsyncThunk(
  "users/login",
  async (
    { data: payload, navigate, cb, setSubmitting },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const {
        data: { data },
      } = await Client({
        method: "POST",
        path: API_LOGIN,
        data: payload,
      });
      removeAccessToken();

      const user = data?.user;

      if (data?.access_token && !data?.user?.email_verified) {
        storeAccessToken(data?.access_token);

        dispatch(resendOTP({ data: { email: user?.email } }));

        dispatch(setUserForOnboarding(user));
        navigate("/otp", {
          state: { email: user?.email },
        });
      } else if (data && data?.access_token) {
        const { access_token } = data;

        storeAccessToken(access_token);

        mixpanel.identify(user?.email);
        mixpanel.register({ email: user?.email });
        mixpanel.people.set({
          $name: user?.name,
          $email: user?.email,
          // $created: new Date(),
        });
        mixpanel.track("Portal-Login", {});

        cb();

        return data;
      } else {
        setSubmitting(false);

        toast.error("Login failed: Access token not found in the response");
        mixpanel.track("Error", {
          type: "onboarding",
          subtype: "login",
          message: "Access token not found in the response",
        });
        return rejectWithValue("Access token not found in the response");
      }
    } catch (error) {
      setSubmitting(false);
      const msg = proccessErrorMessage(error);
      if (
        msg ===
        "You account has been temporarily suspended because you entered invalid password multiple times"
      ) {
        cb(msg, payload?.email);
      } else {
        toast.error(
          msg?.includes("attempts(s)") && !msg?.includes("5 attempts(s) left")
            ? msg
            : "Login failed: Invalid email or password"
        );
      }
      mixpanel.track("Error", {
        type: "onboarding",
        subtype: "login",
        message: msg,
      });
      return rejectWithValue(
        error.response?.data || "An unexpected error occurred"
      );
    }
  }
);

import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SEO from "../../config/Seo";
import Navbar from "../../Components/Navbar";
import VerificationForm from "../../Components/VerificationForm";

const OTP = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const location = useLocation();

  const email = location?.state?.email;
  useEffect(() => {
    const message = queryParams.get("message");

    if (message) {
      // toast.error(message);
    }
    return () => {
      if (message) {
        navigate("/");
      }
    };
  }, []);

  return (
    <>
      <SEO
        title={"Login | Access Your Ethical Finance Investments | Halvest"}
        description={
          "Log in to your Halvest account and explore ethical finance and Islamic banking solutions."
        }
        name={"Halvest"}
        type={"article"}
      />
      <div className="flex flex-col  min-h-screen">
        <Navbar type={2} />
        <div className="flex flex-col flex-1 justify-between">
          <div className="mx-auto mt-[2.5%] mb-[3%] flex gap-3 ">
            <div
              onClick={() => {
                // navigate(-1);
              }}
              className="w-[68px] h-[9px]   bg-[#6935D3] rounded-[30px] "
            ></div>
            <div className="w-[68px] h-[9px]   bg-[#6935D3] rounded-[30px] "></div>
            <div className="w-[68px] h-[9px]   bg-[#F1EDFB] rounded-[30px] transition-all ease-in-out duration-100  hover:bg-[#6935D3]"></div>
          </div>
          <div className="w-full flex-col md:flex-row flex  justify-center gap-10">
            <div className="px-6  py-6  flex flex-col lg:items-end flex-1">
              <h2 className="font-[500] lg:leading-[92.4px] lg:w-[600px] tracking-[-3.1%] text-[#131E3D]  text-[50px] leading-[50px] lg:text-[84px]">
                You’ve got
                <br />
                mail
              </h2>
              <h4 className="font-[400]  mt-3 text-[16px] lg:w-[600px] text-[#9C9C9E] tracking-[-1%] leading-[16.8px]">
                We’ve just sent a verification code to{" "}
                <span className="font-[400]  text-[16px] text-[#131E3D] leading-[19.2px]">
                  {" "}
                  {email}{" "}
                </span>
                <br />
              </h4>
              <h4
                onClick={() => {
                  navigate(-1);
                }}
                className="font-[500] mt-1 cursor-pointer lg:w-[600px] text-[16px] text-[#6935D3] leading-[19.2px]"
              >
                {" "}
                Wrong email?{" "}
              </h4>
            </div>
            <VerificationForm />
          </div>
          <h4 className="font-[400]  self-end justify-self-end mx-auto max-w-[90%] my-8 text-sm text-[#9C9C9E] tracking-[-0.4%]">
            The information on this website is provided for informational
            purposes only and does not constitute investment advice. You should
            not make any investment decisions based solely on the information on
            this website. You should consult with a qualified financial advisor
            before making any investment decisions. This website may contain
            links to third-party websites. These links are provided for your
            convenience only and do not constitute an endorsement by Halvest of
            the content on such websites. Halvest is not responsible for the
            content of any linked website. This website and these terms of use
            shall be governed by and construed in accordance with the laws of
            [Your Jurisdiction], without regard to its conflict of laws
            provisions.
          </h4>
        </div>
      </div>
    </>
  );
};

export default OTP;

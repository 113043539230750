import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { LiaAngleLeftSolid } from "react-icons/lia";
import { useLocation, useNavigate } from "react-router-dom";
import TransactionTable from "../../Components/TransactionTable";
import RecentTransaction from "../../Components/RecentTransaction";
import { FiMoreVertical } from "react-icons/fi";
import PortfoliosCard from "../Auth/PortfoliosCard";
import { FaBuilding } from "react-icons/fa";
import PortfolioDateModal from "../../Components/PortfolioDateModal"; // Import the modal
import Statement from "../../Components/Statement";
import { getPortfolio } from "../../store/actions/portfolio/getportfolio";
import { useDispatch, useSelector } from "react-redux";
import { formatAmount } from "../../utils/formatAmount";
import Switcher from "../../Components/Switcher";
import Loader from "../../Components/Utils/Loader";
import PortfolioTypeBusiness from "./PortfolioType";

const Portfolio = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  // const [currency, setCurrency] = useState({
  //   label: "Naira Porfolio",
  //   value: "NGR",
  // });

  const { state } = useLocation();

  const current = state?.current;
  const currency = state?.currency;

  // const [current, setCurrent] = useState(null);
  const { user } = useSelector((state) => state.onboarding);

  const [showTransactionTable, setShowTransactionTable] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("button1");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { portfolio, pending: portfolioPending } = useSelector(
    (state) => state.portfolio
  );

  const Total_Investment = portfolio?.reduce(
    (sum, item) => sum + +item?.amount,
    0
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPortfolio(currency?.value || currency));
  }, [currency]);

  const [insightItems, setInsightItems] = useState([]);

  useEffect(() => {
    let mapped = [];

    portfolio?.forEach((item) => {
      if (
        item?.investment_category?.name !== "SME Financing" &&
        item?.investment_category?.name !== "Real Estate" &&
        item?.investment_category?.name !== "Startup Investing" &&
        item?.investment_category?.name !== "Exchange Traded Commodities" &&
        mapped.find((_item) => _item.name === "Others")
      ) {
        mapped = mapped.map((_item) => {
          const newValue = _item?.amount + +item?.amount;
          return _item.name === "Others"
            ? {
                ..._item,
                amount: newValue,
                value: (newValue / Total_Investment) * 100,
              }
            : _item;
        });
      } else if (
        item?.investment_category?.name !== "SME Financing" &&
        item?.investment_category?.name !== "Real Estate" &&
        item?.investment_category?.name !== "Startup Investing" &&
        item?.investment_category?.name !== "Exchange Traded Commodities" &&
        !mapped.find((_item) => _item.name === "Others")
      ) {
        mapped.push({
          name: "Others",
          amount: +item?.amount,
          value: Number(((+item?.amount / Total_Investment) * 100).toFixed(2)),
          color: "#E04F16",
        });
      } else if (
        mapped.find((_item) => _item.name === item?.investment_category?.name)
      ) {
        mapped = mapped.map((_item) => {
          const newValue = _item?.amount + +item?.amount;
          return _item.name === item?.investment_category?.name
            ? {
                ..._item,
                amount: newValue,
                value: (newValue / Total_Investment) * 100,
              }
            : _item;
        });
      } else {
        mapped.push({
          name: item?.investment_category?.name,
          amount: +item?.amount,
          value: Number(((+item?.amount / Total_Investment) * 100).toFixed(2)),
          color:
            item?.investment_category?.name === "SME Financing"
              ? "#7A5AF8"
              : item?.investment_category?.name === "Real Estate"
              ? "#C11574"
              : item?.investment_category?.name === "Startup Investing"
              ? "#47CD89"
              : item?.investment_category?.name ===
                "Exchange Traded Commodities"
              ? "#175CD3"
              : "E04F16",
        });
      }
    });
    setInsightItems(mapped);
  }, [portfolio]);

  useEffect(() => {
    if (!current || !currency) {
      navigate(-1);
    }
  }, []);

  return current ? (
    <PortfolioTypeBusiness
      type={current}
      _currency={currency}
      back={() => {
        // setCurrent(null);
        navigate(-1);
      }}
    />
  ) : (
    <></>
    // <div className="flex flex-col bg-[#F9FAFA] items-center min-h-screen">
    //   <Navbar type={3} />

    //   <div className="w-[95%] max-w-[1664px] border-[#F2F2F2] border-[1.1px] shadow-md rounded-[12px] bg-[#FFF] p-6 md:p-10 flex flex-col gap-5 mt-5">
    //     <div
    //       onClick={() => navigate(-1)}
    //       className="flex cursor-pointer items-center gap-2"
    //     >
    //       <LiaAngleLeftSolid size={18} color="#131E3D" />
    //       <h3 className="text-[14px] font-[400] text-[#131E3D]">Back</h3>
    //     </div>

    //     <div className="flex flex-col md:flex-row items-center gap-4 mt-3">
    //       <div className="text-[36px] md:text-[54px] font-[400] text-[#131e3d]">
    //         Portfolio
    //       </div>
    //       <Switcher
    //         handleCurrencyChange={setCurrency}
    //         label={
    //           currency?.value === "NGR" ? "Naira Porfolio" : "USD Porfolio"
    //         }
    //         options={[
    //           { label: "Naira Porfolio", value: "NGR" },
    //           { label: "USD Porfolio", value: "USD" },
    //         ]}
    //       />
    //       <button
    //         onClick={() => setIsModalOpen(true)}
    //         className="w-[180px] md:w-[203px] h-[45px] bg-[#6935D3] rounded-[8px] text-[16px] text-white flex justify-center items-center"
    //       >
    //         Investment Statement
    //       </button>
    //     </div>

    //     <div className="w-[343px] mt-[20px] shadow-custom p-2 h-[49px] border-[1px] rounded-[41px] flex justify-center items-center">
    //       <div className="flex justify-center items-center gap-2">
    //         <button
    //           className={`py-1 font-[400] w-[154px] h-[37px] px-1 rounded-[40px] ${
    //             activeTab === "button1" ? "bg-[#F1EDFB] text-[#6935D3]" : ""
    //           }`}
    //           onClick={() => setActiveTab("button1")}
    //         >
    //           Active Investments
    //         </button>
    //         <button
    //           className={`w-[164px] font-[400] h-[37px] py-1 px-1 rounded-[40px] ${
    //             activeTab === "button2" ? "bg-[#F1EDFB] text-[#6935D3]" : ""
    //           }`}
    //           onClick={() => setActiveTab("button2")}
    //         >
    //           Paid Out Investments
    //         </button>
    //       </div>
    //     </div>

    //     {portfolioPending ? (
    //       <div className="h-[50vh] flex justify-center items-center">
    //         <Loader />
    //       </div>
    //     ) : activeTab === "button1" ? (
    //       <div className="flex min-h-[60vh]  items-start">
    //         <div className="w-full ">
    //           <div className="flex justify-start items-center gap-[10px] flex-wrap">
    //             {insightItems?.map((insightItem, index) => {
    //               return (
    //                 <PortfoliosCard
    //                   key={index}
    //                   icon={FaBuilding}
    //                   category={insightItem?.name}
    //                   amount={formatAmount(insightItem?.amount)}
    //                   owner={user?.name}
    //                   portfolioPercentage={`${insightItem?.value}%`}
    //                   onClick={() => setCurrent(insightItem?.name)}
    //                 />
    //               );
    //             })}
    //           </div>
    //         </div>

    //         <div>
    //           <div className="w-[500px] ml-[5px] flex items-center justify-between mb-5 pr-1">
    //             <h4 className="font-[500] text-[#131E3D] text-base leading-[17.6px] tracking-[-1%]">
    //               Recent Transactions
    //             </h4>
    //             <FiMoreVertical
    //               className="cursor-pointer"
    //               size={20}
    //               color={"#131E3D"}
    //             />
    //           </div>

    //           <TransactionTable
    //             currency={
    //               currency?.value === "NGR" || currency === "NGR"
    //                 ? "Naira"
    //                 : "USD"
    //             }
    //           />
    //         </div>
    //       </div>
    //     ) : (
    //       <div className="flex min-h-[60vh]  "></div>
    //     )}
    //     {/* <Statement /> */}
    //   </div>

    //   {isModalOpen && (
    //     <PortfolioDateModal
    //       isOpen={isModalOpen}
    //       defaultCurrency={currency}
    //       onClose={() => setIsModalOpen(false)}
    //     />
    //   )}
    // </div>
  );
};

export default Portfolio;

import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { LiaAngleLeftSolid } from "react-icons/lia";
import { useLocation, useNavigate } from "react-router-dom";
import InvestmentTimelineSummary from "../../Components/InvestmentTimelineSummary";
import InvestmentDetailDates from "../../Components/InvesmentDetailDates";
import Capital from "../../Components/Capital";
import InvestmentDetailsTable from "../../Components/InvestmentDetailsTable";

const MyInvestmentSummary = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleToggleNotification = () => {
    setShowNotification((prev) => !prev);
  };

  return (
    <>
      <div className="flex flex-col bg-[#F9FAFA] gap-6 pt-3 pb-3 items-center min-h-screen">
        <Navbar type={3} handleNotification={handleToggleNotification} />

        <div className="w-[95%] h-full flex-wrap rounded-[12px] gap-10 p-6 md:p-10 bg-[#FFF] border-[#F2F2F2] border-[1.1px] drop-shadow-custom  max-w-[1664px] flex">
          <div className="flex w-full md:w-auto flex-col md:flex-[5]">
            <div
              onClick={() => navigate(-1)}
              className="flex cursor-pointer items-center"
            >
              <LiaAngleLeftSolid
                size={18}
                color="#131E3D"
                className="mx-[2px]  "
              />
              <h3 className="text-[14px] font-[400] text-[#131E3D]">Back</h3>
            </div>
            <Capital state={state} />
            <InvestmentDetailsTable state={state} />
          </div>
          <div className="flex flex-col md:flex-[4] w-full  md:w-auto  md:pt-6">
            <InvestmentTimelineSummary state={state} />
            <InvestmentDetailDates state={state} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyInvestmentSummary;

import React, { useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import SEO from "../../config/Seo";
import Navbar from "../../Components/Navbar";

import ResetPasswordForm from "../../Components/ResetPasswordForm";

const ResetPassword = () => {
  const navigate = useNavigate();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  useEffect(() => {
    const message = queryParams.get("message");

    if (message) {
      // toast.error(message);
    }
    return () => {
      if (message) {
        navigate("/");
      }
    };
  }, []);

  return (
    <>
      <SEO
        title={"Login | Access Your Ethical Finance Investments | Halvest"}
        description={
          "Log in to your Halvest account and explore ethical finance and Islamic banking solutions."
        }
        name={"Halvest"}
        type={"article"}
      />
      <div className="flex flex-col  min-h-screen">
        <Navbar type={2} />
        <ResetPasswordForm />
      </div>
    </>
  );
};

export default ResetPassword;

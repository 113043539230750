import React from "react";

const FilterButton = ({ active, setActive, items, type }) => {
  return (
    <div className="flex justify-center items-center gap-2">
      {items?.map((item, index) => (
        <button
          key={index}
          className={` transition-all ease-in-out duration-300 font-[500] h-[37px] flex justify-center items-center px-4 rounded-[40px] ${
            active === item?.value ? "bg-[#F1EDFB] text-[#6935D3]" : ""
          } ${
            type === "big" ? "text-[14px] font-[500]" : "text-[12px] font-[400]"
          }`}
          onClick={() => setActive(item?.value)}
        >
          {item?.label}
        </button>
      ))}
    </div>
  );
};

export default FilterButton;

import React from "react";
import icon1 from "../Assets/Images/payout.png";
import icon2 from "../Assets/Images/in.png";
import { useNavigate } from "react-router-dom";
import recent from "../Assets/Images/recent.png";
import { formatAmount } from "../utils/formatAmount";
import { timeformatter } from "../utils/dateformatter";

const TableRow = ({
  iconSrc,
  name,
  description,
  id,
  success,
  amount,
  successBgColor,
}) => {
  const navigate = useNavigate();
  return (
    <div
      // onClick={() => {
      //   navigate(`/investment/${id}`);
      // }}
      className="flex cursor-pointer justify-between items-center gap-4 p-3 border-b"
    >
      <div className="flex items-center gap-4 w-full max-w-xs">
        <div className="w-[45px] flex-shrink-0">
          <img
            src={iconSrc}
            alt="icon"
            className="w-[38px] h-[38px] object-contain"
          />
        </div>
        <div className="flex flex-col">
          <span className="font-[500] text-[14px] text-[#131E3D]">{name}</span>
          <span className="text-[12px] font-[400] text-[#9C9C9E]">
            {description}
          </span>
        </div>
      </div>
      <span
        className={`text-[14px] min-w-[80px] font-[400] px-[10px] justify-center items-center flex h-[27px] p-1 rounded-[40px] text-center ${successBgColor}`}
      >
        {success}
      </span>
      <div className="flex items-center w-[150px] justify-end">
        <span className="text-[#131E3D] font-[500] text-[14px]">{amount}</span>
      </div>
    </div>
  );
};

const InvestmentTransactionTable = ({ type, tableStyle, state, noData }) => {
  const tableData =
    type === "payout"
      ? // ? state?.cycle_details?.paymentSchedules?.expected_date
        // ? state?.cycle_details?.paymentSchedules?.expected_date?.map(
        //     (item) => ({
        //       iconSrc: icon2,
        //       name: timeformatter(item?.date, "formal", false, true),
        //       // description: timeformatter(state?.date, "formal", false, true),
        //       success: item?.status,
        //       // successBgColor: "bg-[#F2F2F7] text-[#131E3D]",
        //       successBgColor: "bg-[#E4FFC2] text-[#344616]",
        //       amount: `${
        //         state?.investment?.currency === "NGR" ||
        //         state?.cycle_details?.currency === "NGR"
        //           ? "₦"
        //           : "$"
        //       }${formatAmount(item?.amount)}`,
        //     })
        //   )
        state?.paymentSchedules?.paymentSchedule
        ? state?.paymentSchedules?.paymentSchedule?.map((item) => ({
            iconSrc: icon2,
            name: timeformatter(item?.date, "formal", false, true),
            // description: timeformatter(state?.date, "formal", false, true),
            success: item?.status,
            // successBgColor: "bg-[#F2F2F7] text-[#131E3D]",
            successBgColor: "bg-[#E4FFC2] text-[#344616]",
            amount: `${
              state?.investment?.currency === "NGR" ||
              state?.cycle_details?.currency === "NGR"
                ? "₦"
                : "$"
            }${formatAmount(item?.amount)}`,
          }))
        : state?.investment?.paymentSchedules?.expected_date?.map((item) => ({
            iconSrc: icon2,
            name: timeformatter(item?.date, "formal", false, true),
            // description: timeformatter(state?.date, "formal", false, true),
            success: item?.status,
            // successBgColor: "bg-[#F2F2F7] text-[#131E3D]",
            successBgColor: "bg-[#E4FFC2] text-[#344616]",
            amount: `${
              state?.investment?.currency === "NGR" ||
              state?.cycle_details?.currency === "NGR"
                ? "₦"
                : "$"
            }${formatAmount(item?.amount)}`,
          }))
      : [
          {
            iconSrc: icon1,
            name: state?.investment?.name || state?.cycle_details?.name,
            description: timeformatter(
              state?.created_at,
              "formal",
              false,
              true
            ),
            success: "Success",
            // successBgColor: "bg-[#F2F2F7] text-[#131E3D]",
            successBgColor: "bg-[#E4FFC2] text-[#344616]",
            amount: `${
              state?.investment?.currency === "NGR" ||
              state?.cycle_details?.currency === "NGR"
                ? "₦"
                : "$"
            }${formatAmount(state?.amount)}`,
          },
        ];

  return (
    <div
      className={`w-full min-w-[550px] md:min-w-auto max-w-[689px] border-[1px] min-h-[175px] rounded-xl mb-[30px] border-[#f2f2f7] p-4 ${tableStyle}`}
    >
      <div className="flex flex-col gap-2">
        {noData ? (
          <div className="flex flex-1 flex-col justify-center items-center">
            <img src={recent} alt="" className=" mb-[5px] w-[76px] h-[76px] " />
            <div className=" mb-[5px] p-2 text-[16px] font-[400] text-[#9C9C9E] ">
              Payout Schedule appear here when investment starts
            </div>
          </div>
        ) : (
          tableData.map((row, index) => (
            <TableRow
              key={index}
              id={index}
              iconSrc={row.iconSrc}
              name={row.name}
              description={row.description}
              success={row.success}
              successBgColor={row.successBgColor}
              amount={row.amount}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default InvestmentTransactionTable;

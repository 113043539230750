import React, { useEffect, useMemo } from "react";
import close from "../../Assets/Images/close.png";
import wallet from "../../Assets/Images/wallet2.png";
import sale from "../../Assets/Images/sell.png";
import { LiaAngleLeftSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import CustomInput from "../Inputs/CustomInput";
import { formatAmount } from "../../utils/formatAmount";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveWithdrawalAmount } from "../../store/reducers/walletSlice";
import MainButton from "../Buttons/MainButton";
import { getRate } from "../../store/actions/wallet/getRate";
import { countdown } from "../../utils/countdown";
import toggleIcon from "../../Assets/Images/toggle-currency.png";
import usd from "../../Assets/Icons/usd-flag.png";
import ngr from "../../Assets/Icons/ngr-flag.png";

const FundFromOtherWallet = ({ next, currency, handleClose, handleBack }) => {
  const _currency = currency === "Naira" ? "USD" : "Naira";

  const dispatch = useDispatch();

  const [amountType, setToggleAmountType] = useState(true);

  const { withdrawalAmount, conversionRate } = useSelector(
    (state) => state?.wallet
  );

  const [amount, setAmount] = useState(
    withdrawalAmount?.amount
      ? Number(withdrawalAmount?.amount)?.toFixed(2)
      : withdrawalAmount
      ? Number(withdrawalAmount)?.toFixed(2)
      : 0
  );

  const walletBalance = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data?.walletBalance
  );
  const dollarBalance = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data?.dollarBalance
  );

  const formattedWalletBalance = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(walletBalance || 0);

  const usdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(dollarBalance || 0);

  const [startTime, setTimeStart] = useState(null);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    dispatch(
      getRate(() => {
        setTimeStart(new Date());
      })
    );
  }, []);

  const handleRefresh = () => {
    setTimer(null);
    dispatch(
      getRate(() => {
        setTimeStart(new Date());
      })
    );
  };

  useEffect(() => {
    let timerId;
    if (startTime) {
      timerId = setInterval(() => {
        const _times = countdown(startTime);
        setTimer(_times);
      }, 1000);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [startTime]);

  const processingFee = useMemo(() => {
    return amountType
      ? amount * 0.05
      : currency === "Naira"
      ? amount * conversionRate * 0.05
      : (amount / conversionRate) * 0.05;
  }, [amount, currency, conversionRate, amountType]);

  return (
    <div className="flex flex-col justify-center items-center border-[1px] border-[#F0F0F5] w-[90%] sm:w-[553px] mt-[30px] mx-auto mb-[30px] min-h-[571px] rounded-t-[14px] shadow-xl relative bg-white">
      <div className="absolute p-3 top-3 right-3 cursor-pointer">
        <img
          src={close}
          alt="Close"
          className="w-6 h-6"
          onClick={handleClose}
        />
      </div>
      <div className="flex  items-center justify-between w-full px-8 mb-4 mt-[50px]">
        <div
          className="flex items-center gap-[3px] cursor-pointer"
          onClick={handleBack}
        >
          <LiaAngleLeftSolid size={18} color="#131E3D" className="mr-2" />
          <h3 className="text-[14px] font-[400] text-[#131E3D]">Back</h3>
        </div>
        <div className="text-[16px] text-[#131e3d] font-[400]">
          {`Fund from ${_currency} Wallet`}
        </div>
        <div className="w-[20px]"></div>
      </div>
      <div className="flex flex-col justify-center items-center w-full h-full px-8 mb-2">
        <div className="flex bg-[#F8F8F8]  flex-col rounded-[8.92px] h-[121px] w-full justify-center items-center">
          <div className="flex gap-2 mb-1 mt-1 justify-center items-center">
            <img
              src={
                amountType
                  ? currency === "Naira"
                    ? ngr
                    : usd
                  : currency === "Naira"
                  ? usd
                  : ngr
              }
              className="w-[16.42px] h-[16.42px] cursor-pointer"
              alt=""
            />
            <h5>
              {amountType
                ? currency === "Naira"
                  ? "NGN"
                  : "USD"
                : currency === "Naira"
                ? "USD"
                : "NGN"}
            </h5>
          </div>

          <CustomInput
            fullWidth
            noBorder
            bg={true}
            height={81}
            autoComplete={"off"}
            autoFocus={true}
            placeholder={
              amountType
                ? currency === "Naira"
                  ? "₦0.00"
                  : "$0.00"
                : currency === "Naira"
                ? "$0.00"
                : "₦0.00"
            }
            customTextStyle={{}}
            textInputCustomStyles={{
              textAlign: "center",
              fontSize: "48px",
              color: "#131e3d",
              paddingTop: "0px",
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingBottom: "0px",
            }}
            variant={"standard"}
            selfValue={amount}
            customType="amount"
            prefix={
              amountType
                ? currency === "Naira"
                  ? "₦"
                  : "$"
                : currency === "Naira"
                ? "$"
                : "₦"
            }
            onChange={(e) => {
              setAmount(e.target.value);
            }}
          />
        </div>
        <img
          onClick={() => {
            // if (amountType) {
            //   setAmount(
            //     currency === "Naira"
            //       ? amount / conversionRate
            //       : amount * conversionRate
            //     // ? (amount / conversionRate).toFixed(2)
            //     // : (amount * conversionRate).toFixed(2)
            //   );
            // } else {
            //   setAmount(
            //     currency === "Naira"
            //       ? (amount * conversionRate).toFixed(2)
            //       : (amount / conversionRate).toFixed(2)
            //   );
            // }
            setToggleAmountType((prev) => !prev);
          }}
          src={toggleIcon}
          className="w-[35.2px] z-20 h-[35.2px] -my-3 cursor-pointer"
          alt=""
        />
        <div className="mb-0 w-full">
          <div className="flex mb-5 bg-[#F8F8F8] gap-2 px-5 overflow-hidden flex-col rounded-[8.92px] h-[121px] w-full justify-center items-center">
            <div className="flex gap-2 mt-1 justify-center items-center">
              <img
                src={
                  amountType
                    ? currency !== "Naira"
                      ? ngr
                      : usd
                    : currency !== "Naira"
                    ? usd
                    : ngr
                }
                className="w-[16.42px] h-[16.42px] cursor-pointer"
                alt=""
              />
              <h5>
                {amountType
                  ? currency !== "Naira"
                    ? "NGN"
                    : "USD"
                  : currency !== "Naira"
                  ? "USD"
                  : "NGN"}
              </h5>
            </div>

            <h3 className="text-center text-nowrap  overflow-hidden w-full text-[48px] font-[400] leading-[52.8px] text-[#131E3D]">
              {`${
                amountType
                  ? currency !== "Naira"
                    ? "₦"
                    : "$"
                  : currency !== "Naira"
                  ? "$"
                  : "₦"
              }${
                !conversionRate
                  ? "--"
                  : formatAmount(
                      amountType
                        ? currency === "Naira"
                          ? amount / conversionRate
                          : amount * conversionRate
                        : currency === "Naira"
                        ? amount * conversionRate
                        : amount / conversionRate
                    )
              }`}
            </h3>
          </div>

          <div className="flex  justify-between items-center ">
            <h3 className="   text-[14px] font-[400] text-[#9c9c9e]">
              1$ ~ ₦{conversionRate ? formatAmount(conversionRate) : "--"}{" "}
              <span className="text-[14px] font-[400] cursor-pointer text-[#6935D3] ">
                (?)
              </span>
            </h3>
            <h3 className="  text-[14px] font-[400] text-[#9c9c9e]">
              {timer?.difference > 60000 && timer?.sec ? (
                <span
                  onClick={handleRefresh}
                  className="text-[#6935D3] cursor-pointer"
                >
                  Refresh
                </span>
              ) : (
                <span className="text-[#6935D3]">
                  Expires in{" "}
                  {timer?.difference > 60000
                    ? "00"
                    : timer?.min
                    ? timer?.min
                    : "--"}
                  :
                  {timer?.difference > 60000
                    ? "00"
                    : timer?.sec
                    ? timer?.sec
                    : "--"}
                </span>
              )}
            </h3>
          </div>
          <div className="h-[1.5px] w-full my-2 bg-[#F2F2F7]"></div>
          <div className=" flex justify-between  mb-6  items-center ">
            <div className="flex  items-center gap-[8px]">
              <img src={sale} alt="" className=" w-[16px] h-[16px] " />
              <div className=" text-[14px] font-[400] text-[#9C9C9E] ">
                Processing Fee
              </div>
            </div>
            <div className=" text-[14px] font-[400] text-[#9c9c9e] ">
              {currency === "Naira" ? "₦" : "$"}{" "}
              {processingFee
                ? formatAmount(processingFee?.toFixed(2), 0)
                : "0.00"}
            </div>
          </div>
        </div>
        {/* <div className="h-[1px] mb-3 w-full bg-[#F2F2F7]"></div>
                <div className="flex justify-between mb-8 items-center w-full px-4">
                    <div className="flex items-center gap-[8px]">
                        <img src={sale} alt="Admin Fee" className='w-[16px] h-[16px]' />
                        <div className="text-[14px] font-[400] text-[#9C9C9E]">Admin Fee</div>
                    </div>
                    <div className="text-[14px] font-[400] text-[#9c9c9e]">$50</div>
                </div> */}
        {/* <div className="flex mt-[30px] mb-5 items-center gap-[5px]"> */}
        <div className="flex mt-[30px] items-center gap-[5px]">
          <img src={wallet} alt="Wallet" className="w-[14px] h-[14px]" />
          <div className="text-[12px] font-[400] text-[#9c9c9e]">
            {_currency} Wallet Balance:{" "}
            {currency !== "Naira"
              ? formattedWalletBalance?.split(".")[0]
              : usdFormatter?.split(".")[0]}
          </div>
        </div>

        <MainButton
          mainClasses="!w-auto !min-w-[203px] !h-[45px] !mt-2 !mb-5 text-white"
          title={`Add ${currency === "Naira" ? "₦" : "$"}${
            !conversionRate
              ? "--"
              : formatAmount(
                  amountType
                    ? amount || 0
                    : currency === "Naira"
                    ? amount * conversionRate
                    : amount / conversionRate
                )
          }`}
          // loading={submitting}
          // type="submit"
          onClick={
            amount < 1 ||
            !conversionRate ||
            (_currency === "Naira" &&
              walletBalance < Number(amount * conversionRate)) ||
            (_currency !== "Naira" &&
              dollarBalance < Number(amount / conversionRate))
              ? null
              : () => {
                  dispatch(
                    saveWithdrawalAmount({
                      amount: amountType
                        ? amount || 0
                        : currency === "Naira"
                        ? amount * conversionRate
                        : amount / conversionRate,
                      type: "other-wallet",
                    })
                  );
                  next();
                }
          }
          disabled={
            !conversionRate
              ? true
              : amount < 1
              ? true
              : amountType
              ? currency === "Naira"
                ? (dollarBalance || 0) < Number(amount / conversionRate)
                : (walletBalance || 0) < Number(amount * conversionRate)
              : currency === "Naira"
              ? (dollarBalance || 0) < Number(amount)
              : (walletBalance || 0) < Number(amount)
          }
        />
      </div>
    </div>
  );
};

export default FundFromOtherWallet;

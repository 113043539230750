import React from "react";
import portfolio from "../Assets/Images/portfolio.png";

const PortfolioCard = ({
  main,
  loading,
  img,
  title,
  subtitle,
  type,
  onClick,
  tag,
}) => {
  return (
    <div
      onClick={onClick}
      className={`cursor-pointer flex-1 max-w-[339px] h-[243px] rounded-[14px] border-[1px] border-[#F0F0F5] flex flex-col ${
        img ? "justify-between" : "justify-end"
      } p-4 relative`}
    >
      {img ? (
        <div className="flex justify-between items-center">
          <img src={img} alt="" className="mt-2 w-[52.8px] h-[52.8px]" />

          {tag ? (
            <div className="bg-[rgba(25,40,81,0.07)] flex justify-center items-center  px-3 rounded-[40px] h-[27px] ">
              <h3 className="text-[14px] font-[400] text-[#18294E]">{tag}</h3>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {type === 3 ? (
        <div className="flex justify-end">
          <div className="flex justify-center items-center bg-[#F0F0F5] rounded-[40px] w-[114px] px-3 ">
            <h3 className="text-[#131E3D] font-[400] text-[14px]">{title}</h3>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div>
        {type ? (
          <h3 className="text-[20px] font-[500] text-[#131E3D]">{main}</h3>
        ) : (
          <h3 className="text-[24px] font-[500] text-[#131E3D]">
            {loading ? "--" : main?.split(".")[0]}
            <span className="text-[16px] font-[500] text-[#9C9C9E]">
              .{loading ? "--" : main?.split(".")[1]}
            </span>
          </h3>
        )}
        <div className="w-full h-[1px] bg-[#F2F2F7] my-3"></div>
        <div className="flex justify-between items-center">
          {!type ? (
            <div>
              <h3 className="text-[#131E3D] font-[400] text-[16px]">{title}</h3>
              <h3 className="text-[#9C9C9E] font-[400] text-[14px]">
                {subtitle}
              </h3>
            </div>
          ) : (
            <div>
              <h3 className="text-[#131E3D] font-[400] text-[11px]">
                {subtitle}
              </h3>
            </div>
          )}
          {type ? (
            <></>
          ) : (
            <img src={portfolio} alt="" className="ml-4 w-[30px] h-[30px]" />
          )}
        </div>
      </div>
    </div>
  );
};

export default PortfolioCard;

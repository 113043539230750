import React, { useEffect, useState } from "react";
import dots from "../../Assets/Images/dots.png"; // Adjust the path as needed
import RealEstateOpportunities from "../../Components/RealEstateOppurtunities";
import brook from "../../Assets/Images/brooks.png";
import sobha from "../../Assets/Images/sobha.png";
import river from "../../Assets/Images/river.png";
import Navbar from "../../Components/Navbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import Notification from "../../Components/Notification/Notification";
import Loader from "../../Components/Utils/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getREInvestments } from "../../store/actions/realEstate/getREInvestments";

const RealEstateInvestment = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const dispatch = useDispatch();

  const [params] = useSearchParams();
  const currency = params.get("currency");

  const {
    opportunities: opportunitiesData,
    REInvestments,
    pending,
  } = useSelector((state) => state.opportunities);

  useEffect(() => {
    dispatch(getREInvestments(currency));
  }, [currency]);

  //   amount
  // :
  // "7500.00"
  // break_down
  // :
  // null
  // created_at
  // :
  // "2024-08-13T08:46:35.000000Z"
  // currency
  // :
  // "NGR"
  // disbursed_amount
  // :
  // null
  // disbursed_date
  // :
  // null
  // id
  // :
  // 7
  // payment_method
  // :
  // "transfer"
  // property_product_id
  // :
  // "2"
  // status
  // :
  // "1"
  // transaction_id
  // :
  // "130824594448157845"
  // unit
  // :
  // "30.00"
  // updated_at
  // :
  // "2024-08-20T07:41:53.000000Z"
  // user_id
  // :
  // "260"

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (pending === false) {
      setLoading(false);
    }
  }, [pending]);

  useEffect(() => {
    if (currency !== "NGR" && currency !== "USD") {
      navigate(-1);
    }
  }, []);

  const [showNotification, setShowNotification] = useState(false);
  const [activeTab, setActiveTab] = useState("button1");
  const navigate = useNavigate();

  // Function to handle card clicks
  const handleCardClick = (id) => {
    navigate(`/investment/RealEstate/${id}`);
  };

  const handleToggleNotification = () => {
    setShowNotification((prev) => !prev);
  };

  return (
    <>
      <div className="flex flex-col bg-[#F9FAFA] gap-6 pt-3 pb-3 items-center min-h-screen">
        <Navbar type={3} handleNotification={handleToggleNotification} />

        <div className="w-[95%] min-h-[80vh]  h-full flex flex-col rounded-[12px] gap-10 p-10 bg-[#FFF] border-[#F2F2F2] border-[1.1px] drop-shadow-custom max-w-[1664px]">
          <div className="flex flex-col justify-center items-center mb-2">
            <div className="text-[54px] text-[#131e3d] leading-[59.4px] text-center mb-[50px] font-[400] w-[652px]">
              Invest in well-vetted real estate assets
            </div>
            <div className="w-[366px] shadow-md p-2 h-[49px] border-[1px] rounded-[41px] flex justify-center items-center">
              <div className="flex justify-center items-center gap-2">
                <button
                  className={`py-1 font-[400] w-[72px] h-[37px] px-1 rounded-[40px] ${
                    activeTab === "button1" ? "bg-[#F1EDFB] text-[#6935D3]" : ""
                  }`}
                  onClick={() => setActiveTab("button1")}
                >
                  Active
                </button>
                <button
                  className={`w-[76px] font-[400] h-[37px] py-1 px-1 rounded-[40px] ${
                    activeTab === "button2" ? "bg-[#F1EDFB] text-[#6935D3]" : ""
                  }`}
                  onClick={() => setActiveTab("button2")}
                >
                  Closed
                </button>
                <button
                  className={`w-[60px] font-[400] h-[37px] py-1 px-1 rounded-[40px] ${
                    activeTab === "button3" ? "bg-[#F1EDFB] text-[#6935D3]" : ""
                  }`}
                  onClick={() => setActiveTab("button3")}
                >
                  Sold
                </button>
                <button
                  className={`w-[118px] font-[400] h-[37px] py-1 px-1 rounded-[40px] ${
                    activeTab === "button4" ? "bg-[#F1EDFB] text-[#6935D3]" : ""
                  }`}
                  onClick={() => setActiveTab("button4")}
                >
                  Coming Soon
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap justify-center gap-4">
            {pending || loading ? (
              <Loader />
            ) : (
              REInvestments?.map((item, index) => {
                return (
                  <div
                    key={item?.id}
                    className="w-full cursor-pointer sm:w-[calc(33.333%-16px)]"
                    onClick={() => handleCardClick(item?.id)}
                  >
                    <RealEstateOpportunities
                      imageSrc={brook}
                      location="Dubai Palms, UAE"
                      title="Brooks Home"
                      price={100}
                      investedPercentage={17}
                      totalAmount={180000}
                    />
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RealEstateInvestment;

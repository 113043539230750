import React, { useEffect } from "react";
import { MdClose } from "react-icons/md";
import SelectField from "./Inputs/SelectField";
import TextInput from "./Inputs/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { IDVerificationSchema } from "../helper/validationSchemas";
import { verifyID } from "../store/actions/profile/verifyID";
import { useFormik } from "formik";
import { formHandler } from "../utils/formhandler";
import MainButton from "./Buttons/MainButton";
import Selfie from "./Inputs/Selfie";
import { useNavigate } from "react-router-dom";

const VerifyId = ({ handleClose, next }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    const data = {
      id_number: values?.id_number,
      id_type: values?.id_type?.value?.toLowerCase(),
      photo: values?.photo,
    };
    dispatch(
      verifyID({
        data,
        cb: () => {
          next();
        },
        failed: (msg) => {
          setSubmitting();
        },
      })
    );
  };
  const form = useFormik({
    initialValues: {
      id_type: {
        value: "NIN",
        label: "NIN",
      },
      id_number: "",
    },
    // validationSchema: IDVerificationSchema,
    onSubmit: handleSubmit,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div className="border-[1px] relative border-[#f0f0f5] bg-white w-[553px] h-auto px-8 pt-6 pb-8 rounded-[14px] shadow-custom">
      <div className="flex justify-end">
        <button
          onClick={handleClose}
          className="absolute top-6 right-8 text-gray-400 hover:text-red-600 transition-colors font-semibold text-[24px]"
        >
          <MdClose />
        </button>
      </div>

      <h2 className="text-[16px] font-[400] text-[#131E3D] text-center mt-2 mb-6">
        Verify ID
      </h2>

      <div>
        <div className="flex flex-col md:flex-row gap-8 mb-3">
          {/* <div className="w-full md:w-[48%]"> */}

          <div className="w-full">
            <label
              htmlFor="id_type"
              className="mb-5 text-[14px] font-[400] text-[#9c9c9e] "
            >
              Choose ID
            </label>

            <div className="w-full ">
              <SelectField
                id="id_type"
                form={form}
                clearFields={["id_number"]}
                options={[
                  // {
                  //   value: "Passport",
                  //   label: "Passport",
                  // },
                  {
                    value: "NIN",
                    label: "NIN",
                  },
                  {
                    value: "Driver's License",
                    label: "Driver's License",
                  },
                ]}
                sx={{ width: "100%", height: "50px" }}
              />
            </div>
          </div>
          {/* <div className="w-full md:w-[48%]">
            <label
              htmlFor="expiryDate"
              className="mb-5 text-[14px] font-[400] text-[#9c9c9e] "
            >
              Expiry Date
            </label>
            <TextInput id="expiryDate" mainClasses="!w-full" />
          </div> */}
        </div>

        {/* <div className="mb-6 ">
          <label
            htmlFor="fullName"
            className="mb-5 text-[14px] font-[400] text-[#9c9c9e] "
          >
            ID Number (Optional)
          </label>

          <TextInput mainClasses="w-full" />
        </div> */}
        {/* <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            ID Number
          </label>
          <TextInput
            {...formHandler("id_number", form)}
            mainClasses={"mb-0 mt-0 !w-full "}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Selfie id="photo" {...formHandler("photo", form)} form={form} />
        </div> */}

        <div className="text-center mt-10">
          {/* <p className="text-[14px] text-[#9c9c9e] font-[400] mb-5">
            Your ID will be verified and processed by our partners
          </p> */}

          <MainButton
            mainClasses="!h-[45px] !w-[191px] !mt-[2px] !mb-0 text-white"
            // title={"Save ID"}
            title={"Next"}
            // onClick={form.handleSubmit}
            onClick={() => {
              navigate(
                `/verify-id?type=${form?.values?.id_type?.value?.toLowerCase()}`
              );
            }}
            loading={form.isSubmitting}
            disabled={form.isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

export default VerifyId;

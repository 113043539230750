import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { LiaAngleLeftSolid } from "react-icons/lia";
import InvestmentCard from "../../Components/InvestmentCard";
import individual from "../../Assets/Images/landing-icon2.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getOpportunities } from "../../store/actions/opportunities/getopportunities";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Utils/Loader";
import Notification from "../../Components/Notification/Notification";

const Investment = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [params] = useSearchParams();
  const currency = params.get("currency");

  const {
    opportunities: opportunitiesData,

    pending,
  } = useSelector((state) => state.opportunities);

  useEffect(() => {
    dispatch(getOpportunities(currency));
  }, [currency]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (pending === false) {
      setLoading(false);
    }
  }, [pending]);

  useEffect(() => {
    if (currency !== "NGR" && currency !== "USD") {
      navigate(-1);
    }
  }, []);

  const handleToggleNotification = () => {
    setShowNotification((prev) => !prev);
  };

  return (
    <>
      <div className="flex flex-col bg-[#F9FAFA] gap-6 pt-3 pb-3 items-center min-h-screen">
        <Navbar type={3} handleNotification={handleToggleNotification} />

        <div className="w-[95%] h-full min-h-[80vh]  rounded-[12px] gap-5 p-5 md:p-10 bg-[#FFF] border-[#F2F2F2] border-[1.1px] drop-shadow-custom  max-w-[1664px] flex">
          <div className="flex flex-col flex-1">
            <div
              onClick={() => navigate(-1)}
              className="flex cursor-pointer items-center mb-4"
            >
              <LiaAngleLeftSolid
                size={18}
                color="#131E3D"
                className="mx-[2px]  "
              />
              <h3 className="text-[14px] font-[400] text-[#131E3D]">Back</h3>
            </div>
            <h2 className="text-[44px] leading-[49.4px] lg:text-[54px] font-[400] lg:leading-[59.4px] tracking-[-3.1%] max-w-[650px]">
              Invest in ethically curated businesses
            </h2>
            <div className="my-8">
              <div className="gap-6 w-full min-h-[60vh] flex-wrap flex">
                {loading || pending ? (
                  <Loader />
                ) : (
                  opportunitiesData?.map((opportunity, index) => (
                    <InvestmentCard
                      key={index}
                      imageSrc={
                        opportunity?.company_logo || opportunity?.banner_link
                      }
                      onClick={
                        opportunity?.investment_status == 0
                          ? () => {
                              // dispatch(
                              //   showErrorModal(
                              //     `Sorry, this investment has closed.`
                              //   )
                              // );
                            }
                          : () =>
                              navigate(
                                `/investment/business-financing/${opportunity.id}?currency=${currency}`,
                                {
                                  state: {
                                    opportunity,
                                  },
                                }
                              )
                      }
                      title={opportunity?.name}
                      // text="Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae."
                      location={opportunity?.location}
                      campaign={opportunity?.cycles?.length}
                      section1={
                        opportunity?.investment_category?.name ||
                        opportunity?.category?.name
                      }
                      section2={opportunity?.sector}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Investment;

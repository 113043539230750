import React from "react";
import close from "../Assets/Images/close.png";
import welcome from "../Assets/Images/welcome.png";

const IdVerified = ({ handleClose, title, subTitle }) => {
  return (
    <div className="flex justify-center items-center border-[1px] border-[#F0F0F5] w-[90%] sm:w-[500px] mx-auto my-[30px] h-[400px] rounded-[14px] shadow-xl relative bg-white">
      <div className="absolute p-3 top-3 right-3 cursor-pointer">
        <img
          src={close}
          alt="Close"
          className="cursor-pointer w-6 h-6"
          onClick={handleClose}
        />
      </div>

      <div className="flex flex-col justify-center items-center w-full h-full">
        <img
          src={welcome}
          alt="Welcome"
          className="w-[110px] h-[110px] mb-[20px]"
        />
        <div className="mb-[6px] text-[20px] text-[#131e3d] font-[500]">
          {title}
        </div>
        <div className="mb-[35px] text-[14px] text-[#9c9c9e] font-[400]">
          {subTitle}
        </div>
      </div>
    </div>
  );
};

export default IdVerified;

import React from 'react';
import { MdGpsFixed } from 'react-icons/md';
import calender from "../Assets/Images/calender.png";
import credit from "../Assets/Images/credit.png";
import sell from "../Assets/Images/sell.png";


const Goal = () => {
  const progressPercentage = 2;

  return (
    <div className='p-[30px] flex flex-col justify-start items-start gap-[10px] border-[1px] border-[#F0F0F5] bg-white w-[550px] h-[140px] rounded-[10px]'>
      <div className="mb-[15px] text-[20px] font-[400] text-[#131e3d]">
        {progressPercentage}% of ₦1,000,000
      </div>

      <div className='relative w-[500px] h-[3px] bg-[#e0e0e0] '>
        <div
          className='absolute top-0 left-0 h-full bg-[#2CB171]'
          style={{ 
            width: `${progressPercentage}%`,
            minWidth: '10px' 
          }}
        ></div>
      <div className="mt-[20px] flex items-center gap-[10px]">
        <MdGpsFixed color='#6935D3' size={20} />
        <div className="text-[12px] font-[400] text-[#6935D3] cursor-pointer">
          Change target
        </div>
       </div>
      </div>

    </div>
  );
};

export default Goal;

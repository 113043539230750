import { createSlice } from "@reduxjs/toolkit";
import { addFunds, getBalance } from "../actions/dashboard/manageFunds";
import { switchCurrency } from "../actions/dashboard/switchCurrency";
import { getAllWithdrawals } from "../actions/wallet/getAllWithdrawals";
import { login } from "../actions/onboarding/login";
import { registerUsers } from "../actions/onboarding/registerUsers";
import { allDashboard } from "../actions/dashboard/allDashboard";
import { getMetrics } from "../actions/dashboard/getMetrics";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    error: null,
    investmentMetrics: [],
    activeTab: "overview",

    pending: false,
    success: false,
    halvestBalance: null,
    savedCreditCards: [],
    myWithdrawals: [],
    transactions: [],
    currency: "NGR",
    pendingAllWithdrawals: false,
    tawkMessengerRef: null,
  },

  reducers: {
    addCreditCards: (state, action) => {
      state.savedCreditCards = [...state.savedCreditCards, action.payload];
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    updateCreditCards: (state, action) => {
      state.savedCreditCards = action.payload;
    },

    saveChathandle: (state, action) => {
      state.tawkMessengerRef = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.halvestBalance = null;
    });
    builder.addCase(registerUsers.fulfilled, (state, action) => {
      state.halvestBalance = null;
    });

    builder.addCase(switchCurrency.fulfilled, (state, action) => {
      state.currency = action.payload;
    });
    builder.addCase(addFunds.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(addFunds.fulfilled, (state, action) => {
      state.pending = false;
    });
    builder.addCase(addFunds.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(getBalance.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(allDashboard.fulfilled, (state, action) => {
      // state.pending = false;
      // state.halvestBalance = action.payload?.walletBalances;
    });
    builder.addCase(getMetrics.rejected, (state, action) => {
      state.metricsPending = false;
    });
    builder.addCase(getMetrics.pending, (state, action) => {
      state.metricsPending = true;
      state.investmentMetrics = [];
    });
    builder.addCase(getMetrics.fulfilled, (state, action) => {
      state.metricsPending = false;
      state.investmentMetrics = action.payload;
      // state.investmentMetrics = [];
    });
    builder.addCase(getBalance.fulfilled, (state, action) => {
      state.pending = false;
      state.halvestBalance = action.payload;
    });
    builder.addCase(getBalance.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(getAllWithdrawals.pending, (state, action) => {
      state.pendingAllWithdrawals = true;

      state.myWithdrawals = [];
    });
    builder.addCase(getAllWithdrawals.fulfilled, (state, action) => {
      state.pendingAllWithdrawals = false;
      state.myWithdrawals = action.payload;
    });
    builder.addCase(getAllWithdrawals.rejected, (state, action) => {
      state.pendingAllWithdrawals = false;
    });
  },
});

export const {
  updateCreditCards,
  setActiveTab,
  saveChathandle,
  addCreditCards,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;

import React, { useEffect } from "react";
import icon3 from "../Assets/Images/addbank.png";
import icon1 from "../Assets/Images/pin.png";
import icon2 from "../Assets/Images/completeprofile.png";
import icon4 from "../Assets/Images/tick.png";
import arrow from "../Assets/Images/Vector.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserBanks } from "../store/actions/bank/getUserBanks";

const ProfileRow = ({ desc, icon, text, completed, onClick }) => (
  <div
    // onClick={completed ? null : onClick}
    onClick={onClick}
    className="flex cursor-pointer group items-center justify-between py-4"
  >
    <div className="flex items-center">
      {completed ? (
        <img src={icon4} alt="icon" className="w-[38px] h-[38px] mr-4" />
      ) : (
        <img src={icon} alt="icon" className="w-[38px] h-[38px] mr-4" />
      )}
      <div className="flex flex-col">
        <span className="text-[14px] font-[500] text-[#131E3D]">{text}</span>
        <span className="text-[12px] font-[400] text-[#9C9C9E]">{desc}</span>
      </div>
    </div>
    <img
      src={arrow}
      alt="arrow"
      className="opacity-0 group-hover:opacity-100 hid w-[9px] transition-all ease-in-out duration-400  hover h-[17px]"
    />
  </div>
);

const ProfileSetup = ({ setShowPinModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userBanks } = useSelector((state) => state?.bank) || [];
  const { profile, user } = useSelector((state) => state.onboarding);

  const NGN_banks = userBanks?.filter(
    (bank) => bank.currency?.toLowerCase() === "ngr"
  );

  const USD_banks = userBanks?.filter(
    (bank) => bank?.currency?.toLowerCase() === "usd" && bank?.bank_type
  );

  return (
    <div className="px-8 mb-[30px] w-full max-w-[731px] border-[1.1px] rounded-[15px] drop-shadow-custom border-[#F0F0F5] py-5">
      <ProfileRow
        completed={profile?.nok_email && profile?.gender}
        icon={icon2}
        onClick={() => {
          navigate("/profile?sub=account");
        }}
        text="Complete Profile"
        desc="Secure your Halvest profile with a transaction PIN to start investing"
        showArrow={false}
      />
      <div className="h-[0.5px] bg-[#F2F2F7]" />
      <ProfileRow
        icon={icon1}
        onClick={() => {
          if (user?.hasSetPin) {
            setShowPinModal(true);
          } else {
            setShowPinModal(true);
          }
          // navigate("/profile?sub=security");
        }}
        completed={user?.hasSetPin}
        text="Setup Transaction PIN"
        desc="Secure your Halvest profile with a transaction PIN to start investing"
        showArrow={true}
      />
      <div className="h-[0.5px] bg-[#F2F2F7]" />

      <ProfileRow
        completed={userBanks?.length > 0}
        onClick={() => {
          navigate("/profile?sub=banks");
        }}
        icon={icon3}
        text="Add bank account"
        desc="Add a new bank account using your BVN details"
        showArrow={false}
      />
      <div className="h-[0.5px] bg-[#F2F2F7]" />
    </div>
  );
};

export default ProfileSetup;

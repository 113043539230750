import React, { useState } from "react";
import close from "../../Assets/Images/close.png";
import up from "../../Assets/Images/chevron right.png";

import usdWallet from "../../Assets/Icons/usd-wallet.png";
import ngrWallet from "../../Assets/Icons/ngr-wallet.png";

import bankTransfer from "../../Assets/Icons/bank-transfer.png";

const PaymentMethodUsd = ({
  handleClose,
  fromNairaWallet,
  openTransfersNotFromNigeria,
  openTransfersFromNigeria,
}) => {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (index) => {
    setSelectedCard(index);

    switch (index) {
      case 0:
        openTransfersFromNigeria();
        break;
      case 1:
        openTransfersNotFromNigeria();
        break;
      case 2:
        openTransfersNotFromNigeria();
        break;
      case 3:
        // fromNairaWallet();
        break;
      default:
        break;
    }
  };

  const getCardStyle = (index) => {
    if (selectedCard === null) {
      return "opacity-100";
    }
    return selectedCard === index
      ? "opacity-100 scale-105 shadow-xl"
      : "opacity-50";
  };

  return (
    <div className="flex flex-col justify-start items-start border-[1px] border-[#F0F0F5] w-[90%] sm:w-[553px] mt-[30px] mx-auto mb-[30px] h-[506px] rounded-[14px] shadow-xl relative bg-white">
      <div className="absolute p-3 top-3 right-3 cursor-pointer">
        <img
          src={close}
          alt="Close"
          className="cursor-pointer w-6 h-6"
          onClick={handleClose}
        />
      </div>
      <div className="flex mt-[55px] flex-col justify-start items-center w-full h-full">
        <h2 className="text-[16px] text-[#9c9c9e] font-[400] mb-[30px]">
          Fund USD Wallet
        </h2>
        <div className="flex w-[90%] md:w-auto flex-col gap-[26px]">
          {[
            "Transfers from Nigeria",
            "Transfers from the US",
            "Transfers from Countries Other than the US and Nigeria",
            "From Naira Wallet",
          ].map((text, index) => (
            <div
              key={index}
              className={`w-full md:w-[489px]  h-[70px] items-center opacity-50 flex justify-between p-5 rounded-[14px] border-[1px] border-[#f0f0f5] shadow-md transition-transform duration-300  ${
                index === 3
                  ? ""
                  : "hover:opacity-100 hover:scale-105  hover:shadow-xl"
              } ${index === 3 ? "cursor-not-allowed " : "cursor-pointer"}`}
              onClick={() => handleCardClick(index)}
            >
              <div className="flex gap-4">
                {text === "Transfers from Nigeria" ? (
                  <img
                    src={bankTransfer}
                    alt=""
                    className="w-[30px] h-[30px]"
                  />
                ) : text === "Transfers from the US" ? (
                  <img src={usdWallet} alt="" className="w-[30px] h-[30px]" />
                ) : text === "From Naira Wallet" ? (
                  <img src={ngrWallet} alt="" className="w-[30px] h-[30px]" />
                ) : (
                  // <img src={debitCard} alt="" className="w-[30px] h-[30px]" />
                  <></>
                )}

                <div className="text-[16px] font-[500] text-[#131E3D]">
                  {text}
                </div>
              </div>
              <img src={up} alt="" className="w-[20px] h-[20px]" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodUsd;

import React from "react";

const FeaturesCard = ({ imageSrc, title, text }) => {
  return (
    <div
      // className={`flex flex-col rounded-xl justify-between items-start transition-all ease-in-out duration-100 border-[1.1px] cursor-pointer border-transparent hover:border-[1.1px] hover:border-[#F0F0F5] p-2  flex-1  max-w-[377px]  h-[298px] `}
      className={`flex flex-col rounded-xl justify-between items-start transition duration-1000 ease-in-out hover:scale-[102%]      border-[1.1px] border-[#F0F0F5] p-2  flex-1  max-w-[377px]  h-[298px] `}
    >
      <img
        src={imageSrc}
        alt="icon"
        className="w-[66px] h-[66px] m-4 object-cover"
      />
      <div className="mt-auto p-4 w-full">
        <div className="h-[0.5px]  w-full bg-[#F2F2F7]"></div>
        {/* <div className="flex items-center gap-[5px] p-2"> */}
        <div className="mt-2 text-[20px] text-left  text-[#131E3D] font-[400] ">
          {title}
        </div>
        <div className=" text-[#9C9C9E] text-[16px] font-[400] ">{text}</div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default FeaturesCard;

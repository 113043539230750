import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Client } from "../../../client";

import { API_GET_BUSINESSES_PRODUCTS } from "../../../config/api";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";

export const getBusinessProducts = createAsyncThunk(
  "getBusinessProducts/get",
  async ({ id, currency, cb, failed }, { rejectWithValue, getState }) => {
    try {
      const { data } = await Client({
        method: "GET",
        path: `${API_GET_BUSINESSES_PRODUCTS}${id}&currency=${currency}`,
      });
      cb(data?.data);
      return data?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      failed();
      return rejectWithValue(error.message);
    }
  }
);

import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import { API_GET_SECURITY_QUESTIONS } from "../../../config/api";

export const getQuestion = createAsyncThunk(
  "auth/getQuestion",
  async ({ data, cb, failed }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_GET_SECURITY_QUESTIONS,
        data,
      });
      cb(response?.data);
      return response?.data;
    } catch (error) {
      failed();
      const msg = proccessErrorMessage(error);
      if (msg !== "No security questions found for the user.") {
        toast.error(msg);
      }
      throw error;
    }
  }
);

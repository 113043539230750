import React, { useState } from "react";
import close from "../../Assets/Images/close.png";
import wallet from "../../Assets/Images/wallet2.png";
import { LiaAngleLeftSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import CustomInput from "../Inputs/CustomInput";
import { formatAmount } from "../../utils/formatAmount";

const FundingFromCard = ({ addCard, next, handleClose, handleBack }) => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(0.0);
  return (
    <div className="flex flex-col justify-center items-center border-[1px] border-[#F0F0F5] w-[90%] sm:w-[553px] mt-[30px] mx-auto mb-[30px] h-[524px] rounded-[14px] drop-shadow-custom relative bg-white">
      <div
        className="absolute p-3 top-3 right-3 cursor-pointer"
        onClick={handleClose}
      >
        <img src={close} alt="Close" className="w-6 h-6" />
      </div>
      <div className="flex mt-[45px] gap-[95px] items-center justify-start mb-[10px] w-full px-8 ">
        <div
          className="flex  items-center gap-[3px] cursor-pointer"
          onClick={handleBack}
        >
          <LiaAngleLeftSolid size={18} color="#9c9c9e" className="mr-2" />
          <h3 className="text-[14px] font-[400] text-[#9c9c9e]">Back</h3>
        </div>
        <div className="text-[16px] text-[#131e3d] font-[400] ">
          Fund from New Debit Card
        </div>
      </div>
      <div className="flex mt-[20px] flex-col items-center w-full h-full px-4">
        <CustomInput
          fullWidth
          noBorder
          bg={true}
          height={81}
          autoFocus={true}
          placeholder={``}
          autoComplete={"off"}
          customTextStyle={{}}
          textInputCustomStyles={{
            textAlign: "center",
            fontSize: "64px",
            color: "#131e3d",
          }}
          variant={"standard"}
          selfValue={amount}
          customType="amount"
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />
        <label
          htmlFor="cardSelect"
          className="text-[14px] mt-[10px] ml-[25px] font-[400] text-[#9c9c9e] mb-2 self-start"
        >
          Debit Cards
        </label>
        <select
          id="cardSelect"
          className="w-[467px] h-[50px] mt-[10px] shadow-custom  text-[#131e3d] p-2 mb-3 border-[1px] border-[#E0E0E0] rounded-[8px]"
        >
          <option value="" disabled>
            {""}
          </option>
          <option value="card1">Card 1</option>
          <option value="card2">Card 2</option>
          <option value="card3">Card 3</option>
        </select>
        <p
          onClick={addCard}
          className="text-[#6935D3] cursor-pointer font-[400] ml-[25px] text-[14px] self-start mb-5"
        >
          New debit card?
        </p>
        <div className="flex mb-4 items-center mt-[30px] gap-[5px]">
          <img src={wallet} alt="Wallet" className="w-[14px] h-[14px]" />
          <div className="text-[12px] font-[400] text-[#9c9c9e]">
            USD Wallet Balance: $4,560.00
          </div>
        </div>
        <button
          onClick={next}
          className="w-[203px] h-[45px]  rounded-[8px] bg-[#6935D3] shadow-sm flex justify-center items-center font-[500] text-[16px] text-white"
        >
          Withdraw {formatAmount(amount)}
        </button>
      </div>
    </div>
  );
};

export default FundingFromCard;

import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_GET_STATEMENT } from "../../../config/api";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";

export const getStatement = createAsyncThunk(
  "getStatement/Post",
  async ({ data, currency, cb, failed }, { dispatch }) => {
    try {
      const response = await Client({
        method: "POST",
        path: `${API_GET_STATEMENT}${currency}`,
        data,
      });
      cb();

      toast.success(
        "Your investment statement has been forwarded to your email."
      );
      return response?.data?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);

      failed();
    }
  }
);

import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import { API_USER_BANK_DETAILS, API_REMOVE_BANK } from "../../../config/api";
import { showErrorModal } from "../../reducers/messageSlice";
import { getUserBanks } from "./getUserBanks";
import mixpanel from "mixpanel-browser";

export const addBankDetails = createAsyncThunk(
  "banks/add",
  async ({ data, cb, failed }, { dispatch }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_USER_BANK_DETAILS,
        data: data,
      });

      dispatch(getUserBanks());
      cb();
      // toast.success("Bank account was successfully added.");
      mixpanel.track("Success", {
        type: "Banks",
        subtype: "Upload-NGN-Bank-Details",
      });
      return response;
    } catch (error) {
      failed();
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      mixpanel.track("Error", {
        type: "Banks",
        subtype: "Upload-NGN-Bank-Details",
        message: msg,
      });
      dispatch(showErrorModal(msg));
    }
  }
);

import React from "react";
import { useSelector } from "react-redux";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const CustomAreaChart = ({ currentPerfomanceRange }) => {
  const investmentMetrics = useSelector(
    (state) => state?.dashboard?.investmentMetrics
  );

  // const CustomTooltip = ({ payload }) => {
  //   return (
  //     <div className="relative items-center flex">
  //       <div className="border-4 -mr-1 w-0 h-0 rotate-45 bg-[rgba(26,26,26,1)]  border-[rgba(26,26,26,1)] border-t-transparent border-r-transparent" />
  //       <div className="h-[40px] px-2 py-1 min-w-[102px] w-max bg-[rgba(26,26,26,1)]">
  //         <p className="text-[rgba(255,255,255,1)] font-[400] text-[10px] font-sf_pro_text">
  //           vNPS:{" "}
  //           <span className="font-[600]"> {payload?.[0]?.payload?.vNPS}</span>
  //         </p>
  //         <p className="text-[rgba(255,255,255,1)] font-[400] text-[10px] font-sf_pro_text">
  //           {`${payload?.[0]?.payload?.keyEvent}, ${payload?.[0]?.payload?.year}`}
  //         </p>
  //       </div>
  //     </div>
  //   );
  // };
  const _perfomanceRange = Number(currentPerfomanceRange.split(" ")[0]);

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      style={{ paddingTop: "16px" }}
    >
      <AreaChart
        height={600}
        width={600}
        data={
          investmentMetrics?.filter(
            (item) =>
              (new Date() - new Date(item?.date)) / 1000 / 60 / 60 / 24 <=
              _perfomanceRange
          )?.length > 0
            ? [
                { name: "", investment: 0, interest: 0, date: 0 },
                ...investmentMetrics
                  ?.filter(
                    (item) =>
                      (new Date() - new Date(item?.date)) /
                        1000 /
                        60 /
                        60 /
                        24 <=
                      _perfomanceRange
                  )
                  ?.map((i) => ({
                    name: "",
                    investment: i?.amount,
                    interest: i?.roi,
                    date: i?.date,
                  })),
              ]
            : [
                { name: "", investment: 0, interest: 0, date: 0 },
                { name: "", investment: 0, interest: 0, date: 0 },
              ]
        }
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id="colorinvestment" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#31D3C6" stopOpacity={0.4} />
            <stop offset="95%" stopColor="#31D3C6" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          //   padding={{ left: 30 }}
          axisLine={false}
          tickLine={false}
          dataKey="name"
        />
        <YAxis padding={{ bottom: 5 }} hide axisLine={false} tickLine={false} />
        {/* <Tooltip content={<CustomTooltip />} /> */}
        <Tooltip />
        <Area
          dataKey="investment"
          name="Investments"
          strokeWidth={"2px"}
          type={"monotone"}
          //   aria-hidden={true}

          //   activeDot={{ stroke: "rgba(36, 42, 48, 1)", strokeWidth: 12 }}
          //   stroke="rgba(90, 64, 19, 1)"
          stroke="#29A99F"
          fillOpacity={1}
          fill="url(#colorinvestment)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default CustomAreaChart;

import { Cookies } from "react-cookie";

export const storeAccessToken = (token) => {
  const date = new Date();
  const nextDate = date.setMinutes(date.getMinutes() + 15);
  const cookies = new Cookies();

  cookies.set("accessToken", token, {
    expires: new Date(nextDate),
  });
};

export const removeAccessToken = () => {
  const cookies = new Cookies();
  localStorage.clear();
  cookies.remove("accessToken");
};

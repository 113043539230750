import * as Yup from "yup";
const passwordRules = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/;
// /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const oldUsersSchema = Yup.object().shape({
  country_of_residence: Yup.object().required("Country of residence"),
  state: Yup.object().required("Country of residence"),
  address: Yup.string().required("Residential address is required"),
  postal_code: Yup.string().required("Residential address is required"),
});
export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .max(20, "The allowable length of the password is 20"),
});

export const forgotValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

export const resetPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(20, "The allowable length of the password is 20")
    .matches(passwordRules, {
      message:
        "An uppercase, a lowercase, a digit, and a special character are required.",
    }),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const bankDetailsSchema = Yup.object().shape({
  accountName: Yup.string().required("This field is required"),
  accountNumber: Yup.string()
    .length(10, "This field must have exactly ten digits")
    .required("This field is required"),
  bankName: Yup.object().required("This field is required"),
  BVN: Yup.number()
    .typeError("Only numeric values are allowed")
    .required("This field is required"),
});
export const IDVerificationSchema = Yup.object().shape({
  id_number: Yup.string().required("This field is required"),
  id_type: Yup.object().required("This field is required"),
});
export const BVNSchema = Yup.object().shape({
  BVN: Yup.string()
    .length(11, "This field must have eleven digits")
    .required("This field is required"),
});
export const WitdrawalSchema = Yup.object().shape({
  amount: Yup.string().required("This field is required"),
});

export const paymentSchema = Yup.object().shape({
  expiryDate: Yup.string().required("Required"),
  //  .length(5, "Invalid expiry date."),
  cvv: Yup.string().required("Required").length(3, "Invalid pin"),
  cardNumber: Yup.string().required("Required"),
  // .length(19, "This field requires a fourteen-digit number."),
});

export const nationalitySchema = Yup.object().shape({
  nationality: Yup.object().required("This field is required"),
});
export const signupSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(20, "The allowable length of the password is 20")
    .matches(passwordRules, {
      message:
        "An uppercase, a lowercase, a digit, and a special character are required.",
    }),
});

export const signUpForTealValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),

  phone: Yup.string().required("Phone Number is required"),

  email: Yup.string().email("Invalid email").required("Email is required"),
});

export const accountStaementSchema = Yup.object().shape({
  accountType: Yup.object(),
  currency: Yup.object().required("Currency is required"),
  start_date: Yup.date().required("Start Date is required"),
  end_date: Yup.date().required("End Date is required"),
  // start_date: Yup.date().when("accountType", {
  //   is: (accountType) => {
  //     return accountType?.value === "Account Statement";
  //   },
  //   then: () => Yup.date().required("Start Date is required"),
  //   otherwise: () => Yup.date(),
  // }),
  // end_date: Yup.date().when("accountType", {
  //   is: (accountType) => {
  //     return accountType?.value === "Account Statement";
  //   },
  //   then: () => Yup.date().required("End Date is required"),
  //   otherwise: () => Yup.date(),
  // }),
});
export const setPasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(20, "The allowable length of the password is 20")
    .matches(passwordRules, {
      message:
        "An uppercase, a lowercase, a digit, and a special character are required.",
    }),
  // new_confirm_password: Yup.string()
  //   .when("new_password", (newPassword, schema) => {
  //     if (newPassword) return schema.required("Confirm Password is required");
  //   })
  //   .oneOf([Yup.ref("new_password")], "Passwords must match"), // Corrected field name
});
export const resetPasswordSchema = Yup.object().shape({
  current_password: Yup.string()
    .required("Current Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(20, "The allowable length of the password is 20"),
  new_password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(20, "The allowable length of the password is 20")
    .matches(passwordRules, {
      message:
        "An uppercase, a lowercase, a digit, and a special character are required.",
    }),
  // new_confirm_password: Yup.string()
  //   .when("new_password", (newPassword, schema) => {
  //     if (newPassword) return schema.required("Confirm Password is required");
  //   })
  //   .oneOf([Yup.ref("new_password")], "Passwords must match"), // Corrected field name
});
export const bankSchema = Yup.object().shape({
  // account_name: Yup.string()
  //   .matches(/^[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/, "Invalid Account Name")
  //   .required("Account Name is required")
  //   .test("regex test", `Invalid Account Name`, (val) => {
  //     const regExp = /[a-zA-Z]/;
  //     return regExp.test(val);
  //   }),
  account_number: Yup.string()
    .when("bank_name", (bank_name, schema) => {
      if (bank_name[0]) {
      } else {
        return schema.required("Enter bank name first");
      }
    })
    .length(10, "Account Number must have 10 digits")
    .required("Account Number is required"),
  bank_name: Yup.string().required("Bank Name is required"),
});

export const investorProfileSchema = Yup.object().shape({
  pastInvestment: Yup.string().required("Required"),
  riskTolerance: Yup.string().required("Required"),
  other_goals: Yup.string().when(
    "investmentGoals",
    (investmentGoals, schema) => {
      if (investmentGoals[0] === "Others (please specify)")
        return schema.required("Required");
    }
  ),

  // "SME Financin",
  //   "Real Estate Investing",
  //   "Startup Investing",
  //   "
  investmentGoals: Yup.string().required("Required"),
  // category_invest: Yup.string().when(
  //   ["SME Financing", "Real Estate Investing", "Startup Investing"],
  //   {
  //     is: true,
  //     then: Yup.string().required("Required"),
  //   }
  // ),
  // category_invest: Yup.string().when(
  //   ["SMEFinancing", "RealEstateInvesting", "StartupInvesting"],
  //   {
  //     is: (SMEFinancing, RealEstateInvesting, StartupInvesting) => {
  //       return !SMEFinancing && !RealEstateInvesting && !StartupInvesting;
  //     },
  //     then: Yup.string().required("Required"),
  //   }
  // ),
  yearly_invest: Yup.string().required("Required"),
  sourceOfIncome: Yup.string().required("Required"),
  incomeRange: Yup.string().required("Required"),
});
export const supprtSchema = Yup.object().shape({
  message: Yup.string().required("This field is required"),
});

export const pinSchema = Yup.object().shape({
  current_pin: Yup.string()
    .required("Pin is required")
    .length(4, "Pin must have only 4 characters"),
  new_pin: Yup.string()
    .required("Pin is required")
    .length(4, "Pin must have only 4 characters"),

  // confirm_pin: Yup.string()
  // .oneOf([Yup.ref("new_pin"), null], "PIN must match")
  // .required("Confirm PIN is required"),
});
export const setPinSchema = Yup.object().shape({
  new_pin: Yup.string()
    .required("Pin is required")
    .length(4, "Pin must have only 4 characters"),

  confirm_pin: Yup.string()
    .oneOf([Yup.ref("new_pin"), null], "PIN must match")
    .required("Confirm PIN is required"),
});

export const unlockAccountSchema = Yup.object().shape({
  answer: Yup.string().required("This field is required"),
  q: Yup.object().required("This field is required"),
});
export const securityQuestionSchema = Yup.object().shape({
  answer2: Yup.string().required("This field is required"),
  answer1: Yup.string().required("This field is required"),
  q1: Yup.object().required("This field is required"),
  q2: Yup.object().required("This field is required"),
});
export const nokProfileDetailsSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("First Name is required"),
  email: Yup.string().email("Invalid email").required("First Name is required"),
  birth_date: Yup.date().required("Date of Birth is required"),
  phone: Yup.string().required("Phone Number is required"),
  gender: Yup.object().required("Gender is required"),
});
export const profileDetailsSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  phone: Yup.string().required("Phone Number is required"),
  gender: Yup.object().required("Gender is required"),
  country: Yup.object().required("Country is required"),
  country_of_residence: Yup.object().required(
    "Country of Residence is required"
  ),
  // state: Yup.object().required("State is required"),
  // city: Yup.object().required("City is required"),
  state: Yup.object().required("State is required"),
  // city: Yup.string().required("City is required"),
  address: Yup.string().required("Street Address is required"),

  birth_date: Yup.date().required("Date of Birth is required"),
});

import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import { API_RESEND_OTP } from "../../../config/api";
import mixpanel from "mixpanel-browser";

export const resendOTP = createAsyncThunk(
  "users/resendOTP",
  async ({ data, cb }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_RESEND_OTP,
        data,
      });
      toast.success("Verification OTP has been resent successfully.");
      if (cb) {
        cb();
      }
      return response.data;
    } catch (error) {
      const message = proccessErrorMessage(error);

      mixpanel.track("Error", {
        type: "onboarding",
        subtype: "resend-otp",
        message: message,
      });
      toast.error(message || "Invalid user ID or token");
      throw error;
    }
  }
);

import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
// import { processErrorMessage } from "../../../Helper/processErrorMessage";
import { API_GET_OPPORTUNITIES } from "../../../config/api";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import mixpanel from "mixpanel-browser";

export const getOpportunities = createAsyncThunk(
  "opportunities/getList",
  async (currency, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_OPPORTUNITIES,
      });

      if (!response.data) {
        // Check if the data is not empty and is an array or object as expected
        toast.error("No data received from the API");
        return rejectWithValue("No data received from the API");
      }

      return response.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      mixpanel.track("Error", {
        type: "opportunities",
        subtype: "get-opportunities",
        message: msg,
      });

      // If you have a function to process the error message, use it here
      // const message = processErrorMessage(error);
      // toast.error(message);
      // Use rejectWithValue to return a rejected action with a specific payload
      return rejectWithValue(error.message || "Error fetching opportunities");
    }
  }
);

import React from "react";
import PropTypes from "prop-types";
import loc from "../Assets/Images/loc.png";

const RealEstateOpportunities = ({
  imageSrc,
  location,
  title,
  price,
  investedPercentage,
  totalAmount,
}) => {
  return (
    <div className="relative mx-5 w-[334px] h-[413px]">
      <div className="relative mb-[20px] w-[334px] h-[269px]">
        <img
          src={imageSrc}
          alt="Real Estate"
          className="w-[334px] h-[269px] rounded-[10px] object-cover"
        />
        <div className="absolute bottom-0 left-0 mb-[15px] ml-[15px] w-[180px] h-[27px] rounded-[30px] bg-[#2C2C2C66] flex items-center text-white px-3">
          <img src={loc} alt="Location" className="w-[16px] h-[16px] mr-2" />
          <p>{location}</p>
        </div>
      </div>
      <div className="mt-[20px] font-[500] text-[16px] text-[#131E3D]">
        {title}
      </div>
      <div className="font-[400] text-[14px] text-[#131E3D]">
        ${price} per unit
      </div>
      <div className="mt-[5px] font-[400] text-[14px] text-[#9c9c9e]">
        {investedPercentage}% invested of ${totalAmount}
      </div>
      <div className="w-full mt-[10px] bg-[#F2F2F7] rounded-full h-[4px]">
        <div
          className="bg-[#6935D3] h-[4px] rounded-full"
          style={{ width: `${investedPercentage}%` }}
        ></div>
      </div>
    </div>
  );
};

RealEstateOpportunities.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  investedPercentage: PropTypes.number.isRequired,
  totalAmount: PropTypes.number.isRequired,
};

export default RealEstateOpportunities;

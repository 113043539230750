import React from "react";
import recent from "../Assets/Images/recent.png";

const RecentTransaction = ({ onClick, tableStyle }) => {
  return (
    <div
      className={` mb-[10px] w-full max-w-[698px]  h-[301px] rounded-[14px] border-[1px] border-[#F0F0F5] flex flex-col justify-center items-center ${tableStyle}`}
    >
      <div className="">
        <img src={recent} alt="" className=" mb-[5px] w-[76px] h-[76px] " />
      </div>
      <div className=" mb-[5px] p-2 px-4 text-center text-[16px] font-[400] text-[#9C9C9E] ">
        Make your first deposit to see some activity
      </div>
      {/* <div className="">
        <button
          onClick={onClick ? onClick : null}
          className=" w-[203px] h-[45px] rounded-[8px] bg-[#6935D3] text-white  transition-colors duration-200 transform rounded-lg xl:h-10 hover:bg-[#6935D3]/80"
        >
          {" "}
          Invest Now{" "}
        </button>
      </div> */}
    </div>
  );
};

export default RecentTransaction;

import React, { useState } from "react";
import web from "../Assets/Images/web.png";
import calender from "../Assets/Images/calender.png";
import credit from "../Assets/Images/credit.png";
import sell from "../Assets/Images/sell.png";
import { toast } from "react-toastify";
import { Parser } from "html-to-react";
import { formatAmount } from "../utils/formatAmount";
import CustomTooltip from "../Components/containers/ToolTip";

const AboutInvesment = ({ opportunity }) => {
  const htmlParser = new Parser();

  const [readAll, setReadAll] = useState(false);
  return (
    <div className=" mb-[20px] w-full md:max-w-[640px] ">
      <div className="mb-[40px]  w-full  md:w-[645px] h-[397px] ">
        <img
          src={opportunity?.company_logo || opportunity?.banner_link}
          alt=""
          className=" w-full md:w-[645px] h-[397px] rounded-[14px] object-cover"
        />
      </div>
      <div className=" flex mb-[10px] justify-between items-center ">
        <div className=" text-[16px] font-[500] text-[#131E3D] ">About</div>
        <div
          className={`cursor-pointer flex items-center gap-[5px] ${
            opportunity?.website?.includes("http")
              ? ""
              : "opacity-65 cursor-not-allowed"
          }`}
        >
          <a
            // onClick={() => {
            //   if (!opportunity?.investment_memo) {
            //     toast.error("Sorry! The document has not been uploaded.");
            //   }
            // }}
            rel="noreferrer"
            href={
              opportunity?.website?.includes("http")
                ? opportunity?.website
                : null
            }
            // download={`${opportunity?.name} Documents`}
            target="_blank"
            className=" text-[#6935D3] text-[16px] font-[400]"
          >
            Visit website
          </a>
          <img src={web} alt="" className=" w-[20px] h-[20px] " />
        </div>
      </div>
      <div className=" h-[0.5px] w-full bg-[#F2F2F7] "></div>

      <div className=" mt-[10px] ">
        <h4 className="">
          <span
            className={`  ${
              readAll ? "" : "line-clamp-2"
            } text-[#9C9C9E] text-[16px] font-[400] `}
          >
            {opportunity?.description && opportunity?.description !== "null"
              ? htmlParser?.parse(opportunity?.description)
              : ""}{" "}
          </span>
        </h4>
        {opportunity?.description?.length > 120 ? (
          <h4
            onClick={() => {
              setReadAll((prev) => !prev);
            }}
            className="cursor-pointer  text-right w-full text-[#6935D3]"
          >
            {readAll ? "Show Less." : "Read More."}
          </h4>
        ) : (
          <></>
        )}
      </div>

      <div className=" mt-[20px] flex gap-[8px] ">
        {opportunity?.investment_category?.name ||
        opportunity?.category?.name ? (
          <div className=" bg-[#F2F2F7] px-3 min-w-[99px] font-[400] text-[14px] text-[#131E3D]  h-[33px] rounded-[40px] p-4 flex items-center justify-center ">
            {opportunity?.investment_category?.name ||
              opportunity?.category?.name}
          </div>
        ) : (
          <></>
        )}
        {opportunity?.sector ? (
          <div className=" bg-[#F2F2F7] px-3 min-w-[64px] font-[400] text-[14px] text-[#131E3D] h-[33px] rounded-[40px] p-4 flex items-center justify-center ">
            {opportunity?.sector}
          </div>
        ) : (
          <></>
        )}
        {/* <div className=" bg-[#F2F2F7] px-3 min-w-[83px] font-[400] text-[14px] text-[#131E3D] h-[33px] rounded-[40px] p-4 flex items-center justify-center ">
          Farming{" "}
        </div> */}
      </div>

      <div className="  mx-auto w-full  ">
        <div className="flex mt-[40px]  justify-between">
          <div className="flex gap-[6px]  items-center">
            <img src={calender} alt="" className=" w-[18px] h-[18px] " />
            <div className=" text-[16px] font-[400] text-[#9C9C9E] ">
              Incorporation Date
            </div>
          </div>
          <div className=" text-[#131E3D] text-[16px] font-[400] ">
            {opportunity?.incorporation_date || "--"}
          </div>
        </div>
        <div className=" mt-[10px] w-full bg-[#F2F2F7] h-[1.5px]  "></div>
      </div>

      <div className=" mx-auto w-full ">
        <div className="flex mt-[10px] items-center  justify-between">
          <div className=" flex gap-[6px] items-center ">
            <img src={credit} alt="" className=" w-[18px] h-[18px] " />
            <div className=" text-[16px] font-[400] text-[#9C9C9E] ">
              Credit Rating
            </div>
          </div>
          <div className=" text-[#131E3D] text-[16px] font-[400] flex items-center">
            {opportunity?.credit_rating_type
              ? `${opportunity?.credit_rating}%[${opportunity?.credit_rating_type}]`
              : opportunity?.credit_rating || "--"}{" "}
            <CustomTooltip message={"---"}>
              <span className="cursor-pointer text-[16px] font-[400] text-[#6935D3] ">
                ?
              </span>
            </CustomTooltip>
          </div>
        </div>
        <div className=" mt-[10px] w-full bg-[#F2F2F7] h-[1.5px]  "></div>
      </div>

      <div className=" mx-auto w-full ">
        <div className="flex mt-[10px]  justify-between">
          <div className=" flex gap-[6px] items-center ">
            <img src={sell} alt="" className="w-[18px] h-[18px] " />
            <div className=" text-[16px] font-[400] text-[#9C9C9E] ">
              {" "}
              Average Monthly Revenue ({opportunity?.revenue_duration})
            </div>
          </div>
          <div className=" text-[#131E3D] text-[16px] font-[400] ">
            {opportunity?.revenue_currency === "USD" ? "$" : "₦"}
            {formatAmount(Number(opportunity?.revenue))}{" "}
          </div>
        </div>
        <div className=" mt-[10px] w-full bg-[#F2F2F7] h-[1.5px]  "></div>
      </div>
    </div>
  );
};

export default AboutInvesment;

import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  API_USER_BANK_DETAILS,
  API_USER_USD_BANK_DETAILS,
} from "../../../config/api";
import { showErrorModal, showSuccessModal } from "../../reducers/messageSlice";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";

export const deleteUserBank = createAsyncThunk(
  "banks/delete",
  async ({ bankId, setDeleteLoading, currency, cb }, { dispatch }) => {
    try {
      const { data } = await Client({
        method: "DELETE",
        path: `${
          currency?.toLowerCase() === "usd"
            ? API_USER_USD_BANK_DETAILS
            : API_USER_BANK_DETAILS
        }/${bankId}`,
      });

      setDeleteLoading(false);
      cb();

      toast.success("Bank account removed successfully.");
      mixpanel.track("Success", {
        type: "Banks",
        subtype: "delete-Bank-Details",
      });
      return data;
    } catch (error) {
      const msg = proccessErrorMessage(error);

      toast.error(msg || "Error removing bank account.");
      mixpanel.track("Error", {
        type: "Banks",
        subtype: "delete-Bank-Details",
        message: msg,
      });
      setDeleteLoading(false);
    }
  }
);

import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import Overview from "../../Components/Overview";
import TransactionTable from "../../Components/TransactionTable";
import RecentTransaction from "../../Components/RecentTransaction";
import ProfileSetup from "../../Components/ProfileSetup";
import Porfolio from "../../Components/Porfolio";
import PortfolioAllocation from "../../Components/PortfolioAllocation";
import { FiMoreVertical } from "react-icons/fi";
import Card from "../../Components/Card";
import ngnInvest from "../../Assets/Images/ngn-invest.png";
import usdInvest from "../../Assets/Images/usd-invest.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOpportunities } from "../../store/actions/opportunities/getopportunities";
import { getPayout } from "../../store/actions/payouts/getPayout";
import { autoFetchPayout } from "../../store/actions/payouts/autoFetchPayout";
import { autoGetProfile } from "../../store/actions/profile/autoGetProfile ";

import Loader from "../../Components/Utils/Loader";
import { allDashboard } from "../../store/actions/dashboard/allDashboard";
import UpdatePin from "../../Components/UpdatePin";
import DashboardPortfolioOverview from "../../Components/DashboardPortfolioOverview";
import FilterButton from "../../Components/Buttons/FilterButton";
import FollowAndTrack from "../../Components/FollowAndTrack";
import PortfolioDateModal from "../../Components/PortfolioDateModal";
import UpdateIdOldUsers from "../../Components/UpdateIdOldUsers";
import { fetchUserProfile } from "../../store/actions/profile/getProfile";
import { setActiveTab } from "../../store/reducers/dashboardSlice";
import { getUserBanks } from "../../store/actions/bank/getUserBanks";
import InProgress from "../../Components/InProgress";

const DashboardHome = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [showPinModal, setShowPinModal] = useState(false);
  const [showOldUsersModal, setShowOldUsersModal] = useState(false);
  const [investmentStatementModalOpen, setInvestmentStatementModalOpen] =
    useState(false);

  const { activeTab } = useSelector((state) => state?.dashboard);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { user, profile } = useSelector((state) => state.onboarding);
  const navigate = useNavigate();

  const { userBanks } = useSelector((state) => state?.bank) || [];

  const dispatch = useDispatch();
  // const currency = "NGR";

  const [currency, setCurrency] = useState("Naira");

  useEffect(() => {
    dispatch(getOpportunities(currency));
    dispatch(getPayout(currency === "Naira" ? "NGR" : "USD"));

    const clean = setInterval(() => {
      dispatch(autoFetchPayout(currency));
      dispatch(autoGetProfile());
    }, 60000);
    return () => {
      clearInterval(clean);
    };
  }, [currency]);

  // useEffect(() => {
  //   // dispatch(getTransactions(currency));
  //   const clean = setInterval(() => {
  //     dispatch(autoFetchTransactions(currency));
  //   }, 60000);
  //   return () => {
  //     clearInterval(clean);
  //   };
  // }, [currency, dispatch]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(
      allDashboard(() => {
        setLoading(false);
      })
    );
  }, []);

  useEffect(() => {
    setShowOldUsersModal(true);
  }, []);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, []);

  useEffect(() => {
    dispatch(getUserBanks());
  }, []);

  const verificationStatus = localStorage.getItem("verificationStatus");

  return (
    <>
      <div className="flex relative flex-col bg-[#F9FAFA] gap-6 pt-3 pb-3 items-center min-h-screen">
        <Navbar type={3} />

        <div className="w-[95%] flex-col lg:flex-row flex-1 h-full border-[#F2F2F2] border-[1.1px] drop-shadow-custom rounded-[12px] gap-5 p-6 md:p-10 bg-[#FFF] max-w-[1664px] flex">
          {loading && !profile && userBanks?.length < 1 ? (
            <Loader />
          ) : (
            <>
              <div className="flex flex-col flex-1">
                <div className="w-min mt-3 border-[#F0F0F5] drop-shadow-custom mb-4 p-2 h-[49px] border-[1px] rounded-[41px] flex justify-center items-center">
                  <FilterButton
                    items={[
                      { label: "Overview", value: "overview" },
                      { label: "Portfolio", value: "portfolio" },
                    ]}
                    active={activeTab}
                    setActive={(value) => {
                      dispatch(setActiveTab(value));
                    }}
                  />
                </div>
                <h2 className="text-[54px] font-[400] leading-[59.4px] tracking-[-3.1%] mb-[20px] max-w-[380px]">
                  {userBanks?.length > 0 ||
                  profile?.nok_email ||
                  profile?.gender
                    ? "Welcome back"
                    : "Get started"}
                  , {user?.name?.split(" ")[0]}
                </h2>
                <div className="mt-3">
                  <h4 className="font-[500] text-[#131E3D] mb-[16px] text-base leading-[17.6px] tracking-[-1%]">
                    {activeTab === "overview" ? "Overview" : "Portfolio"}
                  </h4>
                  {activeTab === "overview" ? (
                    <DashboardPortfolioOverview
                      currency={currency}
                      setCurrency={setCurrency}
                    />
                  ) : (
                    <Overview currency={currency} setCurrency={setCurrency} />
                  )}
                </div>
                <div>
                  {activeTab === "overview" ? (
                    <>
                      <div className="max-w-[698px] flex items-center justify-between mb-5 mt-10 pr-1 ">
                        <h4 className="font-[500] text-[#131E3D] text-base leading-[17.6px] tracking-[-1%]">
                          Recent Transactions
                        </h4>
                        <FiMoreVertical
                          className="cursor-pointer"
                          size={20}
                          color={"#131E3D"}
                        />
                      </div>
                      <TransactionTable currency={currency} />
                    </>
                  ) : (
                    <div
                      // onClick={() => {
                      //   navigate("/investment-statement");
                      // }}
                      className="flex items-center gap-2 py-3 mt-7 cursor-not-allowed"
                    >
                      <img
                        src={require("../../Assets/Images/download-statement.png")}
                        className="w-5 h-5"
                      />
                      <h3 className="text-[13.33px] font-[500] text-[#6935D3]">
                        DOWNLOAD YOUR INVESTMENT STATEMENT
                      </h3>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col flex-1">
                {user?.hasSetPin &&
                profile?.nok_email &&
                profile?.gender &&
                userBanks?.length > 0 ? (
                  activeTab === "overview" ? (
                    <div className="max-w-[731px] flex items-center justify-between mb-5 mt-10 mr-1 ">
                      <h4 className="font-[500] text-[#131E3D] text-base leading-[17.6px] tracking-[-1%]">
                        Explore Opportunities
                      </h4>
                    </div>
                  ) : activeTab === "overview" ? (
                    <></>
                  ) : (
                    <div className="h-[216px]"> </div>
                  )
                ) : (
                  <div>
                    <div className="max-w-[731px] flex items-center justify-between mb-5 mt-10 mr-1 ">
                      <h4 className="font-[500] text-[#131E3D] text-base leading-[17.6px] tracking-[-1%]">
                        Complete your profile{" "}
                        <span className="font-[500] text-[#9C9C9E] text-base leading-[17.6px] tracking-[-1%]">
                          in ~5 minutes
                        </span>
                      </h4>
                      <FiMoreVertical
                        className="contain-paint cursor-pointer"
                        size={20}
                        color={"#131E3D"}
                      />
                    </div>
                    <ProfileSetup setShowPinModal={setShowPinModal} />
                  </div>
                )}
                {activeTab === "overview" ? (
                  <div className="gap-6 flex-wrap flex">
                    <Card
                      onClick={() => {
                        navigate(`/investment?currency=NGR`);
                      }}
                      className=" transition duration-[800ms] ease-in-out hover:scale-[102%]    hover:bg-customPurple/90 "
                      type={2}
                      background="bg-customPurple"
                      text="Invest in Naira Assets"
                      imageSrc={ngnInvest}
                    />
                    <Card
                      onClick={() => {
                        navigate(`/investment?currency=USD`);
                      }}
                      type={2}
                      className=" transition duration-[800ms] ease-in-out hover:scale-[102%]      hover:bg-[#192851]/90  "
                      background="bg-[#192851]"
                      text="Invest in USD Assets"
                      imageSrc={usdInvest}
                    />
                  </div>
                ) : (
                  <></>
                )}

                {activeTab !== "overview" ? (
                  <div className="mt-3">
                    <h4 className="font-[500] text-[#131E3D] mb-[16px] text-base leading-[17.6px] tracking-[-1%]">
                      Portfolio Allocation
                    </h4>
                    <PortfolioAllocation />
                  </div>
                ) : (
                  <></>
                )}
                {/* {activeTab === "overview" ? <FollowAndTrack /> : <Porfolio />} */}
                {activeTab === "overview" ? <FollowAndTrack /> : <></>}
              </div>
            </>
          )}
        </div>
      </div>
      {verificationStatus === "Pending-Notification" ? (
        <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <InProgress
            subText={"Your ID is currently being reviewed"}
            buttonTitle="Ok"
            text={"Undergoing Review"}
            handleClose={() => {
              localStorage.setItem("verificationStatus", "Pending");
            }}
            next={() => {
              localStorage.setItem("verificationStatus", "Pending");
            }}
          />
        </div>
      ) : (
        <></>
      )}
      {showOldUsersModal &&
      !profile?.id_verified &&
      verificationStatus !== "Pending-Notification" &&
      verificationStatus !== "No-Response" &&
      verificationStatus !== "Pending" ? (
        <div className="top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <UpdateIdOldUsers
            user={user}
            profile={profile}
            small
            handleClose={() => {
              setShowOldUsersModal(false);
            }}
          />
        </div>
      ) : null}
      {showPinModal ? (
        <div className="top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <UpdatePin
            small
            handleClose={() => {
              setShowPinModal(false);
            }}
            next={(value) => {
              setShowConfirmationModal(value);
              setShowPinModal(false);
            }}
          />
        </div>
      ) : null}

      {investmentStatementModalOpen && (
        <PortfolioDateModal
          defaultCurrency={currency}
          onClose={() => setInvestmentStatementModalOpen(false)}
        />
      )}
    </>
  );
};

export default DashboardHome;

export const formatAmount = (digit, decimalPlaces = 2, toKobo = false) => {
  const unit = 100;
  let amount = toKobo ? Number(digit) * unit : Number(digit);

  if (digit === 0) {
    return "0.00";
  }
  if (!digit) {
    return "";
  }
  if (!decimalPlaces) {
    return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") ?? 0.0;
  }
  return (
    amount?.toFixed(decimalPlaces).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") ??
    0.0
  );
};

import React, { useCallback, useState } from "react";
import { FiX } from "react-icons/fi"; // Close icon
import Switcher from "./Switcher";
import FilterButton from "./Buttons/FilterButton";
import { formHandler } from "../utils/formhandler";
import MainButton from "./Buttons/MainButton";
import { useFormik } from "formik";
import dayjs from "dayjs";
import DatePicker from "./Inputs/DatePicker";
import { getStatement } from "../store/actions/portfolio/getStatement";
import { useDispatch } from "react-redux";

const PortfolioDateModal = ({ onClose, defaultCurrency }) => {
  const [currency, setCurrency] = useState(
    defaultCurrency?.value === "NGN" || defaultCurrency === "Naira"
      ? {
          label: "Naira",
          value: "NGN",
        }
      : {
          label: "USD",
          value: "USD",
        }
  );
  const [activeTab, setActiveTab] = useState("Custom Period");

  // if (!isOpen) return null;
  const dispatch = useDispatch();
  const now = new Date();
  const last2Month = now.setMonth(now.getMonth() - 2);
  const handleSubmit = useCallback(
    ({ start_date, end_date }, { setSubmitting }) => {
      const data = {
        type: activeTab,
        start_date,
        end_date,
      };
      dispatch(
        getStatement({
          data,
          currency: currency?.value,
          cb: () => {
            setSubmitting(false);
          },
          failed: () => {
            setSubmitting(false);
          },
        })
      );
    },
    [currency, activeTab]
  );

  const form = useFormik({
    initialValues: {
      start_date: dayjs(last2Month),
      end_date: dayjs(new Date()),
    },
    onSubmit: handleSubmit,
  });

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white  w-[553px]  p-6 rounded-[14px] shadow-custom relative">
        <button onClick={onClose} className="absolute p-3 top-3 right-3 ">
          <FiX size={24} color="#131e3d" />
        </button>

        <h3 className="text-[16px] mt-[40px] font-[400] text-[#131e3d] text-center mb-[40px] ">
          Investment Statement
        </h3>

        <div className=" mb-[15px] flex justify-between items-start   ">
          <div className="w-[300px] text-[9px] border-[#F0F0F5] drop-shadow-custom mb-4 p-2 h-[49px] border-[1px] rounded-[41px] mx-1  flex justify-center items-center">
            <FilterButton
              items={[
                { label: "Custom Period", value: "Custom Period" },
                { label: "Last month", value: "Last month" },
                { label: "Year to date", value: "Year to date" },
              ]}
              active={activeTab}
              setActive={setActiveTab}
            />
          </div>
          <div className=" mt-[10px] ">
            <Switcher
              handleCurrencyChange={setCurrency}
              label={
                defaultCurrency?.value === "NGN" || defaultCurrency === "Naira"
                  ? "Naira"
                  : "USD"
              }
              options={[
                { label: "Naira", value: "NGN" },
                { label: "USD", value: "USD" },
              ]}
            />
          </div>
        </div>

        {activeTab === "Custom Period" ? (
          <div className="flex flex-col gap-[15px] mt-[20px] ">
            <label
              htmlFor=""
              className="text-[14px] font-[400] text-[#9c9c9e] "
            >
              Choose Start Date
            </label>
            <DatePicker
              title={"Choose Start Date"}
              id="start_date"
              {...formHandler("start_date", form)}
              type={"text"}
              form={form}
              placeholder={"Choose Start Date"}
              mainClasses={"mb-0 mt-0 !w-full "}
            />

            <label
              htmlFor=""
              className="text-[14px] font-[400] text-[#9c9c9e] "
            >
              Choose End Date
            </label>
            <DatePicker
              title={"Choose End Date"}
              id="end_date"
              {...formHandler("end_date", form)}
              type={"text"}
              form={form}
              placeholder={"Choose End Date"}
              mainClasses={"mb-0 mt-0 !w-full "}
            />
          </div>
        ) : (
          <></>
        )}

        <div className="flex justify-end mt-6">
          <MainButton
            mainClasses="!h-[52px] !mt-[20px] !mb-0 text-white"
            title={"Send Request"}
            onClick={form.handleSubmit}
            loading={form.isSubmitting}
            disabled={form.isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

export default PortfolioDateModal;

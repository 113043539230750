import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../Utils/Loader";

const ImagePicker = ({ form, id, type, loading }) => {
  const [myImagePreview, setMyImagePreview] = useState(null);

  const imageRef = useRef();

  const handleImageChange = async (e) => {
    if (!e?.dataTransfer?.files[0] && !e?.target?.files[0]) {
      return;
    }
    const files = e?.dataTransfer?.files || e?.target?.files;
    const convertedFiles = files[0];

    form?.setFieldValue(`${id}-name`, convertedFiles.name);

    if (!/(gif|jpe?g|png|jpg|svg|jpeg|pdf)$/i.test(convertedFiles.name)) {
      // dispatch(
      //   showErrorModal(
      //     "Invalid file type. Only PNG, jpg, GIF, JPEG, SVG, and PDF files are accepted."
      //   )
      // );
      return;
    }
    if ((e.target.files[0].size / 1024 / 1024).toFixed(4) > 5) {
      // dispatch(showErrorModal("File size is too big (max. 1.5MB)"));
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsDataURL(convertedFiles);
    fileReader.addEventListener("load", (result) => {
      setMyImagePreview(result.target.result);
    });
    form?.setFieldValue(id, convertedFiles);
    form?.submitForm();
  };
  const { user } = useSelector((state) => state.onboarding);

  return (
    <>
      <div
        onClick={() => {
          imageRef.current.click();
        }}
        className="flex relative justify-center items-center mt-5 mb-6"
      >
        <div className="w-[84px] relative h-[84px]  bg-blue-950  flex justify-center items-center rounded-full">
          {myImagePreview || form?.values[id] ? (
            <img
              src={myImagePreview || form.values[id]}
              alt="camera"
              className="w-[84px] object-cover  h-[84px] absolute top-0 rounded-full"
            />
          ) : type === "pic" ? (
            <h4 className="font-[500] capitalize text-[50px] text-[#FFF]">
              {user?.name?.split(" ")[0]?.slice(0, 1)}
            </h4>
          ) : (
            <></>
          )}
          {loading ? (
            <div className="w-full absolute top-0 left-0 flex h-full  border-[1.2px] bg-[rgba(23,37,84,0.3)] rounded-full">
              <Loader />
            </div>
          ) : (
            <></>
          )}
        </div>
        <input
          id={"id"}
          type={"file"}
          accept=".png, .jpg, .jpeg, .svg"
          ref={imageRef}
          className={`hidden`}
          onChange={(e) => {
            handleImageChange(e);
            // onChange(e?.target?.value);
          }}
        />
      </div>
    </>
  );
};

export default ImagePicker;

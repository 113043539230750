import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import { API_SECURITY_PASSWORD_OTP } from "../../../config/api";
import mixpanel from "mixpanel-browser";

export const getSecurityPasswordOTP = createAsyncThunk(
  "users/getSecurityPasswordOTP",
  async ({ data, cb }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_SECURITY_PASSWORD_OTP,
        data,
      });
      // toast.success("OTP sent successfully!");
      if (cb) {
        cb();
      }
      return response.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      mixpanel.track("Error", {
        type: "onboarding",
        subtype: "get-OTP-to-change-passord",
        message: msg,
      });
      // toast.error(msg);
      throw error;
    }
  }
);

import React, { useEffect, useState } from "react";
import close from "../Assets/Images/close.png";
import TextInput from "./Inputs/TextInput";
import { formHandler } from "../utils/formhandler";
import { useFormik } from "formik";
import { getBankList } from "../store/actions/bank/getBankList";
import { formatArray } from "../utils/data";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Utils/Loader";
import { getAccountName } from "../store/actions/bank/getAccountName";
import { Autocomplete, Stack, TextField } from "@mui/material";

const AddNewBankModal = ({ next, handleClose }) => {
  const { banks } = useSelector((state) => state?.bank);
  const {
    userBanks,
    isAddBank: loading,
    pending,
  } = useSelector((state) => state?.bank);
  const dispatch = useDispatch();

  const [gettingAccountName, setgettingAccountName] = useState(false);

  const form = useFormik({
    initialValues: {
      account_name: "",
      account_number: "",
      bank_type: {
        value: "International Bank Account",
        label: "International Bank Account",
      },
      beneficiary_country: {
        value: "United States",
        label: "United States",
      },
    },
    onSubmit: (values) => {
      next(values);
      handleClose();
    },
    // validationSchema: nokProfileDetailsSchema,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  useEffect(() => {
    const _account_number = form?.values?.account_number;
    const code = form?.values?.bankName?.code;
    if (_account_number?.length === 10 && code) {
      setgettingAccountName(true);
      dispatch(
        getAccountName({
          payload: {
            bank_code: code,
            account_number: _account_number,
          },
          cb: (accountName) => {
            setgettingAccountName(false);
            form.setFieldValue("account_name", accountName);
          },
          deleteLastDegit: () => {
            form.setFieldValue("account_number", _account_number?.slice(0, 9));
            form.setFieldError("account_number", "Invalid account number.");
          },
          setgettingAccountName,
        })
      );
    } else if (form?.values?.account_name) {
      form.setFieldValue("account_name", "");
    }
  }, [form?.values?.bankName, form?.values?.account_number]);

  useEffect(() => {
    if (banks?.length < 1) {
      dispatch(getBankList());
    }
  }, []);

  return (
    <div className="relative mx-auto w-[553px] h-[388px] rounded-[14px] border-[1px] flex justify-between flex-col bg-[#FFF] border-[#f0f0f5] drop-shadow-custom">
      <div
        onClick={handleClose}
        className="absolute top-3 right-3 cursor-pointer py-2 px-3"
      >
        <img src={close} alt="Close" className="w-6 h-6" />
      </div>
      <div>
        <div className="mt-[30px] flex justify-center text-[16px] font-[500] text-[#131e3d]">
          Add New NGN Bank
        </div>
        <div className="mt-[60px] flex justify-between w-[90%] mx-auto gap-[10px] items-start">
          <div className="flex flex-col">
            <label
              htmlFor="accountNumber"
              className="text-[14px] font-[400] text-[#9c9c9e] mb-[5px]"
            >
              Account Number
            </label>
            <TextInput
              {...formHandler("account_number", form)}
              mainClasses={"mb-0 mt-0 !w-[236px] !h-[56px] "}
            />
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="bank"
              className="text-[14px] font-[400] text-[#9c9c9e] mb-[5px]"
            >
              Choose Bank
            </label>
            <div className="w-[236px] relative">
              <Stack spacing={2} sx={{ width: "100%" }}>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  options={
                    form.values?.currency?.value === "USD"
                      ? []
                      : formatArray(banks, "name")
                  }
                  value={form?.values?.bankName}
                  onChange={(_, newValue) =>
                    form?.setFieldValue("bankName", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      id="bank_name"
                      sx={{
                        // height: "52px",
                        // Root class for the input field
                        "& .MuiOutlinedInput-root": {
                          color: "#131E3D",
                          fontFamily: "Aeonik",
                          fontWeight: "400",
                          height: "52px",
                          // Class for the border around the input field
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#F2F2F7",
                            borderRadius: "8px",
                            borderWidth: "1.3px",
                          },
                        },
                        "&.Mui-focused": {
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#F2F2F7",
                            borderRadius: "8px",
                            borderWidth: "1.3px",
                          },
                        },
                        // Class for the label of the input field
                        "& .MuiInputLabel-outlined": {
                          color: "#131E3D",
                          fontFamily: "Aeonik",
                          fontWeight: "400",
                        },
                      }}
                    />
                    // <input

                    //   // value={value}
                    //   // onChange={handleNumbers}
                    //   // id={id}
                    //   // name={id}
                    //   type={"text"}
                    //   placeholder={"placeholder"}
                    //   className={``}

                    //   // onClick={(e) => e.stopPropagation()}
                    // />
                  )}
                />
              </Stack>

              {pending ? (
                <div className="w-full absolute top-0 left-0 flex h-full ">
                  <Loader />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className=" w-[90%]  mx-auto flex  mt-5 items-center">
          <div className="bg-[#F2F2F7] min-w-[200px] px-[14px] mt-2 h-[33px] rounded-[40px] flex justify-center items-center">
            {gettingAccountName ? (
              <Loader />
            ) : (
              <h4 className="font-[400] capitalize text-[#131E3D] text-[14px] leading-[16.8px]">
                {form?.values?.account_name || "*Resolved Account Name*"}
              </h4>
            )}
          </div>
        </div>
      </div>

      {/* <div className="w-[90%] self-end justify-self-end mx-auto flex justify-end my-5"> */}
      <div className="w-[90%]  justify-center mx-auto flex my-5">
        <button
          disabled={!form?.values?.account_name}
          onClick={() => next(form?.values)}
          className={`w-[191px] h-[45px] bg-[#6935D3] text-white font-[500] rounded-[8px] ${
            !form?.values?.account_name ? "opacity-65 cursor-not-allowed" : ""
          }`}
        >
          Confirm with PIN
        </button>
      </div>
    </div>
  );
};

export default AddNewBankModal;

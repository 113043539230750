import React, { useEffect, useState } from "react";
import Notification from "../../Components/Notification/Notification";
import Navbar from "../../Components/Navbar";
import ethical from "../../Assets/Images/ethical (2).png";
import competitive from "../../Assets/Images/competitive.png";
import compliance from "../../Assets/Images/compliance.png";
import { useNavigate } from "react-router-dom";

const SavingsLandingPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();

  const handleToggleNotification = () => {
    setShowNotification((prev) => !prev);
  };

  return (
    <div className="md:flex hidden  flex-col bg-[#F9FAFA] gap-6 pt-3 pb-3 items-center min-h-screen">
      <Navbar type={3} handleNotification={handleToggleNotification} />

      <div className="w-[95%] h-full rounded-[12px] gap-5 p-10 border-[#F2F2F2] border-[1.1px] drop-shadow-custom bg-[#FFF] max-w-[1664px] flex">
        <div className=" p-[30px] flex justify-center flex-wrap items-center gap-[50px]  ">
          <div className=" flex flex-col gap-[10px] justify-center  w-[550px] h-[500px] ">
            <div className=" mb-[15px] text-[60px] font-[500] text-[#131e3d] ">
              Grow your savings, the ethical way
            </div>
            <div className=" text-[16px] font-[400] text-[#9c9c9e] ">
              Designed to provide users with a secure and profitable way to grow
              their savings.
            </div>
            <div
              className=" cursor-not-allowed mt-[10px] bg-[#6935D3] opacity-90 w-[134px] h-[45px] rounded-[8px] justify-center items-center flex text-white font-[400] "
              // onClick={() => navigate("/save")}
            >
              Coming Soon
            </div>
          </div>
          <div className=" w-[500px] h-[500px] drop-shadow-custom border-[1px] border-[#F0F0F5] rounded-[14px] ">
            <div className=" p-[30px] flex flex-col gap-[30px] justify-center mx-auto  ">
              <div className=" flex justify-start pb-[10px] border-b-[1px] items-start gap-[10px] ">
                <img src={ethical} alt="img" className=" w-[44px] h-[44px] " />
                <div className=" flex  flex-col gap-[10px] ">
                  <div className=" text-[20px] font-[500] text-[#131e3d] ">
                    Ethical Compliance{" "}
                  </div>
                  <div className=" text-[14px] font-[400] text-[#9c9c9e]  ">
                    Strictly compliant with ethical and Shari'ah principles.
                  </div>
                </div>
              </div>

              <div className=" flex justify-start pb-[10px] border-b-[1px] items-start gap-[10px] ">
                <img
                  src={competitive}
                  alt="img"
                  className=" w-[44px] h-[44px] "
                />
                <div className=" flex  flex-col gap-[10px] ">
                  <div className=" text-[20px] font-[500] text-[#131e3d] ">
                    Competitive Returns
                  </div>
                  <div className=" text-[14px] font-[400] text-[#9c9c9e]  ">
                    Enjoy competitive returns while staying true to your values.
                  </div>
                </div>
              </div>

              <div className=" flex justify-start pb-[10px] border-b-[1px] items-start gap-[10px] ">
                <img
                  src={compliance}
                  alt="img"
                  className=" w-[44px] h-[44px] "
                />
                <div className=" flex  flex-col gap-[10px] ">
                  <div className=" text-[20px] font-[500] text-[#131e3d] ">
                    Safe and Secure
                  </div>
                  <div className=" text-[14px] font-[400] text-[#9c9c9e]  ">
                    Funds are securely protected by an NDIC-licensed partner.
                  </div>
                </div>
              </div>

              <div className=" flex justify-start pb-[10px] border-b-[1px] items-start gap-[10px] ">
                <img src={ethical} alt="img" className=" w-[44px] h-[44px] " />
                <div className=" flex  flex-col gap-[10px] ">
                  <div className=" text-[20px] font-[500] text-[#131e3d] ">
                    Flexible Withdrawal Options
                  </div>
                  <div className=" text-[14px] font-[400] text-[#9c9c9e]  ">
                    Access your funds at your preferred exit or maturity window.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavingsLandingPage;

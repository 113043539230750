import React, { useEffect, useMemo, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import pay from "../../Assets/Images/pay.png";
import leaf from "../../Assets/Images/teal.png";
import { teal } from "@mui/material/colors";
import recent from "../../Assets/Images/recent.png";
import { useDispatch, useSelector } from "react-redux";
import { getPayouts } from "../../store/actions/payouts/getPayouts";
import { groupbydate } from "../../helper/groupbydate";
import Loader from "../Utils/Loader";
import { formatAmount } from "../../utils/formatAmount";
const Payouts = ({ currency }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleOpen = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const dispatch = useDispatch();

  const _currency = useMemo(
    () => (currency === "Naira" ? "NGR" : "USD"),
    [currency]
  );

  const [viewAll, setViewAll] = useState(false);

  const { payOuts, payOutsPending } = useSelector(
    (state) => state.transactions
  );

  useEffect(() => {
    dispatch(getPayouts(_currency));
    // const clean = setInterval(() => {
    //   dispatch(getPayouts(currency));
    // }, 60000);
    // return () => {
    //   clearInterval(clean);
    // };
  }, [_currency]);

  const sortedPayouts = useMemo(
    () =>
      payOuts
        ? groupbydate({
            data: payOuts,
            dateKey: "payment_date",
          })
        : [],
    [payOuts]
  );

  const grouped = useMemo(() => {
    return sortedPayouts
      ? [...sortedPayouts]?.slice(0, viewAll ? sortedPayouts?.length : 15)
      : [];
  }, [sortedPayouts, viewAll]);

  const data = [] || [
    {
      question: "Febraury 2024",
      desc: "1 Investment",
      answer: "Grubs 4th Cycle  •   ₦ 250,250.80  •  Due Feb 03, 2024",
      amount: "₦ 250,250.80",
    },
    {
      question: "March 2024",
      desc: "1 Investment",
      answer: "Grubs 4th Cycle  •   ₦ 250,250.80  •  Due Feb 03, 2024",
      amount: "₦ 250,250.80",
    },
    {
      question: "April 2024",
      desc: "1 Investment",
      answer: "Grubs 4th Cycle  •   ₦ 250,250.80  •  Due Feb 03, 2024",
      amount: "₦ 250,250.80",
    },
    {
      question: "May 2024",
      desc: "1 Investment",
      answer: "Grubs 4th Cycle  •   ₦ 250,250.80  •  Due Feb 03, 2024",
      amount: "₦ 250,250.80",
    },
    {
      question: "June 2024",
      desc: "1 Investment",
      answer: "Grubs 4th Cycle  •   ₦ 250,250.80  •  Due Feb 03, 2024",
      amount: "₦ 250,250.80",
    },
    {
      question: "July 2024",
      desc: "1 Investment",
      answer: "Grubs 4th Cycle  •   ₦ 250,250.80  •  Due Feb 03, 2024",
      amount: "₦ 250,250.80",
    },
    // { question: 'August 2024', desc : '1 Investment' , answer: 'Grubs 4th Cycle  •   ₦ 250,250.80  •  Due Feb 03, 2024' , amount : '₦ 250,250.80', },
  ];

  return (
    <div className="w-full">
      <div className="w-full md:w-[520px] min-h-[500px] max-h-[80%] overflow-y-auto max-w-2xl  mx-auto ">
        {payOutsPending ? (
          <div className="h-[500px] flex justify-center items-center w-full">
            <Loader />
          </div>
        ) : !payOuts || payOuts?.length < 1 ? (
          <div className="w-full flex-col h-[500px] flex justify-center items-center">
            <img src={recent} alt="" className=" mb-[5px] w-[76px] h-[76px] " />
            <div className=" mb-[5px] p-2 text-[16px] font-[400] text-[#9C9C9E] ">
              No payouts
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            {grouped.map((item, index) => (
              <div key={index} className=" border-gray-300">
                <button
                  onClick={() => toggleOpen(index)}
                  className="w-[480px] border-t-[1px] mx-auto p-4 flex justify-between items-center text-left focus:outline-none"
                >
                  <div className="flex justify-center items-center space-x-2">
                    <img
                      src={pay}
                      alt="Payment method"
                      className="w-[38px] h-[38px] object-contain"
                    />
                    <div className=" flex flex-col items-start gap-[4px] ">
                      <span className=" text-[16px] font-[500] text-[#131e3d] ">
                        {`${item?.month} ${item?.year}`}
                      </span>
                      <span className=" text-[12px] font-[400] text-[#131e3d]">
                        {item.groups?.length} investment
                        {item.groups?.length === 1 ? "" : "s"}
                      </span>
                    </div>
                  </div>
                  <div className=" flex gap-[5px] items-center ">
                    <div className="  text-[16px] font-[500] text-[#131e3d] ">
                      {_currency === "NGR" ? "₦ " : "$ "}
                      {formatAmount(
                        item.groups.slice(0, 4)?.reduce((acc, curr) => {
                          return acc + Number(curr?.monthly_payout);
                        }, 0),
                        2
                      )}
                    </div>
                    {openIndex === index ? (
                      <div className=" w-[24px] h-[24px] flex justify-center items-center rounded-[40px] bg-[#f2f2f7] ">
                        {" "}
                        <FaChevronUp
                          color="#9c9c9e"
                          className=" w-[16px] h-[16px]  "
                        />{" "}
                      </div>
                    ) : (
                      <div className=" w-[24px] h-[24px] flex justify-center items-center rounded-[40px] bg-[#f2f2f7] ">
                        <FaChevronDown
                          color="#9c9c9e"
                          className=" w-[16px] h-[16px] "
                        />
                      </div>
                    )}
                  </div>
                </button>
                {openIndex === index &&
                  item.groups
                    .slice(0, 4)
                    ?.map(
                      (
                        {
                          amount,
                          currency,
                          payment_date,
                          investment_product_name,
                          monthly_payout,
                        },
                        index
                      ) => (
                        <div className="px-4 py-[6px]  min-h-[32px] mb-[15px] mt-[10px] ml-[40px] flex  items-center  rounded-[30px] w-[420px] bg-[#f2f2f7] ">
                          <h4 className="font-[400] text-[#131e3d] flex items-center gap-1">
                            <img
                              src={leaf}
                              alt=""
                              className=" w-[14px] h-[14px] "
                            />
                            {investment_product_name} •{" "}
                            {monthly_payout && _currency === "NGR" ? "₦" : "$"}{" "}
                            {monthly_payout && formatAmount(monthly_payout, 2)}{" "}
                            • Due {payment_date}
                          </h4>
                        </div>
                      )
                    )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Payouts;

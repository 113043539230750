import React, { useCallback, useEffect, useMemo, useState } from "react";
import mop from "../../Assets/Images/mop.png";
import payout from "../../Assets/Images/payout.png";
import notification from "../../Assets/Images/no-notification.png";
import FilterButton from "../Buttons/FilterButton";
import { Client } from "../../client";
import { deleteNotifications } from "../../store/actions/notification/deleteNotifications";
import { useDispatch, useSelector } from "react-redux";
import { getAllNotifications } from "../../store/actions/notification/getAllNotifications";
import { timeformatter } from "../../utils/dateformatter";
import { toast } from "react-toastify";
import Loader from "../Utils/Loader";

const Notification = ({ close }) => {
  const [activeTab, setActiveTab] = useState("notifications");

  const dispatch = useDispatch();
  const [fetchedDataAll, setFetchedDataAll] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    allNotifications,
    allNotificationsData,
    unreadNotifications,
    pending,
  } = useSelector((state) => state?.notifications);

  const allNotificationsPageInfo = useMemo(
    () => allNotifications,
    [allNotifications]
  );
  const unReadNotificationsPageInfo = useMemo(
    () => unreadNotifications,
    [unreadNotifications]
  );
  // const unreadNotifications = useSelector((state) => state?.unreadNotifications);

  useEffect(() => {
    dispatch(getAllNotifications());
  }, []);

  const handleClearAll = (itemsToBeCleared) => {
    setLoading(true);

    dispatch(
      deleteNotifications({
        selected: itemsToBeCleared,

        success: () => {
          setFetchedDataAll((prev) => {
            return prev?.filter(
              (notification) =>
                !itemsToBeCleared.some((i) => i === notification.id)
            );
          });
          setLoading(false);
        },
        cb: () => {
          setLoading(false);
        },
      })
    );
  };

  const loadNewData = async ({ url, type }) => {
    try {
      setLoading(true);

      const { data } = await Client({
        method: "GET",
        path: url,
        type: "full-path",
      });

      setFetchedDataAll((prev) => [...prev, ...data?.data]);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Data");
    }
  };

  useEffect(() => {
    if (allNotificationsPageInfo?.total) {
      setFetchedDataAll(allNotificationsPageInfo?.data);
    }
  }, [allNotificationsPageInfo]);

  return (
    <>
      <div
        onClick={() => {
          close();
        }}
        className="fixed px-4  top-[0px] h-full right-0 bg-black bg-opacity-30 flex items-start justify-end z-50 w-full"
      ></div>
      <div
        className={`bg-white w-[445px] flex flex-col py-5 px-6 h-[557px] top-[75px] rounded-[12px] right-[240px] border-[1.1px] border-[#f2f2f2] fixed z-50 `}
      >
        <div className="w-min border-[#F0F0F5] drop-shadow-custom mb-4 p-2 h-[49px] border-[1px] rounded-[41px] mx-1  flex justify-center items-center">
          <FilterButton
            items={[
              { label: "Notifications", value: "notifications" },
              { label: "Announcements", value: "announcements" },
            ]}
            active={activeTab}
            setActive={setActiveTab}
          />
        </div>

        {activeTab === "announcements" ? (
          <div className="flex flex-1 overflow-y-auto flex-col gap-[10px] ">
            <div className="flex-1 flex flex-col items-center justify-center">
              <img
                src={notification}
                alt=""
                className=" mb-[10px] w-[70px] h-[70px] "
              />

              <div className=" text-[16px] font-[500] mb-[15px] text-[#131e3d] ">
                No Announcements
              </div>
              <div className=" text-[#9c9c9e] font-[400] text-[14px]    ">
                New announcements will appear here.
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-1 overflow-y-auto flex-col gap-[10px] ">
              {loading || pending ? (
                <div className="flex-1 justify-center flex  items-center ">
                  <Loader />
                </div>
              ) : fetchedDataAll?.length > 0 ? (
                <>
                  {fetchedDataAll?.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col justify-between items-center"
                    >
                      <div className="flex items-center gap-[7px]">
                        <img
                          src={payout}
                          alt="Payout Icon"
                          className="w-[55px] h-[55px] object-contain"
                        />
                        <div className="flex flex-col gap-[5px]">
                          <h4 className="text-[14px] font-[500] text-[#131e3d]">
                            {item?.title ? `${item?.title} : ` : ""}
                            <span className="text-[14px] font-[400] text-[#9c9c9e]">
                              {item?.message}
                            </span>{" "}
                            <span className="text-[14px] font-[400] text-[#131e3d]">
                              {timeformatter(
                                item?.created_at,
                                "formal",
                                "withInThreeDays"
                              )}
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))}
                  {fetchedDataAll?.length < allNotificationsPageInfo?.total ? (
                    <div className=" flex items-center mt-3 cursor-pointer gap-[8px] ">
                      <h4
                        onClick={() => [
                          loadNewData({
                            url: allNotificationsPageInfo?.links[
                              Math.floor(fetchedDataAll?.length / 10 + 1)
                            ]?.url,
                            type: "all",
                          }),
                        ]}
                        className=" text-[14px] font-[400] text-[#6935D3] "
                      >
                        Load More
                      </h4>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : pending ? (
                <div className="flex-1 justify-center flex  items-center ">
                  <Loader />
                </div>
              ) : (
                <div className="flex-1 flex flex-col items-center justify-center">
                  <img
                    src={notification}
                    alt=""
                    className=" mb-[10px] w-[70px] h-[70px] "
                  />

                  <div className=" text-[16px] font-[500] mb-[15px] text-[#131e3d] ">
                    No Notifications
                  </div>
                  <div className=" text-[#9c9c9e] font-[400] text-[14px]    ">
                    Make your first deposit to see some activity
                  </div>
                </div>
              )}
            </div>
            {fetchedDataAll?.length > 0 ? (
              <div
                onClick={() => {
                  if (pending) {
                    return;
                  } else {
                    let all = [];
                    fetchedDataAll?.forEach((i) => all.push(i.id));
                    handleClearAll(all);
                  }
                }}
                className=" flex items-center mt-3 cursor-pointer gap-[8px] "
              >
                <img src={mop} alt="Close Icon" className="w-[14px] h-[14px]" />
                <h4 className=" text-[14px] font-[400] text-[#6935D3] ">
                  Clear All
                </h4>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Notification;

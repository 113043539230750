import React, { useEffect, useMemo, useState } from "react";
import closeIcon from "../Assets/Images/close.png";
import { useDispatch } from "react-redux";
import { getInvestmentTimeline } from "../store/actions/investment/getInvestmentTimeline";
import Loader from "./Utils/Loader";
import { timeformatter } from "../utils/dateformatter";

const InvesmentCycleTimelineModal = ({ investment, close, investmentId }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(
      getInvestmentTimeline({
        payload: { cycle_id: investment?.id },
        investmentId: investmentId,
        cb: (data) => {
          setData(data);
          setLoading(false);
        },
        failed: () => {
          setLoading(false);
        },
      })
    );
  }, []);

  const step1 = useMemo(
    () =>
      data.find((item) => item?.status == 1)?.date || investment?.created_at,
    [data]
  );
  const step2 = useMemo(
    () => data.find((item) => item?.status == 2)?.date,
    [data]
  );
  const step3 = useMemo(
    () => data.find((item) => item?.status == 3)?.date,
    [data]
  );
  const step4 = useMemo(
    () => data.find((item) => item?.status == 4)?.date,
    [data]
  );
  const step5 = useMemo(
    () => data.find((item) => item?.status == 5)?.date,
    [data]
  );

  return (
    <div className="relative h-[591px] w-full max-w-[553px] flex flex-col rounded-[12px] border-[1px] border-[#f2f2f7] bg-white shadow-lg">
      <div className="absolute top-3 right-3 cursor-pointer p-3">
        <img
          onClick={() => {
            close();
          }}
          src={closeIcon}
          alt="Close"
          className="cursor-pointer w-6 h-6"
        />
      </div>

      <h2 className="text-[24px] mt-[10px] font-[500] text-[#131e3d] p-5">
        {investment?.name} Timeline
      </h2>

      <div className="flex-1 flex flex-col justify-between pb-[30px]">
        {loading ? (
          <div className="flex-1 flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div className="flex py-1 px-[30px] items-start gap-[12px] ">
            <div className="flex flex-col gap-[8px] items-center mt-[10px]">
              <div className="flex flex-col items-center">
                {step1 ? (
                  <div className="w-[14px] h-[14px] bg-white border-[1px] rounded-full border-[#16a267] flex justify-center items-center">
                    <div className="w-[7px] h-[7px] bg-[#16a267] rounded-full"></div>
                  </div>
                ) : (
                  <div className="w-[14px] h-[14px] bg-white border-[1px] rounded-full border-[#9c9c9e] flex justify-center items-center">
                    <div className="w-[7px] h-[7px] bg-transparent rounded-full"></div>
                  </div>
                )}
              </div>
              <div className="w-[1px] border-l-[1px] border-dashed border-[#131e3d] h-[50px]"></div>

              <div className="flex flex-col items-center">
                {step2 ? (
                  <div className="w-[14px] h-[14px] bg-white border-[1px] rounded-full border-[#16a267] flex justify-center items-center">
                    <div className="w-[7px] h-[7px] bg-[#16a267] rounded-full"></div>
                  </div>
                ) : (
                  <div className="w-[14px] h-[14px] bg-white border-[1px] rounded-full border-[#9c9c9e] flex justify-center items-center">
                    <div className="w-[7px] h-[7px] bg-transparent rounded-full"></div>
                  </div>
                )}
              </div>
              <div className="w-[1px] border-l-[1px] border-dashed border-[#131e3d] h-[50px]"></div>

              <div className="flex flex-col items-center">
                {step3 ? (
                  <div className="w-[14px] h-[14px] bg-white border-[1px] rounded-full border-[#16a267] flex justify-center items-center">
                    <div className="w-[7px] h-[7px] bg-[#16a267] rounded-full"></div>
                  </div>
                ) : (
                  <div className="w-[14px] h-[14px] bg-white border-[1px] rounded-full border-[#9c9c9e] flex justify-center items-center">
                    <div className="w-[7px] h-[7px] bg-transparent rounded-full"></div>
                  </div>
                )}
              </div>
              <div className="w-[1px] border-l-[1px] border-dashed border-[#131e3d] h-[50px]"></div>

              <div className="flex flex-col items-center">
                {step4 ? (
                  <div className="w-[14px] h-[14px] bg-white border-[1px] rounded-full border-[#16a267] flex justify-center items-center">
                    <div className="w-[7px] h-[7px] bg-[#16a267] rounded-full"></div>
                  </div>
                ) : (
                  <div className="w-[14px] h-[14px] bg-white border-[1px] rounded-full border-[#9c9c9e] flex justify-center items-center">
                    <div className="w-[7px] h-[7px] bg-transparent rounded-full"></div>
                  </div>
                )}
              </div>
              <div className="w-[1px] border-l-[1px] border-dashed border-[#131e3d] h-[50px]"></div>

              {step5 ? (
                <div className="w-[14px] h-[14px] bg-white border-[1px] rounded-full border-[#16a267] flex justify-center items-center">
                  <div className="w-[7px] h-[7px] bg-[#16a267] rounded-full"></div>
                </div>
              ) : (
                <div className="w-[14px] h-[14px] bg-white border-[1px] rounded-full border-[#9c9c9e] flex justify-center items-center">
                  <div className="w-[7px] h-[7px] bg-transparent rounded-full"></div>
                </div>
              )}
            </div>
            <div className="flex flex-col mt-[4px] ">
              <div
                className={`text-[16px] h-[80px] ${
                  step1 ? "text-[#131e3d]" : "text-[#9C9C9E]"
                } ] font-[500]`}
              >
                Campaign launched
                <div className="text-[14px] font-[400] text-[#9C9C9E]">
                  {step1 && step1 != true
                    ? timeformatter(step1, "formal", "withInThreeDays")
                    : "___"}
                </div>
              </div>

              <div
                className={`text-[16px] h-[80px] ${
                  step2 ? "text-[#131e3d]" : "text-[#9C9C9E]"
                } ] font-[500]`}
              >
                Funding Completed
                <div className="text-[14px] font-[400] text-[#9C9C9E]">
                  {step2
                    ? timeformatter(step2, "formal", "withInThreeDays")
                    : "___"}
                </div>
              </div>
              <div
                className={`text-[16px] h-[80px] ${
                  step3 ? "text-[#131e3d]" : "text-[#9C9C9E]"
                } ] font-[500]`}
              >
                Goods Purchased
                <div className="text-[14px] font-[400] text-[#9C9C9E]">
                  {step3
                    ? timeformatter(step3, "formal", "withInThreeDays")
                    : "___"}
                </div>
              </div>
              <div
                className={`text-[16px] h-[80px] ${
                  step4 ? "text-[#131e3d]" : "text-[#9C9C9E]"
                } ] font-[500]`}
              >
                Goods Sold to business
                <div className="text-[14px] font-[400] text-[#9C9C9E]">
                  {step4
                    ? timeformatter(step4, "formal", "withInThreeDays")
                    : "___"}
                </div>
              </div>
              <div
                className={`text-[16px] h-[80px] ${
                  step5 ? "text-[#131e3d]" : "text-[#9C9C9E]"
                } ] font-[500]`}
              >
                Profit Payout Started
                <div className="text-[14px] font-[400] text-[#9C9C9E]">
                  {step5
                    ? timeformatter(step5, "formal", "withInThreeDays")
                    : "___"}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className=" pr-[30px] ">
          <button
            onClick={() => {
              close();
            }}
            className=" w-[93px] float-end h-[45px] rounded-[8px] text-white justify-center items-center flex font-[500] bg-[#6935D3] "
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvesmentCycleTimelineModal;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { API_GET_WITHDRAWALS } from "../../../config/api";
import { Client } from "../../../client";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";

export const getAllWithdrawals = createAsyncThunk(
  "wallet/getAllWithdrals",
  async (cb, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_WITHDRAWALS,
      });

      cb();
      return response?.data?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      // toast.error(msg);
      cb();
      throw error;
    }
  }
);

import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_SUBMIT_INVESTMENT } from "../../../config/api";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import mixpanel from "mixpanel-browser";

export const submitInvestment = createAsyncThunk(
  "investment/Submit",
  async ({ data, name, failed, cb }, { dispatch, rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_SUBMIT_INVESTMENT,
        data: data,
      });
      toast.success("Investment added successfully");

      cb(response?.data);

      return response?.data?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      failed();
      mixpanel.track("Error", {
        type: "Investment",
        subtype: "submit-investment",
        message: msg,
      });
      return rejectWithValue(
        error.response?.data || "An unexpected error occurred"
      );
    }
  }
);

import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const TextInput = ({
  value,
  onChange,
  title,
  placeholder,
  disabled,
  mainClasses,
  inputClasses,
  defaultValue,
  id,
  type,
  autoComplete,
  error,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleNumbers = (e) => {
    let text = e.target.value;
    text = text.replace(/\D/g, "");

    if (id === "cardNumber") {
      text = text.length
        ? text.split("").reduce((acc, curr, ind) => {
            if (ind !== 0 && !(ind % 4)) acc += " ";
            return acc + curr;
          })
        : text;

      e.target.value = text;
      onChange(e);
    } else if (id === "expiry") {
      text = text.length
        ? text.split("").reduce((acc, curr, ind) => {
            if (ind !== 0 && !(ind % 2)) acc += " / ";
            return acc + curr;
          })
        : text;

      e.target.value = text;
      onChange(e);
    } else {
      onChange(e);
    }
  };

  return (
    <div className={` w-[367px] ${mainClasses}`}>
      {/* <h4 className="text-[#707070] font-[400] text-[14px] mb-1 leading-[21.94px]">
        {title}
      </h4> */}
      <div className="flex relative z-[1] items-center">
        <input
          autoComplete={type === "password" ? "off" : autoComplete}
          value={value}
          onChange={handleNumbers}
          id={id}
          name={id}
          type={
            type === "password" && showPassword ? "text" : type ? type : "text"
          }
          placeholder={placeholder}
          disabled={disabled ? disabled : false}
          className={`flex  w-full disabled:cursor-not-allowed font-[400] text-[14px] md:text-[16px] text-[#131E3D] outline-none disabled:bg-black-10 placeholder:text-black-50 focus:border-[2px] focus:border-[#6935D3]/70 invalid:[&:not(:placeholder-shown):not(:focus)]:border-[2px] invalid:[&:not(:placeholder-shown):not(:focus)]:border-error-light transition-all ease-in-out duration-100 rounded-[8px] px-2 md:px-4 border-[1px] border-gray-200 h-[52px]  py-3 leading-tight focus:outline-none focus:bg-white drop-shadow-custom ${inputClasses}`}
          defaultValue={defaultValue}

          // onClick={(e) => e.stopPropagation()}
        />
        {type === "password" ? (
          <>
            {showPassword ? (
              <AiOutlineEye
                className="cursor-pointer absolute right-[15px]"
                size={22}
                color="#192851"
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <AiOutlineEyeInvisible
                className="cursor-pointer absolute right-[15px]"
                color="192851"
                size={"20px"}
                onClick={() => setShowPassword(true)}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      {error && (
        <h6 className=" text-[0.75rem] mt-[4px] px-[2px] mb-2 text-[#ef5364] text-left w-full">
          {`${error}`}
        </h6>
      )}
    </div>
  );
};

export default TextInput;

import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HelmetProvider } from "react-helmet-async";
import Login from "./Pages/Auth/Login";
import Signup from "./Pages/Auth/Signup";
import OTP from "./Pages/Auth/OTP";
import Welcome from "./Pages/Auth/Welcome";
import Landing from "./Pages/Auth/Landing";
import DashboardHome from "./Pages/Dashboard/DashboardHome";
import Save from "./Pages/Dashboard/Save";
import Invest from "./Pages/Dashboard/Invest";
import Investment from "./Pages/Dashboard/Investment";
import InvestmentDetails from "./Pages/Dashboard/InvestmentDetails";
import mixpanel from "mixpanel-browser";
import { useSelector } from "react-redux";
import MyInvestmentSummary from "./Pages/Dashboard/MyInvestmentSummary";
import RealEstateInvestment from "./Pages/Dashboard/RealEstate";
import Profile from "./Pages/Dashboard/Profile";
import { Cookies } from "react-cookie";
import RealEstateDetails from "./Pages/Dashboard/RealEstateDetails";
import PropertyMarketPlace from "./Pages/Dashboard/PropertyMarketPlace";
import Wallet from "./Pages/Dashboard/Wallet";
import ResetPassword from "./Pages/Auth/ResetPassword";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import SavingsLandingPage from "./Pages/Dashboard/SavingsLandingPage";
import VentureInvesting from "./Pages/Dashboard/VentureInvesting";
import Portfolio from "./Pages/Dashboard/Portfolio";
import Nationality from "./Pages/Auth/Nationality";
import GetStatement from "./Pages/Dashboard/GetStatement";
import VerifyID from "./Pages/Dashboard/VerifyID";

const generalRoutes = [
  {
    path: "/reset-password/:token",
    element: <ResetPassword />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
];

const protectedRoutes = createBrowserRouter([
  ...generalRoutes,
  {
    path: "/landing",
    element: <Landing />,
  },
  {
    path: "/dashboard",
    element: <DashboardHome />,
  },
  {
    path: "/investment-statement",
    element: <GetStatement />,
  },
  {
    path: "/welcome",
    element: <Welcome />,
  },
  {
    path: "/save",
    element: <Save />,
  },
  {
    path: "/saving",
    element: <SavingsLandingPage />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/verify-id",
    element: <VerifyID />,
  },
  {
    path: "/investment",
    element: <Invest />,
  },
  {
    path: "/Wallet",
    element: <Wallet />,
  },
  {
    path: "/portfolio",
    element: <Portfolio />,
  },
  {
    path: "/investment/businessFinancing",
    element: <Investment />,
  },

  {
    path: "/investment/ventureInvesting",
    element: <VentureInvesting />,
  },
  {
    path: "/investment/realEstate",
    element: <RealEstateInvestment />,
  },
  {
    path: "/investment/realEstate/marketplace",
    element: <PropertyMarketPlace />,
  },
  {
    path: "/investment/realEstate/:id",
    element: <RealEstateDetails />,
  },
  {
    path: "/investment/:type/:id",
    element: <InvestmentDetails />,
  },
  {
    path: "/investment-summary/:id",
    element: <MyInvestmentSummary />,
  },
  {
    path: "/",
    element: <DashboardHome />,
  },

  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);

const publicRoutes = createBrowserRouter([
  ...generalRoutes,
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/landing",
    element: <Landing />,
  },

  {
    path: "/signin",
    element: <Login />,
  },
  {
    path: "/otp",
    element: <OTP />,
  },
  {
    path: "/welcome",
    element: <Welcome />,
  },
  {
    path: "/forgot-password",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/nationality",
    element: <Nationality />,
  },

  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);

// const PROJECT_TOKEN = "20bc20e92982f5e45dccfa3e164e4739";
const PROJECT_TOKEN = "20bc20e92982f5e45dccfa3e164e4700";
mixpanel.init(PROJECT_TOKEN, {
  debug: true,
  ignore_dnt: true,
  track_pageview: "full-url",
});

function App() {
  const cookies = new Cookies();

  const { user } = useSelector((state) => state.onboarding);

  const isAccessTokenAvailable = cookies.get("accessToken");
  useEffect(() => {
    mixpanel.track("V2-URL-Launched");
  }, []);

  return (
    <div className="">
      <HelmetProvider>
        <RouterProvider
          router={
            isAccessTokenAvailable && user?.id ? protectedRoutes : publicRoutes
          }
        />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          closeOnClick
          ltr={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          hideProgressBar={true}
        />
        {/* <Whatsapp /> */}
      </HelmetProvider>
    </div>
  );
}

export default App;

import React, { useState } from "react";
import dots from "../Assets/Images/dots.png"; // Adjust the path as needed
import InvestmentTransactionTable from "./InvestmentTransactionTable";
import icon1 from "../Assets/Images/payout.png";
import icon2 from "../Assets/Images/in.png";

const InvestmentDetailsTable = ({ state }) => {
  const [activeTab, setActiveTab] = useState("button1");

  return (
    <div className="w-full  md:max-w-[697px] py-5  rounded-[14px]">
      <div className="flex justify-between items-center">
        <div className=" p-2 h-[49px] border-[1px] rounded-[41px] flex justify-center items-center">
          <div className="flex justify-center items-center gap-2">
            <button
              className={`py-1 font-[500]  h-[37px] px-4 rounded-[40px] ${
                activeTab === "button1" ? "bg-[#F1EDFB] text-[#6935D3]" : ""
              }`}
              onClick={() => setActiveTab("button1")}
            >
              Transactions
            </button>
            <button
              className={` font-[500] h-[37px] py-1 px-4 rounded-[40px] ${
                activeTab === "button2" ? "bg-[#F1EDFB] text-[#6935D3]" : ""
              }`}
              onClick={() => setActiveTab("button2")}
            >
              Payout Schedule
            </button>
          </div>
        </div>
        <img
          src={dots}
          alt="dots"
          className="hidden md:blockml-4 w-[20px] h-[20px] "
        />
      </div>

      <div className="w-full overflow-auto mt-4">
        {activeTab === "button1" ? (
          <div>
            <InvestmentTransactionTable state={state} />
          </div>
        ) : (
          <div>
            <InvestmentTransactionTable
              type={"payout"}
              noData={
                !state?.investment?.paymentSchedules?.created_at &&
                !state?.cycle_details?.paymentSchedules?.payout_date
              }
              state={state}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default InvestmentDetailsTable;

import React from "react";
// import {close} from 'react-icons'
import close from "../Assets/Images/close.png";
import video from "../Assets/Images/video.png";
import pole from "../Assets/Images/pole.png";
const RealEstateSlide3 = ({ next, skip, handleClose }) => {
  return (
    <div className="mb-6 p-6 border-[1.2px] border-[#F0F0F5] rounded-xl mx-auto w-[553px] h-[610px] max-h-[95%] bg-white flex flex-col shadow-2xl justify-start">
      <div className="flex justify-end">
        <img
          onClick={handleClose}
          src={close}
          alt="Close"
          className="cursor-pointer w-[24px] h-[24px]"
        />
      </div>
      <div className="mb-[5px] w-[48px] h-[48px] rounded-full bg-[#F1EDFB] font-[400] text-[20px] flex justify-center items-center text-[#6935D3]">
        2
      </div>

      <div className="flex gap-[3px] p-3 mt-[8px] mb-[10px]">
        <div className="w-[6px] h-[6px] bg-[#6935D3] rounded-full"></div>
        <div className="w-[6px] h-[6px] bg-[#6935D3] rounded-full"></div>
        <div className="w-[6px] h-[6px] bg-[#F2F2F7] rounded-full"></div>
      </div>

      <div className=" text-[24px] text-[#131E3D] font-[500] ">
        {" "}
        How it works?{" "}
      </div>
      <div className="flex-1  overflow-y-scroll">
        <div className=" mt-[40px]  p-2 ">
          <iframe
            className="w-full"
            height="240px"
            style={{
              borderRadius: "8px",
            }}
            src={
              "https://www.youtube.com/embed/UxAwqrfoTxU?si=PDnw3P6EFxIJE0xG"
            }
            title=""
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          {/* <img src={video} alt="" className=" w-[480px] h-[240px] " /> */}
        </div>
        <div className=" flex gap-[15px] items-center mt-[20px] ">
          <div className="">
            <img src={pole} alt="" className=" h-[290px] " />
          </div>
          <div className=" w-[433px] flex flex-col gap-[20px] ">
            <div className=" text-[16px] font-[400] text-[#131E3D] ">
              Halvest selects a property in a prime location
            </div>
            <div className=" text-[16px] font-[400] text-[#131E3D] ">
              We create a special purpose vehicle (SPV company) to buy the
              property
            </div>
            <div className=" text-[16px] font-[400] text-[#131E3D] ">
              You own a % (share) of the SPV
            </div>
            <div className=" text-[16px] font-[400] text-[#131E3D] ">
              Management of property is assigned to a reliable and trustworthy
              property manager  
            </div>
            <div className=" text-[16px] font-[400] text-[#131E3D] ">
              Property is put up for rental or on Airbnb
            </div>
          </div>
        </div>

        <div className="gap-[7px] mt-[20px] p-2 flex justify-end items-center">
          <button
            onClick={skip}
            className="w-[149px] h-[45px] border border-[#F2F2F7] drop-shadow-custom rounded-[8px] bg-white text-[#192851] font-[500] text-[16px]"
          >
            Skip
          </button>
          <button
            onClick={next}
            className="w-[149px] h-[45px] rounded-[8px] bg-[#6935D3] text-white font-[500] text-[16px]"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default RealEstateSlide3;

import React, { useState } from "react";
import close from "../../Assets/Images/close.png";
import wallet from "../../Assets/Images/wallet2.png";
import sale from "../../Assets/Images/sell.png";
import { LiaAngleLeftSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import copy from "../../Assets/Images/copy.png";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const BankTransferDetails = ({ handleClose, handleBack }) => {
  const user = useSelector((state) => state.onboarding?.user);

  const _wallet = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data
  );

  const handleCopyToClipboard = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);

      toast.success("Account Details copied.");
    } catch (error) {
      // console.error("Failed to copy text to clipboard:", error);
    }
  };

  let wallet = { ..._wallet, accountName: user?.name };

  const accountInfoText = `
  Account Name: ${wallet?.accountName}
  Account Number: ${wallet?.accountNumber}
  Bank Name: ${wallet?.bankName}
`;

  return (
    <div className="flex flex-col items-center border-[1px] border-[#F0F0F5] w-[90%] sm:w-[553px] mt-[30px] mx-auto mb-[30px] md:h-[448px] rounded-[14px] shadow-xl relative bg-white">
      <div className="absolute top-3 p-3 right-3 cursor-pointer">
        <img
          onClick={handleClose}
          src={close}
          alt="Close"
          className="w-6 h-6"
        />
      </div>
      <div className="flex mt-[40px] gap-[130px] items-center justify-start w-full px-8 ">
        <div
          className="flex items-center gap-[3px] cursor-pointer"
          onClick={handleBack}
        >
          <LiaAngleLeftSolid size={18} color="#9c9c9e" className="mr-2" />
          <h3 className="text-[14px] font-[400] text-[#9c9c9e]">Back</h3>
        </div>
        <div className="text-[16px] text-[#131e3d] font-[400]">
          Bank Transfer
        </div>
      </div>

      <div className="flex w-[90%] md:w-[489px] justify-between mt-[70px] gap-[120px] items-center">
        <div className="text-[14px] font-[400] text-[#9c9c9e]">
          Account Name
        </div>
        <div className="text-[14px] font-[400] text-[#131e3d]">
          {wallet?.accountName}
        </div>
      </div>
      <div className="h-[1px] bg-[#f2f2f7] my-[10px] w-[90%] md:w-[489px] mx-auto"></div>

      <div className="flex w-[90%] md:w-[489px] justify-between mt-[20px] gap-[120px] items-center">
        <div className="text-[14px] font-[400] text-[#9c9c9e]">
          Account Number
        </div>
        <div className="text-[14px] font-[400] text-[#131e3d]">
          {wallet?.accountNumber}
        </div>
      </div>
      <div className="h-[1px] bg-[#f2f2f7] my-[10px] w-[90%] md:w-[489px] mx-auto"></div>

      <div className="flex w-[90%] md:w-[489px] justify-between mt-[20px] gap-[120px] items-center">
        <div className="text-[14px] font-[400] text-[#9c9c9e]">Bank Name</div>
        <div className="text-[14px] font-[400] text-[#131e3d]">
          {wallet?.bankName}
        </div>
      </div>
      <div className="h-[1px] bg-[#f2f2f7] my-[10px] w-[90%] md:w-[489px] mx-auto"></div>

      <div className="flex w-[90%] md:w-[489px] mt-[25px] text-[12px] font-[400] text-[#9c9c9e] text-left">
        Fund your Halvest wallet by making a transfer to the banking details
        above.
      </div>
      <h4 className=" w-[90%] md:w-[489px] mt-[4px] text-[13px] font-[400] text-[#6935D3] text-left">
        <span className=" text-[13px] font-[500] text-[#6935D3] mr-1">
          {" "}
          Note:
        </span>{" "}
        A
        <span className=" text-[13px] font-[500] text-[#6935D3]">
          {" "}
          service fee of 0.5%
        </span>
        , capped at a maximum of
        <span className=" text-[13px] font-[500] text-[#6935D3]">
          {" "}
          100 naira
        </span>
        , is charged by our banking partner.
      </h4>

      <div
        onClick={() => {
          if (wallet?.accountName) handleCopyToClipboard(accountInfoText);
        }}
        className="flex w-[90%] md:w-[489px] cursor-pointer   mt-[22px] items-center"
      >
        <img src={copy} alt="Copy" className="w-[14px] h-[14px]" />
        <h4 className="text-[12px] font-[400] text-[#6935D3] ml-2">
          Copy Account Details
        </h4>
      </div>
    </div>
  );
};

export default BankTransferDetails;

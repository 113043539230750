import { createAsyncThunk } from "@reduxjs/toolkit";
import { Client } from "../../../client";
import { API_WITHDRAW } from "../../../config/api";
import { showErrorModal, showSuccessModal } from "../../reducers/messageSlice";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import { getBalance } from "./manageFunds";
import { toast } from "react-toastify";
import { getTransactions } from "../transactions/getTransactions";
// import mixpanel from "mixpanel-browser";

export const withdraw = createAsyncThunk(
  "withdraw/post",
  async ({ data: payload, cb, failed, currency }, { dispatch, getState }) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: `${API_WITHDRAW}${currency}`,
        data: payload,
      });
      dispatch(getBalance());
      dispatch(getTransactions(currency));
      cb();
      dispatch(showSuccessModal(data.message));

      return data;
    } catch (error) {
      failed();
      const msg = proccessErrorMessage(error);

      toast.error(msg);
    }
  }
);

import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import { API_SECURITY_QUESTIONS } from "../../../config/api";
import { showErrorModal } from "../../reducers/messageSlice";

import { fetchUserProfile } from "./getProfile";

export const updateSecurityQuestions = createAsyncThunk(
  "securityQuestions/add",
  async ({ data, cb, failed }, { dispatch }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_SECURITY_QUESTIONS,
        data: data,
      });

      cb();
      toast.success(response?.data?.message);
      dispatch(fetchUserProfile());
      return response;
    } catch (error) {
      failed();
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      dispatch(showErrorModal(msg));
    }
  }
);

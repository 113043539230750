import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_CHANGE_PASSWORD } from "../../../config/api";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";

import { fetchUserProfile } from "../profile/getProfile";
import mixpanel from "mixpanel-browser";
import { toast } from "react-toastify";

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async ({ payload, cb }, { dispatch, getState }) => {
    const user = getState().onboarding?.user;

    try {
      const { data } = await Client({
        method: "POST",
        path: API_CHANGE_PASSWORD,
        data: payload,
      });

      if (data?.status === "error") {
        if (data?.message === "Kindly login to access this endpoint.") {
        }

        // dispatch(showErrorModal(data?.message || "Error changing password"));
      } else {
        // if (user && user?.hasPassword) {
        //   dispatch(showSuccessModal("Password Changed Successfully"));
        // } else {
        //   dispatch(showSuccessModal("Password Created Successfully"));
        // }

        dispatch(fetchUserProfile());
        // setTimeout(() => {
        //   dispatch(closeModal());
        // }, 3000);
        toast.success("Password updated successfully.");
        cb();
        return data;
      }
    } catch (error) {
      cb();
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      // mixpanel.track("Error", {
      //   type: "onboarding",
      //   subtype: "reset-password",
      //   message: msg,
      // });
      // dispatch(showErrorModal(msg || "Error changing password"));
      // setTimeout(() => {
      //   dispatch(closeModal());
      // }, 3000);
      throw error;
    }
  }
);

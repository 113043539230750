import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import TransactionTable from "../../Components/TransactionTable";
import RecentTransaction from "../../Components/RecentTransaction";
import Goal from "../../Components/Goal";
import SavingsDetails from "../../Components/SavingsDetails";
import SavingsOverview from "../../Components/SavingsOverview";
import SavingAmountModal from "../../Components/SavingAmountModal";
import SavingGoalModal from "../../Components/SavingGoalModal";
import SavingDateModal from "../../Components/SavingDateModal";
import SavingSuccess from "../../Components/SavingSuccess";
import DepositButton from "../../Components/DepositButton";
import WithdrawButton from "../../Components/WithdrawButton";
import Notification from "../../Components/Notification/Notification";
import { FiMoreVertical } from "react-icons/fi";

const Save = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [showTransactionTable, setShowTransactionTable] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [modalState, setModalState] = useState("");
  const [showSlide, setShowSlide] = useState("");
  const [currentStep, setCurrentStep] = useState(1);

  const handleToggleNotification = () => {
    setShowNotification((prev) => !prev);
  };

  const handleOpenModal = () => {
    setCurrentStep(1);
    setShowSlide(true);
  };

  const handleCloseModal = () => {
    setShowSlide(false);
    setCurrentStep(1);
  };

  const handleNextModal = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : 1));
  };

  return (
    <div className="flex flex-col bg-[#F9FAFA] gap-6 pt-3 pb-3 items-center min-h-screen">
      <Navbar type={3} handleNotification={handleToggleNotification} />

      <div className="w-[95%] h-full rounded-[12px] gap-5 p-10 border-[#F2F2F2] border-[1.1px] drop-shadow-custom bg-[#FFF] max-w-[1664px] flex">
        <div className="flex flex-col flex-1">
          <h2 className="text-[54px] font-[400] leading-[59.4px] tracking-[-3.1%] max-w-[380px]">
            Save
          </h2>

          <div className="mt-3">
            <h4 className="font-[500] mb-3 text-[#131E3D] text-base leading-[17.6px] tracking-[-1%]">
              Overview
            </h4>
            <SavingsOverview />
          </div>

          <div className="mt-[90px]">
            <div className="max-w-[698px] flex items-center justify-between mb-5 pr-1">
              <h4 className="font-[500] text-[#131E3D] text-base leading-[17.6px] tracking-[-1%]">
                Recent Transactions
              </h4>
              <FiMoreVertical
                className="cursor-pointer"
                size={20}
                color={"#131E3D"}
              />
            </div>
            {showTransactionTable ? (
              <TransactionTable />
            ) : (
              <RecentTransaction
                onClick={() => setShowTransactionTable(true)}
              />
            )}
          </div>
        </div>

        <div className="flex flex-col flex-1">
          <div>
            <div className="max-w-[698px] flex items-center justify-between mb-5 mt-10 pr-1">
              <h4 className="font-[500] text-[#131E3D] text-base leading-[17.6px] tracking-[-1%]">
                Actions
              </h4>
            </div>
            <div className="flex items-center gap-4 mb-[20px]">
              <DepositButton title={"Save More"} onClick={handleOpenModal} />
              <WithdrawButton />
            </div>
            <div className="mb-[20px] mt-[40px] font-[500] text-[#131e3d] text-base leading-[17.6px] tracking-[-1%]">
              Goal
            </div>
            <Goal />
          </div>

          <h4 className="font-[500] mt-[50px] mb-[30px] text-[#131E3D] text-base leading-[17.6px] tracking-[-1%]">
            More Details
          </h4>
          <SavingsDetails />
        </div>
      </div>

      {showSlide && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          {currentStep === 1 && (
            <SavingAmountModal
              handleNext={handleNextModal}
              handleClose={handleCloseModal}
              values={"13"}
            />
          )}
          {currentStep === 2 && (
            <SavingGoalModal
              handleNext={handleNextModal}
              handleBack={handleBack}
              handleClose={handleCloseModal}
            />
          )}
          {currentStep === 3 && (
            <SavingDateModal
              handleNext={handleNextModal}
              handleBack={handleBack}
              handleClose={handleCloseModal}
            />
          )}
          {currentStep === 4 && (
            <SavingSuccess handleClose={handleCloseModal} />
          )}
        </div>
      )}
    </div>
  );
};

export default Save;

import React, { useState } from "react";
import close from "../../Assets/Images/close.png";
import { useNavigate } from "react-router-dom";
import { LiaAngleLeftSolid } from "react-icons/lia";
import OTPInput from "react-otp-input";
import { formatAmount } from "../../utils/formatAmount";
import { useDispatch, useSelector } from "react-redux";
import MainButton from "../Buttons/MainButton";
import { withdraw } from "../../store/actions/dashboard/withdraw";
import { resetwithdrawal } from "../../store/reducers/walletSlice";
import { walletToWalletTransfer } from "../../store/actions/wallet/walletToWalletTransfer";

const WithdrawalSubmittion = ({
  next,
  type,
  currency,
  handleClose,
  handleBack,
}) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");

  const dispatch = useDispatch();
  const { withdrawalAmount, withdrawalAccount, conversionRate } = useSelector(
    (state) => state?.wallet
  );

  const [submitting, setSubmitting] = useState(false);

  const handleVerify = (pin, withdrawalAmount, bank, type) => {
    if (type === "bank") {
      setSubmitting(true);
      const data = {
        amount: Number(withdrawalAmount),
        // bank_id: bank?.id,
        bank_name: bank?.bank_name || bank?.bank_type,
        bank_code: bank?.bank_code || bank?.swift_code || bank?.routing_number,
        bank_account: bank?.account_number,
        bank_holder: bank?.account_name,
        transaction_pin: pin,
      };

      dispatch(
        withdraw({
          data,
          failed: () => {
            setOtp("");
            setSubmitting(false);
          },
          cb: () => {
            dispatch(resetwithdrawal());
            setSubmitting(false);
            next();
          },
          currency: currency === "Naira" ? "NGR" : "USD",
        })
      );
    } else {
      setSubmitting(true);
      dispatch(
        walletToWalletTransfer({
          data: {
            from_currency:
              type === "withdrawal"
                ? currency === "Naira"
                  ? "NGN"
                  : "USD"
                : currency === "Naira"
                ? "USD"
                : "NGN",
            to_currency:
              type === "withdrawal"
                ? currency === "Naira"
                  ? "USD"
                  : "NGN"
                : currency === "Naira"
                ? "NGN"
                : "USD",
            amount: Number(withdrawalAmount),
            transaction_pin: pin,
          },
          failed: () => {
            setOtp("");
            setSubmitting(false);
          },
          cb: () => {
            dispatch(resetwithdrawal());
            setSubmitting(false);
            next();
          },
          currency: currency === "Naira" ? "NGR" : "USD",
        })
      );
    }
  };

  return (
    <div className="relative bg-white  mb-[30px] mx-auto w-[553px] h-[431px] rounded-[14px] border-[1px] border-[#f0f0f5] drop-shadow-custom">
      <div className="absolute top-3 p-3 right-3 cursor-pointer">
        <img
          onClick={handleClose}
          src={close}
          alt="Close"
          className="w-6 h-6"
        />
      </div>
      <div className="mt-[50px] mx-[20px] flex flex-col gap-4">
        <div className="flex gap-[100px] items-start cursor-pointer">
          <div onClick={handleBack} className=" flex items-center gap-[1px]">
            <LiaAngleLeftSolid size={18} color="#9c9c9e" className="mr-2" />
            <h3 className="text-[14px] font-[400] text-[#9c9c9e]">Back</h3>
          </div>
          <div className="text-[16px] text-center justify-center mt-[px] font-[500] text-[#131e3d]">
            Confirm Transaction PIN
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-[80px] gap-[7px] items-center ">
        <OTPInput
          value={otp}
          onChange={setOtp}
          inputType="number"
          numInputs={4}
          autoComplete={"off"}
          renderInput={(props) => <input {...props} />}
          placeholder="----"
          containerStyle={{
            display: "flex",
            justifyContent: "center",
            // marginTop: "34px",
            // marginBottom: "18px",
          }}
          inputStyle={{
            width: "50px",
            "-webkit-text-security": "disc",
            height: "64px",
            width: "64px",
            fontWeight: "500",
            border: "1.1px solid #F2F2F2",
            borderRadius: "10px",
            fontWeight: "bold",
            color: "#131E3D",
            fontSize: "32px",
            marginLeft: "6px",
            marginRight: "6px",
          }}
        />
      </div>

      <div className="mx-auto flex justify-center items-center">
        {withdrawalAmount?.type === "other-wallet" ? (
          <MainButton
            mainClasses="!w-[266px] !mx-auto !mt-[90px] !my-0 !mb-[20px] text-white"
            title={`Add ${currency === "Naira" ? "₦" : "$"}${
              !conversionRate
                ? "--"
                : formatAmount(
                    currency === "Naira"
                      ? withdrawalAmount?.amount
                      : withdrawalAmount?.amount
                  )
            }`}
            loading={submitting}
            type="submit"
            onClick={
              otp?.length !== 4 || !conversionRate
                ? null
                : () => {
                    handleVerify(
                      otp,
                      currency === "Naira"
                        ? withdrawalAmount?.amount / conversionRate
                        : withdrawalAmount?.amount * conversionRate,
                      withdrawalAccount
                    );
                  }
            }
            disabled={otp?.length !== 4 || !conversionRate}
            // loading={loading}
          />
        ) : (
          <MainButton
            mainClasses="!w-[266px] !mx-auto !mt-[90px] !my-0 !mb-[20px] text-white"
            title={`Withdraw ${currency === "Naira" ? "₦" : "$"}${formatAmount(
              withdrawalAmount?.amount || withdrawalAmount
            )}`}
            loading={submitting}
            type="submit"
            onClick={
              otp?.length !== 4
                ? null
                : () => {
                    handleVerify(
                      otp,
                      withdrawalAmount?.amount || withdrawalAmount,
                      withdrawalAccount,
                      type === "bank" ? "bank" : withdrawalAmount?.type
                    );
                  }
            }
            disabled={otp?.length !== 4}
            // loading={loading}
          />
        )}
      </div>

      <div className=" text-center text-[14px] mt-[18px] font-[400] text-[#9c9c9e] ">
        Confirm your Halvest PIN to process this transfer
      </div>
    </div>
  );
};

export default WithdrawalSubmittion;

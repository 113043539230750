import React, { useState } from "react";
import { Label } from "recharts";

const Switcher = ({ label, handleCurrencyChange, options }) => {
  const [selectedCurrency, setSelectedCurrency] = useState(label || "Naira");
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (currency) => {
    handleCurrencyChange(currency);
    setSelectedCurrency(currency?.label || currency);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <div
        className="min-w-[77px]  px-3 h-[34px] rounded-[6px] drop-shadow-custom flex items-center justify-center gap-[3px]  bg-[#FFF] border-[#F2F2F7] border-[1.3px] cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-[14px] font-[400] text-[#131E3D] ">
          {selectedCurrency}
        </h3>
        <svg
          className={`w-4 h-4 transform transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </div>
      {isOpen && (
        <div className="absolute top-[36px] left-0 min-w-[77px] w-full bg-white border-[#F2F2F7] drop-shadow-custom border-[1.3px] rounded-[6px] overflow-hidden z-10">
          {options
            ? options
                ?.filter(
                  (item) =>
                    item?.label !== selectedCurrency &&
                    item !== selectedCurrency
                )
                ?.map((option) => {
                  return (
                    <div
                      className="px-2 py-1 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleSelect(option)}
                    >
                      <h4 className="font-[400] text-[14px] text-[#131E3D]">
                        {option?.label}
                      </h4>
                    </div>
                  );
                })
            : ["Naira", "Dollar"]
                ?.filter(
                  (item) =>
                    item?.label !== selectedCurrency &&
                    item !== selectedCurrency
                )
                ?.map((option) => {
                  return (
                    <div
                      className="px-2 py-1 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleSelect(option)}
                    >
                      <h4 className="font-[400] text-[14px] text-[#131E3D]">
                        {option}
                      </h4>
                    </div>
                  );
                })}
        </div>
      )}
    </div>
  );
};

export default Switcher;

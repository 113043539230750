import MainButton from "../Buttons/MainButton";
import React, { useState } from "react";
import TextInput from "../Inputs/TextInput";
import { formHandler } from "../../utils/formhandler";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { nokProfileDetailsSchema } from "../../helper/validationSchemas";
import PhoneInputField from "../Inputs/PhoneInputField";
import { convertBirthDate } from "../../utils/convertDOB";
import { updateUserProfile } from "../../store/actions/profile/updateProfile";
import DatePicker from "../Inputs/DatePicker";
import SelectField from "../Inputs/SelectField";
import dayjs from "dayjs";

const NOK = ({ profile }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleSubmit = (values, { setSubmitting }) => {
    const formData = new FormData();
    const birthDate = new Date(form.values.dob);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();

    if (age < 18) {
      // dispatch(showErrorModal("You must be 18 years or older to proceed."));
      return;
    }

    const formattedBirthDate = convertBirthDate(new Date(values.birth_date));

    formData.append("nok_first_name", values?.first_name);
    formData.append("nok_last_name", values?.last_name);
    formData.append(
      "nok_phone",
      values?.phone?.startsWith(values?.phoneCode)
        ? values?.phone
        : `${values?.phoneCode}${values?.phone}`
    );
    formData.append("nok_email", values.email);
    formData.append("nok_gender", values?.gender?.value?.toLowerCase());
    formData.append("nok_birth_date", formattedBirthDate);
    // formData.append("nok_relationship", "Sibling");

    dispatch(
      updateUserProfile({
        data: formData,
        navigate,
        formValues: values,
        from: "settings",
        cb: () => {
          setSubmitting(false);
        },
      })
    );
  };

  const form = useFormik({
    initialValues: {
      first_name: profile?.nok_first_name || "",
      last_name: profile?.nok_last_name || "",
      email: profile?.nok_email || "",
      phone: profile?.nok_phone || "",
      phoneCode: "+234",
      birth_date: profile?.nok_birth_date
        ? dayjs(profile?.nok_birth_date)
        : null,
      gender: profile?.nok_gender
        ? {
            label: profile?.nok_gender,
            value: profile?.nok_gender,
          }
        : null,
    },
    onSubmit: handleSubmit,
    validationSchema: nokProfileDetailsSchema,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div className="w-full md:w-[642px] min-h-[423px] bg-white border-[1.2px] rounded-[14px] border-[#F0F0F5]  drop-shadow-custom p-[30px]">
      <div className=" p-1  mb-4">
        <h2 className="text-[20px] font-[500] text-[#131e3d]"> Next of Kin </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            {" "}
            First Name{" "}
          </label>
          <TextInput
            {...formHandler("first_name", form)}
            mainClasses={"mb-0 mt-0 !w-full "}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            Last Name{" "}
          </label>
          <TextInput
            {...formHandler("last_name", form)}
            mainClasses={"mb-0 mt-0 !w-full "}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            Phone{" "}
          </label>
          <div className="flex   w-full ">
            <PhoneInputField
              fullWidth
              name={"phone"}
              form={form}
              id={"phone"}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            {" "}
            Next of Kin Email{" "}
          </label>
          <TextInput
            {...formHandler("email", form)}
            type={"email"}
            mainClasses={"mb-0 mt-0 !w-full "}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            {" "}
            Date of Birth{" "}
          </label>

          <DatePicker
            title={"Date of Birth"}
            id="birth_date"
            {...formHandler("birth_date", form)}
            type={"text"}
            form={form}
            placeholder={"Date of Birth"}
            mainClasses={"mb-0 mt-0 !w-full "}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            {" "}
            Gender{" "}
          </label>
          <div className="w-full ">
            <SelectField
              title="Gender"
              id="gender"
              form={form}
              options={[
                {
                  label: "Male",
                  value: "Male",
                },
                {
                  label: "Female",
                  value: "Female",
                },
              ]}
              sx={{ width: "100%", height: "50px" }}
            />
          </div>
        </div>
      </div>
      <div className="mt-6">
        <MainButton
          mainClasses="!w-[169px] !my-0 text-white"
          title={"Update"}
          onClick={form.handleSubmit}
          loading={form.isSubmitting}
          disabled={form.isSubmitting}
        />
      </div>
    </div>
  );
};

export default NOK;

import React from "react";
import { FiMessageSquare, FiPhone } from "react-icons/fi";

const Support = ({ close, otherStyles }) => {
  return (
    <>
      <div
        onClick={() => {
          close();
        }}
        className="fixed px-4  top-[0px] h-full right-0 bg-black bg-opacity-30 flex items-start justify-end z-50 w-full"
      ></div>
      <div
        className={`bg-white w-[445px] h-[445px]  border-[1.1px] top-[75px] right-[280px] z-50 rounded-[12px] border-[#f2f2f2] fixed ${otherStyles}`}
      >
        <div className=" flex flex-col p-4 lg:p-8  justify-start ">
          <div className="  text-[16px] font-[500] text-[#131e3d]  mb-[25px] ">
            Need Help?
          </div>

          <a
            href="https://api.whatsapp.com/send?phone=2347042915411&text=Halvest"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className=" w-[381px] h-[125px] drop-shadow-custom rounded-[14px] border-[1.1px] border-[#f0f0f5] ">
              <div className="mt-[10px] py-[10px] px-[27px] flex items-center gap-[6px]">
                <FiPhone color="#131e3d" />
                <div className="text-[16px] font-[500] text-[#131e3d]">
                  WhatsApp
                </div>
              </div>
              <div className="mx-[27px] h-[0.5px] bg-[#f2f2f2]"></div>
              <div className="mt-[10px] text-[14px] font-[400] text-[#9c9c9e] px-[27px]">
                Reach out to us for quick prompt support over WhatsApp
              </div>
            </div>
          </a>

          <div className="cursor-not-allowed w-[381px] h-[125px] drop-shadow-custom rounded-[14px] border-[1.1px] border-[#f0f0f5] mt-[20px]">
            <div className="mt-[10px] py-[10px] px-[27px] flex items-center gap-[6px]">
              <FiMessageSquare color="#131e3d" />
              <div className="text-[16px] font-[500] text-[#131e3d]">
                Live Chat{" "}
              </div>
            </div>
            <div className="mx-[27px] h-[0.5px] bg-[#f2f2f2]"></div>
            <div className="mt-[10px] text-[14px] font-[400] text-[#9c9c9e] px-[27px]">
              Reach out to us for quick prompt support over the phone
            </div>
          </div>

          <a
            target="_blank"
            rel="noreferrer"
            href="mailto:support@halvestco.com"
            className="text-[#6935D3]"
          >
            <div className=" text-[12px] px-1 cursor-pointer font-[400] mt-5 text-[#6935D3] ">
              support@halvestco.com
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Support;

import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { LiaAngleLeftSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import loc from "../../Assets/Images/location_on.png";
import AboutRealEstate from "../../Components/AboutRealEstate";
import PropertyImages from "../../Components/PropertyImages";
import RealEstateCta from "../../Components/RealEstateCta";
import RealEstateReturnsCalculator from "../../Components/RealEstateReturnsCalculator";
import Notification from "../../Components/Notification/Notification";

const RealEstateDetails = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);

  const handleToggleNotification = () => {
    setShowNotification((prev) => !prev);
  };

  return (
    <div className="flex flex-col bg-[#F9FAFA] gap-6 pt-3 pb-3 items-center min-h-screen">
      <Navbar type={3} handleNotification={handleToggleNotification} />

      <div className="w-[95%] h-full flex flex-col rounded-[12px] gap-10 p-10 bg-[#FFF] border-[#F2F2F2] border-[1.1px] drop-shadow-custom max-w-[1664px]">
        <div
          onClick={() => navigate(-1)}
          className="flex cursor-pointer items-center mb-4"
        >
          <LiaAngleLeftSolid size={18} color="#131E3D" className="mx-[2px]" />
          <h3 className="text-[14px] font-[400] text-[#131E3D]">Back</h3>
        </div>

        <div className="min-w-[1050px] mx-auto items-center">
          <div className="flex pb-3 items-end justify-between ">
            <div className="text-[32px] leading-tight font-[500] text-[#131e3d]">
              Akeem Al’ Ameed
            </div>
            <div className="flex pb-1 items-center gap-[5px]">
              <img src={loc} alt="" className="w-[14px] h-[14px]" />
              <div className="text-[14px] font-[400] text-[#9c9c9e]">
                Dubai Palms, UAE
              </div>
            </div>
          </div>
          <div className="flex justify-center items-start gap-[30px]">
            <div className="">
              <AboutRealEstate />
            </div>
            <div className="flex flex-col justify-center items-end">
              <PropertyImages />
              <RealEstateCta />
            </div>
          </div>
          <div className=" ">
            <div className="flex flex-col justify-start items-start gap-4">
              <div className="text-[16px] font-[500] text-[#131e3d]">
                Estimate your returns
              </div>
              <RealEstateReturnsCalculator />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealEstateDetails;

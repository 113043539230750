import React, { useState } from "react";
import close from "../Assets/Images/close.png";
import CustomInput from "./Inputs/CustomInput";
import { LiaAngleLeftSolid } from "react-icons/lia";

const SavingGoalModal = ({
  handleClose,
  handleBack,
  handleNext,
  backButtonLabel,
}) => {
  const [amount, setAmount] = useState(0.0);

  return (
    <div className="flex flex-col justify-center items-center border-[1px] border-[#F0F0F5] w-[90%] sm:w-[553px] mt-[30px] mx-auto mb-[30px] h-[431px] rounded-[14px] shadow-xl relative bg-white">
      <div className="absolute p-3 top-5 right-6 cursor-pointer">
        <img
          src={close}
          alt="Close"
          className="cursor-pointer w-6 h-6"
          onClick={handleClose}
        />
      </div>

      <div className="flex justify-between items-center w-full px-8 mt-[50px]">
        <button
          onClick={handleBack}
          className="flex items-center gap-2 text-[#9c9c9e] text-[14px] font-[400]"
        >
          <LiaAngleLeftSolid color="#9c9c9e" />
          {backButtonLabel || "Back"}
        </button>

        <h2 className="text-[16px] text-[#9c9c9e] font-[400] text-center">
          Enter Saving Amount
        </h2>
        <div className="w-[40px]" />
      </div>

      <div className="flex flex-col justify-center items-center w-full h-full ">
        <CustomInput
          fullWidth
          noBorder
          bg={true}
          autoComplete={"off"}
          height={30}
          autoFocus={true}
          placeholder={``}
          customTextStyle={{}}
          textInputCustomStyles={{
            textAlign: "center",
            fontSize: "64px",
            color: "#131e3d",
          }}
          variant={"standard"}
          selfValue={amount}
          customType="amount"
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />

        <button
          onClick={handleNext}
          className="w-[250px] h-[45px] rounded-[8px] bg-[#6935D3] shadow-custom justify-center items-center flex font-[500] text-[16px] text-white mt-6"
        >
          Continue
        </button>
        <div className=" text-[14px] font-[400] text-[#6935D3] mt-[35px] ">
          Skip and set later
        </div>
      </div>
    </div>
  );
};

export default SavingGoalModal;

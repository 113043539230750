import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa"; 

const VentureInvestmentCard = ({
  logo,
  location,
  companyName,
  category,
  percentageRaised,
  goalAmount,
  handleClick
}) => {
  return (
    <div
    onClick={handleClick}
      className="w-[334px] cursor-pointer h-[289px] bg-white flex flex-col justify-start p-4 gap-4 rounded-[14px] border-[1.2px] drop-shadow-custom border-[#f0f0f5]"
      style={{ opacity: 1 }}
    >
      <div className="flex w-[180px] items-center gap-2 bg-[#f0f0f5] rounded-full p-2">
        <FaMapMarkerAlt className="text-gray-600" />
        <span className="text-[#131e3d] font-[400] text-[14px]">{location}</span>
      </div>

      <div className="flex justify-start items-start gap-4">
        <img src={logo} alt={`${companyName} logo`} className="w-[64px] h-[64px] rounded-full" />
      </div>

      <div className="text-left">
        <h3 className="text-[20px] text-[#131e3d] font-[500]">{companyName}</h3>
        <p className="text-sm text-[#9c9c9e] font-[400] ">{category}</p>
      </div>

      <div className="w-full mt-2">
        <div className="h-[3px] w-full bg-[#f0f0f5]">
          <div
            className="h-[3px] bg-[#131e3d]"
            style={{ width: `${percentageRaised}%` }} 
          ></div>
        </div>
        <p className="text-[14px] text-[#9c9c9e] font-[400] text-left mt-[18px]">
          {percentageRaised}% Raised of {goalAmount.toLocaleString()}
        </p>
      </div>
    </div>
  );
};

export default VentureInvestmentCard;

import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput, { getCountryCallingCode } from "react-phone-number-input";
import { LiaAngleDownSolid } from "react-icons/lia";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PhoneInputField = ({
  form,
  name, // The 'name' prop is used to manage value in Formik
  setActiveInput,
  label,
  fullWidth,
  height,
  disabled,
  width,
}) => {
  const onFocus = () => {
    setActiveInput && setActiveInput(name);
  };

  return (
    <>
      {/* className=
      {`flex  w-full disabled:cursor-not-allowed text-[15px] outline-none disabled:bg-black-10 placeholder:text-black-50 focus:border-[2px] focus:border-black-90 invalid:[&:not(:placeholder-shown):not(:focus)]:border-[2px] invalid:[&:not(:placeholder-shown):not(:focus)]:border-error-light transition-all ease-in-out duration-100 rounded-[8px] px-2 md:px-4  h-[52px]  py-3 leading-tight focus:outline-none focus:bg-white drop-shadow-custom ${inputClasses}`} */}
      <div
        className="phone-input-container1 w-full h-[52px] mb-3 rounded-[8px] drop-shadow-custom text-gray-700 border-[1px] border-gray-200  "
        style={{ width: fullWidth ? "100%" : width, height: height || "52px" }}
      >
        <PhoneInput
          className="w-full px-4 flex justify-end phone-input-container1 "
          style={{
            width: "100%",
            height: "100%",
            fontWeight: "400",
            color: disabled ? "#999" : "#101828",
            fontSize: "15px",
          }}
          defaultCountry="NG"
          value={form.values[name]} // Use 'name' to retrieve value from Formik state
          onChange={(phoneNumber) => {
            form.setFieldValue(name, phoneNumber);
          }}
          onFocus={onFocus}
          focusInputOnCountrySelection={false}
          onCountryChange={(code) => {
            if (code) {
              const countryCode = "+" + getCountryCallingCode(code);
              form.setFieldValue("phoneCode", countryCode);
            }
          }}
          disabled={disabled}
          countrySelectProps={{
            arrowComponent: () => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "10px",
                }}
              >
                {/* <h4
                    style={{
                      marginLeft: 10,
                      fontSize: "16px",
                      fontWeight: "400",
                      marginRight: 15,
                      color: "#131E3D",
                    }}
                  >
                    {form.values.phoneCode}
                  </h4> */}
                <LiaAngleDownSolid size={16} color="#131E3D" className="mr-2" />
              </div>
            ),
          }}
        />
        {form?.errors[name] && (
          <div className="text-red-500 text-xs mt-1 w-full ">
            {form.errors[name]}
          </div>
        )}
      </div>
    </>
  );
};

export default PhoneInputField;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { nationalitySchema } from "../helper/validationSchemas";
import AuthButton from "./Buttons/AuthButton";
import SelectField from "./Inputs/SelectField";
import { countries } from "../utils/data";

const NationalityForm = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  const handleSubmit = (values, { setSubmitting }) => {
    navigate("/signup", {
      state: {
        country: values?.nationality?.value,
        country_of_residence:
          values?.whereYouLive?.value || values?.nationality?.value,
      },
    });
  };

  const form = useFormik({
    initialValues: {
      whereYouLive: null,
      nationality: null,
    },
    onSubmit: handleSubmit,
    validationSchema: nationalitySchema,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div className=" flex flex-col  flex-1  p-4">
      <div className="flex drop-shadow-[0_10px_14px_0px_rgba(0,0,0,0.25)]  ">
        <div className="w-full lg:w-[493px] py-8 px-6 bg-[#FFFFFF] border-[#F2F2F2] border-[1.1px] drop-shadow-custom shadow-custom">
          <div className="mb-6">
            <label
              className="block tracking-wide text-[#9C9C9E] text-[14px] font-[400] mb-2"
              htmlFor="phone-number"
            >
              Tell us your nationality
            </label>

            <div className="w-full ">
              <SelectField
                id="nationality"
                form={form}
                options={["Nigeria"]?.map((i) => ({
                  label: i,
                  value: i,
                }))}
                sx={{ width: "100%", height: "50px" }}
              />
            </div>
          </div>
          <div className="mb-6">
            <label
              className="block tracking-wide text-[#9C9C9E] text-[14px] font-[400] "
              htmlFor="phone-number"
            >
              Where do you live
            </label>
            <label style={{ display: "flex", alignItems: "center" }}>
              <input
                id="checkbox"
                type="checkbox"
                checked={checked}
                onChange={(event) => {
                  setChecked(event.target.checked);
                }}
                style={{
                  color: "#6935D3",
                  width: "12px",
                  height: "12px",
                  borderWidth: "3px",
                  borderRadius: "16px",
                  marginRight: "8px",
                }}
              />
              <h4 className="font-[500] text-[12px] text-[#9C9C9E]  ">
                I live in my country of nationality
              </h4>
            </label>
            {checked ? (
              <></>
            ) : (
              <div className="w-full mt-2 ">
                <SelectField
                  id="whereYouLive"
                  form={form}
                  options={countries?.map((i) => ({
                    label: i,
                    value: i,
                  }))}
                  sx={{ width: "100%", height: "50px" }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <h4 className="font-[400] lg:w-[493px]  mt-7 text-[16px] text-[#9C9C9E] leading-[20.8px]">
        We collect your country of residence and nationality to personalize your
        Halvest experience according to your region
      </h4>

      <AuthButton
        title={"Proceed"}
        onClick={
          !checked && !form?.values?.whereYouLive ? null : form.handleSubmit
        }
        loading={form.isSubmitting}
        disabled={form.isSubmitting}
        // mainClasses={" hover:text-[red] hover:border-[#192851] "}
        // textStyles={"group-hover:text-[#192851]"}
      />
    </div>
  );
};

export default NationalityForm;

import { createSlice } from "@reduxjs/toolkit";
import { login } from "../actions/onboarding/login";
import { logout } from "../actions/user/logout";
import { forgotPassword } from "../actions/onboarding/forgotPassword";
import { registerUsers } from "../actions/onboarding/registerUsers";
import { resetPassword } from "../actions/onboarding/resetPassword";
import { resendOTP } from "../actions/onboarding/resendotp";
import { verifyAccount } from "../actions/onboarding/verifyAccount";
import { fetchUserProfile } from "../actions/profile/getProfile";
import { getSecurityPasswordOTP } from "../actions/onboarding/getSecurityPasswordOTP";
import { resetTransactionPin } from "../actions/onboarding/resetTransactionPin";
import { getCreateSecurityPINOTP } from "../actions/onboarding/getCreateSecurityPINOTP";
import { getUpdateSecurityPINOTP } from "../actions/onboarding/getUpdateSecurityPINOTP";
import { getNewToken } from "../actions/onboarding/getNewToken";
import { autoGetProfile } from "../actions/profile/autoGetProfile ";
import { allDashboard } from "../actions/dashboard/allDashboard";

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: {
    user: null,
    userSignedup: null,
    profile: null,
    error: null,
    success: false,
    pending: false,
    pendingToken: false,
    getingOTP: false,
    forgotPasswordSuccess: false,
    resetPasswordSuccess: false,
    forgotPasswordFailed: false,
    resetPasswordFailed: false,
    resetPasswordPending: false,
    pinLoading: false,
    tempAuth: null,
  },

  reducers: {
    setUserForOnboarding: (state, action) => {
      state.userSignedup = action.payload;
    },
    onboardUser: (state, action) => {
      state.user = state?.userSignedup;
    },
    setNetwork: (state, action) => {
      state.network = action.payload;
    },
    clearLoading: (state, action) => {
      state.pending = false;
    },
    loginFromVerify: (state, action) => {
      state.user = action?.payload?.user;
      state.auth = action.payload;
      state.profile = null;
    },
    logoutUser: (state) => {
      state.user = null;
      state.profile = null;
      state.pending = false;
      state.success = false;
      state.failed = false;
      state.tokenIsValid = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getNewToken.pending, (state, action) => {
        state.pendingToken = true;
        // state.user = null;
        // state.profile = null;
      })
      .addCase(getNewToken.fulfilled, (state, action) => {
        state.pendingToken = false;
        // state.user = action?.payload?.user;
        // state.auth = action.payload;
      })
      .addCase(getNewToken.rejected, (state, action) => {
        state.pendingToken = false;
      })
      .addCase(login.pending, (state, action) => {
        state.pending = true;
        state.user = null;
        state.profile = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.pending = false;
        state.user = action?.payload?.user;
        state.auth = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.pending = false;
      })
      .addCase(resendOTP.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(resendOTP.fulfilled, (state, action) => {
        state.pending = false;
      })
      .addCase(resendOTP.rejected, (state, action) => {
        state.pending = false;
      })
      .addCase(getSecurityPasswordOTP.pending, (state, action) => {
        state.getingOTP = true;
      })
      .addCase(getSecurityPasswordOTP.fulfilled, (state, action) => {
        state.getingOTP = false;
      })
      .addCase(getSecurityPasswordOTP.rejected, (state, action) => {
        state.getingOTP = false;
      })
      .addCase(getCreateSecurityPINOTP.pending, (state, action) => {
        state.getingOTP = true;
      })
      .addCase(getCreateSecurityPINOTP.fulfilled, (state, action) => {
        state.getingOTP = false;
      })
      .addCase(getCreateSecurityPINOTP.rejected, (state, action) => {
        state.getingOTP = false;
      })
      .addCase(getUpdateSecurityPINOTP.pending, (state, action) => {
        state.getingOTP = true;
      })
      .addCase(getUpdateSecurityPINOTP.fulfilled, (state, action) => {
        state.getingOTP = false;
      })
      .addCase(getUpdateSecurityPINOTP.rejected, (state, action) => {
        state.getingOTP = false;
      })
      .addCase(registerUsers.pending, (state, action) => {
        state.pending = true;
        state.user = null;
        state.profile = null;
      })
      .addCase(registerUsers.fulfilled, (state, action) => {
        state.pending = false;
        state.userSignedup = action.payload;
      });
    builder.addCase(fetchUserProfile.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.pending = false;
      state.user = action.payload?.user;
      state.profile = action.payload;
    });
    builder.addCase(allDashboard.fulfilled, (state, action) => {
      state.user = action.payload?.profile?.user || state.user;
      state.profile = action.payload?.profile;
    });
    builder.addCase(autoGetProfile.fulfilled, (state, action) => {
      state.pending = false;
      state.user = action.payload?.user;
      state.profile = action.payload;
    });
    builder
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.pending = false;
      })
      .addCase(registerUsers.rejected, (state, action) => {
        state.pending = false;
      })
      .addCase(verifyAccount.pending, (state, action) => {
        state.pending = true;
        state.user = null;
        state.profile = null;
      })
      .addCase(verifyAccount.fulfilled, (state, action) => {
        state.pending = false;
        state.tempAuth = action.payload;
      })
      .addCase(verifyAccount.rejected, (state, action) => {
        state.pending = false;
      })

      .addCase(resetPassword.pending, (state, action) => {
        state.resetPasswordPending = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetPasswordPending = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPasswordPending = false;
        state.error = true;
      })
      .addCase(resetTransactionPin.pending, (state, action) => {
        state.pinLoading = true;
      })
      .addCase(resetTransactionPin.fulfilled, (state, action) => {
        state.pinLoading = false;
      })
      .addCase(resetTransactionPin.rejected, (state, action) => {
        state.pinLoading = false;
        state.error = true;
      })

      .addCase(forgotPassword.rejected, (state, action) => {
        state.pending = false;
      })
      .addCase(forgotPassword.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.pending = false;
        state.forgotPasswordSuccess = true;
        state.resetPasswordSuccess = false;
      })

      .addCase(logout.fulfilled, (state, action) => {
        state.user = null;
        state.profile = null;
        state.pending = false;
      });
  },
});

export const {
  logoutUser,
  clearLoading,
  loginFromVerify,
  setNetwork,
  onboardUser,
  setUserForOnboarding,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;

import React from "react";
import close from "../Assets/Images/close.png";
import TextInput from "./Inputs/TextInput";
import { formHandler } from "../utils/formhandler";
import { useFormik } from "formik";
import SelectField from "./Inputs/SelectField";
import { BVNSchema } from "../helper/validationSchemas";
import { useDispatch } from "react-redux";
import { updateUserBvn } from "../store/actions/profile/updateBVN";
import MainButton from "./Buttons/MainButton";

const BVNModal = ({ handleClose, next }) => {
  const dispatch = useDispatch();

  const handleUpdateBVN = (values, { resetForm }) => {
    const BVN = values.BVN;
    const data = { BVN };
    dispatch(
      updateUserBvn({
        data,
        cb: () => {
          resetForm();
          handleClose();
          next();
        },
        failed: (msg) => {
          resetForm();
          // handleClose();
        },
      })
    );
  };
  const form = useFormik({
    initialValues: {
      BVN: "",
    },
    validationSchema: BVNSchema,
    onSubmit: handleUpdateBVN,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div className="relative mx-auto w-[553px] h-[300px] rounded-[14px] border-[1.2px] bg-[#FFF] border-[#f0f0f5] drop-shadow-custom">
      <div
        onClick={handleClose}
        className="absolute top-3 right-3 cursor-pointer px-3 py-2"
      >
        <img src={close} alt="Close" className="w-6 h-6 " />
      </div>
      <div className="p-4 lg:p-8">
        <div className="text-[16px] text-center font-[500] text-[#131e3d] mb-4">
          Enter BVN
        </div>
        <div className="flex flex-col gap-8">
          <div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col">
                <label
                  htmlFor="answer2"
                  className="text-[14px] font-[400] text-[#9c9c9e] mb-[5px]"
                >
                  BVN
                </label>
                <TextInput
                  {...formHandler("BVN", form)}
                  mainClasses={"mb-0 mt-0 !w-full max-w-[489px] "}
                />
              </div>
            </div>
          </div>
          <MainButton
            mainClasses="!h-[52px] !mt-[20px] !mb-0 text-white"
            title={"Submit BVN"}
            onClick={form.handleSubmit}
            loading={form.isSubmitting}
            disabled={form.isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

export default BVNModal;

import React, { useEffect, useState } from "react";
import dots from "../../Assets/Images/dots.png"; // Adjust the path as needed
import RealEstateOpportunities from "../../Components/RealEstateOppurtunities";
import brook from "../../Assets/Images/brooks.png";
import sobha from "../../Assets/Images/sobha.png";
import river from "../../Assets/Images/river.png";
import Navbar from "../../Components/Navbar";
import { useNavigate } from "react-router-dom";
import Notification from "../../Components/Notification/Notification";

const PropertyMarketPlace = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [showNotification, setShowNotification] = useState(false);
  const [activeTab, setActiveTab] = useState("button1");
  const navigate = useNavigate();

  // Function to handle card clicks
  const handleCardClick = (id) => {
    navigate(`/investment/RealEstate/${id}`);
  };

  const handleToggleNotification = () => {
    setShowNotification((prev) => !prev);
  };

  return (
    <>
      <div className="flex flex-col bg-[#F9FAFA] gap-6 pt-3 pb-3 items-center min-h-screen">
        <Navbar type={3} handleNotification={handleToggleNotification} />

        <div className="w-[95%] h-full flex flex-col rounded-[12px] gap-10 p-10 bg-[#FFF] border-[#F2F2F2] border-[1.1px] drop-shadow-custom max-w-[1664px]">
          <div className="flex flex-col justify-center items-center mb-2">
            <div className="text-[54px] text-[#131e3d] text-center mb-[50px] font-[400] w-[652px]">
              Property Marketplace
            </div>
            <div className="w-[152px] shadow-md p-2 h-[49px] border-[1px] rounded-[41px] flex justify-center items-center">
              <div className="flex justify-center items-center gap-2">
                <button
                  className={`py-1 font-[400] w-[48px] h-[37px] px-1 rounded-[40px] ${
                    activeTab === "button1" ? "bg-[#F1EDFB] text-[#6935D3]" : ""
                  }`}
                  onClick={() => setActiveTab("button1")}
                >
                  All
                </button>
                <button
                  className={`w-[80px] font-[400] h-[37px] py-1 px-1 rounded-[40px] ${
                    activeTab === "button2" ? "bg-[#F1EDFB] text-[#6935D3]" : ""
                  }`}
                  onClick={() => setActiveTab("button2")}
                >
                  For You
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap justify-center gap-4">
            {/* Static Card 1 */}
            <div
              className="w-full cursor-pointer sm:w-[calc(33.333%-16px)]"
              onClick={() => handleCardClick(1)}
            >
              <RealEstateOpportunities
                imageSrc={brook}
                location="Dubai Palms, UAE"
                title="Brooks Home"
                price={100}
                investedPercentage={17}
                totalAmount={180000}
              />
            </div>

            {/* Static Card 2 */}
            <div
              className="w-full cursor-pointer sm:w-[calc(33.333%-16px)]"
              onClick={() => handleCardClick(2)}
            >
              <RealEstateOpportunities
                imageSrc={sobha}
                location="Dubai Palms, UAE"
                title="Sobha Orbis"
                price={100}
                investedPercentage={67}
                totalAmount={250000}
              />
            </div>

            {/* Static Card 3 */}
            <div
              className="w-full cursor-pointer sm:w-[calc(33.333%-16px)]"
              onClick={() => handleCardClick(3)}
            >
              <RealEstateOpportunities
                imageSrc={river}
                location="Dubai Palms, UAE"
                title="340 Riverside Crescent"
                price={100}
                investedPercentage={17}
                totalAmount={400000}
              />
            </div>

            {/* Static Card 4 */}
            <div
              className="w-full cursor-pointer sm:w-[calc(33.333%-16px)]"
              onClick={() => handleCardClick(4)}
            >
              <RealEstateOpportunities
                imageSrc={brook}
                location="New York, USA"
                title="NYC Apartments"
                price={200}
                investedPercentage={45}
                totalAmount={300000}
              />
            </div>

            {/* Static Card 5 */}
            <div
              className="w-full cursor-pointer sm:w-[calc(33.333%-16px)]"
              onClick={() => handleCardClick(5)}
            >
              <RealEstateOpportunities
                imageSrc={sobha}
                location="New York, USA"
                title="NYC Apartments"
                price={200}
                investedPercentage={45}
                totalAmount={300000}
              />
            </div>

            {/* Static Card 6 */}
            <div
              className="w-full cursor-pointer sm:w-[calc(33.333%-16px)]"
              onClick={() => handleCardClick(6)}
            >
              <RealEstateOpportunities
                imageSrc={river}
                location="Dubai Palms, UAE"
                title="340 Riverside Crescent"
                price={100}
                investedPercentage={17}
                totalAmount={400000}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyMarketPlace;

import React, { useState } from "react";
import TextInput from "./Inputs/TextInput";
import SelectField from "./Inputs/SelectField";
import MainButton from "./Buttons/MainButton";
import close from "../Assets/Images/close.png";
import { useFormik } from "formik";
import { formHandler } from "../utils/formhandler";
import { useDispatch, useSelector } from "react-redux";
import { bankSchema } from "../helper/validationSchemas";
import { countries } from "../utils/data";

const AddDollarBank = ({ handleClose, next }) => {
  const dispatch = useDispatch();
  // const [_currency, getAmount] = useCurrency();

  const [selectedBank, setSelectedBank] = useState("");
  const [a, seta] = useState();

  const { banks } = useSelector((state) => state?.bank);
  const { userBanks } = useSelector((state) => state?.bank);
  const loading = useSelector((state) => state?.bank?.isAddBank);

  const form = useFormik({
    initialValues: {
      account_name: "",
      account_number: "",
      bank_type: {
        value: "International Bank Account",
        label: "International Bank Account",
      },
      beneficiary_country: {
        value: "United States",
        label: "United States",
      },
    },
    validationSchema: bankSchema.pick(["account_name", "account_number"]),
  });

  const handleBankDetails = () => {
    const payload = {
      data: {
        bank_type: form.values.bank_type?.value,

        beneficiary_bank_address: form.values.beneficiary_bank_address,
        beneficiary_address: form.values.beneficiary_address,
        beneficiary_country: form.values.beneficiary_country?.value,
        account_number: form.values.account_numberUSD,

        account_name: form.values.beneficiary_bank_name,
        currency: "USD",
        beneficiary_name: form.values.beneficiary_name,
      },
    };

    if (form.values?.bank_type?.value === "Nigerian Bank Account") {
      payload.data.swift_code = form.values.swift_code || "N/A";
      payload.data.sort_code = form.values.sort_code || "N/A";
    } else if (form.values?.bank_type?.value === "International Bank Account") {
      payload.data.routing_number = form.values.routing_number || "N/A";
      payload.data.SWIFT_BIC = form.values.SWIFT_BIC || "N/A";
      payload.data.IBAN = form.values.IBAN || "N/A";
    }

    next(payload);
  };

  return (
    <div className="relative mx-auto w-[700px] max-h-[80vh] p-[40px] rounded-[14px] border-[1px] bg-[#FFF] border-[#f0f0f5] drop-shadow-custom flex flex-col">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-[24px] text-[#131e3d] font-[500]">
          Add a Bank Account
        </h2>
        <div onClick={handleClose} className="cursor-pointer py-2 px-3">
          <img src={close} alt="Close" className="w-6 h-6" />
        </div>
      </div>

      <p className="text-sm mb-6">
        Please, only add a bank account that you own. Transactions to accounts
        that don’t belong to you will be flagged.
      </p>

      <div className="flex-grow overflow-y-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <>
            <div>
              <label className="block text-sm font-medium mb-2">
                Bank Type
              </label>
              <SelectField
                label="Choose Bank Type"
                id="bank_type"
                clearFields={[
                  // "account_name",
                  "account_numberUSD",
                  "sort_code",
                  "swift_code",
                  "routing_number",
                  "IBAN",
                  "beneficiary_bank_name",
                  "beneficiary_name",
                  "SWIFT_BIC",
                  "beneficiary_bank_address",
                  "beneficiary_address",
                  "beneficiary_country",
                ]}
                form={form}
                options={[
                  {
                    value: "Nigerian Bank Account",
                    label: "Nigerian Bank Account",
                  },
                  {
                    value: "International Bank Account",
                    label: "International Bank Account",
                  },
                ]}
                sx={{ height: "50px" }}
                // name="bankType"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">
                Beneficiary Bank Name
              </label>
              <TextInput
                mainClasses="!w-full"
                placeholder={"Enter Beneficiary Bank Name "}
                {...formHandler("beneficiary_bank_name", form)}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">
                Beneficiary Bank Address
              </label>
              <TextInput
                mainClasses="!w-full"
                placeholder={"Enter Beneficiary Bank Address "}
                {...formHandler("beneficiary_bank_address", form)}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">
                Beneficiary Address
              </label>
              <TextInput
                mainClasses="!w-full"
                placeholder={"Enter Beneficiary Address "}
                {...formHandler("beneficiary_address", form)}
              />
            </div>
            {form.values?.bank_type?.value === "Nigerian Bank Account" ? (
              <></>
            ) : (
              <>
                <div>
                  <label className="block text-sm font-medium mb-2">
                    Beneficiary Country
                  </label>
                  <SelectField
                    label="Choose Beneficiary Country"
                    id="beneficiary_country"
                    form={form}
                    options={countries?.map((i) => ({ value: i, label: i }))}
                    sx={{ height: "50px" }}
                  />
                </div>
              </>
            )}
            <div>
              <label className="block text-sm font-medium mb-2">
                Account Number
              </label>
              <TextInput
                mainClasses="!w-full"
                {...formHandler("account_numberUSD", form)}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">
                Beneficiary Name
              </label>
              <TextInput
                mainClasses="!w-full"
                {...formHandler("beneficiary_name", form)}
              />
            </div>
            {form.values?.bank_type?.value === "Nigerian Bank Account" ? (
              <></>
            ) : (
              <>
                {/* <div>
                  <label className="block text-sm font-medium mb-2">
                    Routing Number
                  </label>
                  <TextInput
                    mainClasses="!w-full"
                    {...formHandler("routing_number", form)}
                  />
                </div> */}
              </>
            )}
            {form.values?.bank_type?.value === "Nigerian Bank Account" ? (
              <>
                <div>
                  <label className="block text-sm font-medium mb-2">
                    Sort Code
                  </label>
                  <TextInput
                    mainClasses="!w-full"
                    {...formHandler("sort_code", form)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-2">
                    Swift Code
                  </label>
                  <TextInput
                    mainClasses="!w-full"
                    {...formHandler("swift_code", form)}
                  />
                </div>
              </>
            ) : (
              <>
                {/* <div>
                  <label className="block text-sm font-medium mb-2">
                    SWIFT / BIC
                  </label>
                  <TextInput
                    mainClasses="!w-full"
                    {...formHandler("sort_code", form)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-2">IBAN</label>
                  <TextInput
                    mainClasses="!w-full"
                    {...formHandler("IBAN", form)}
                  />
                </div> */}
              </>
            )}
          </>
        </div>
        <div className="mt-10 flex justify-center">
          <MainButton
            mainClasses="!w-[240px] !my-0 !mb-[20px] text-white"
            title=" Confirm with PIN"
            type="submit"
            onClick={handleBankDetails}
            // disabled={
            //   form?.values?.currency?.value === "USD"
            //     ? false
            //     : !form?.dirty || !selectedBank || !form?.isValid
            // }
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default AddDollarBank;

import { createSlice } from "@reduxjs/toolkit";
import { getOpportunities } from "../actions/opportunities/getopportunities";
import { getOpportunityDetails } from "../actions/opportunities/getopportunitydetails";
import { submitInvestment } from "../actions/investment/submitinvestment";
import { investNow } from "../actions/investment/investNow";
import { submitInterest } from "../actions/investment/submitInterest";
import { getInvestment } from "../actions/investment/getInvestment";
import { switchCurrency } from "../actions/dashboard/switchCurrency";
import { getREInvestments } from "../actions/realEstate/getREInvestments";

export const opportunitiesSlice = createSlice({
  name: "opportunities",
  initialState: {
    error: null,
    pending: false,
    investPending: false,
    submitInterestPending: false,
    investNowPending: false,
    success: false,
    opportunities: [],
    opportunitiesListDetails: null,
    opportunity: null,
    REInvestments: null,
    realEstate: null,
    interestDetails: {},
    getInvestmentPending: false,
  },

  reducers: {
    updateOpportunities: (state, action) => {
      state.opportunities = [...state.opportunities, ...action.payload];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(switchCurrency.fulfilled, (state, action) => {
        state.opportunities = [];
        state.opportunity = null;
        state.interestDetails = {};
      })
      .addCase(getOpportunities.pending, (state, action) => {
        state.pending = true;
        state.opportunitiesListDetails = null;
      })
      .addCase(getOpportunities.fulfilled, (state, action) => {
        state.pending = false;
        state.opportunities = action.payload || [];
        // state.opportunitiesListDetails = { data: action.payload };
      })
      .addCase(getOpportunities.rejected, (state, action) => {
        state.pending = false;
      })
      .addCase(getOpportunityDetails.pending, (state, action) => {
        state.pending = true;
        state.opportunity = null;
      })
      .addCase(getOpportunityDetails.fulfilled, (state, action) => {
        state.pending = false;
        state.opportunity = action.payload?.data;
      })
      .addCase(getOpportunityDetails.rejected, (state, action) => {
        state.pending = false;
        state.opportunity = null;
      })
      .addCase(submitInvestment.pending, (state, action) => {
        state.investPending = true;
      })
      .addCase(submitInvestment.fulfilled, (state, action) => {
        state.investPending = false;
      })
      .addCase(submitInvestment.rejected, (state, action) => {
        state.investPending = false;
      })
      .addCase(investNow.pending, (state, action) => {
        state.investNowPending = true;
      })
      .addCase(investNow.fulfilled, (state, action) => {
        state.investNowPending = false;
        state.interestDetails = action.payload;
      })
      .addCase(investNow.rejected, (state, action) => {
        state.investNowPending = false;
      })
      .addCase(getInvestment.pending, (state, action) => {
        state.getInvestmentPending = true;
      })
      .addCase(getInvestment.fulfilled, (state, action) => {
        state.getInvestmentPending = false;
      })
      .addCase(getInvestment.rejected, (state, action) => {
        state.getInvestmentPending = false;
      })
      .addCase(getREInvestments.pending, (state, action) => {
        state.pending = false;
        state.REInvestments = null;
      })
      .addCase(getREInvestments.fulfilled, (state, action) => {
        state.pending = false;
        state.REInvestments = action.payload;
      })
      .addCase(getREInvestments.rejected, (state, action) => {
        state.pending = false;
      })
      .addCase(submitInterest.pending, (state, action) => {
        state.submitInterestPending = true;
      })
      .addCase(submitInterest.fulfilled, (state, action) => {
        state.submitInterestPending = false;
        state.interestDetails = action.payload;
      })
      .addCase(submitInterest.rejected, (state, action) => {
        state.submitInterestPending = false;
      });
  },
});
export const { updateOpportunities } = opportunitiesSlice.actions;

export default opportunitiesSlice.reducer;

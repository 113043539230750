import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import mixpanel from "mixpanel-browser";
import { API_UNLOCK_ACCOUNT } from "../../../config/api";
import { toast } from "react-toastify";

export const unlockAccount = createAsyncThunk(
  "onboarding/unlockAccount",
  async ({ payload, cb, setSubmitting }, { dispatch }) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: API_UNLOCK_ACCOUNT,
        data: payload,
      });
      // toast.success("Account unlocked successfully.");
      cb();
      mixpanel.track("Success", {
        type: "Profile",
        subtype: "update-PIN",
      });
      return data;
    } catch (error) {
      setSubmitting(false);
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      mixpanel.track("Error", {
        type: "Profile",
        subtype: "update-PIN",
        message: msg,
      });
    }
  }
);

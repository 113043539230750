import React, { useEffect, useMemo, useState } from "react";
import close from "../Assets/Images/close.png";
import wallet from "../Assets/Images/wallet2.png";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "../utils/formatAmount";
import CustomInput from "./Inputs/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getBalance } from "../store/actions/dashboard/manageFunds";
import { MdInfo } from "react-icons/md";

const SavingAmountModal = ({ handleClose, handleNext, values }) => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(0.0);
  const dispatch = useDispatch();
  const [expectedInterest, setExpectedInterest] = useState(0);
  const user = useSelector((state) => state.onboarding?.user);
  const savingGoal = values;
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);

  //   const walletBalance = useSelector(
  //     (state) => state?.dashboard?.halvestBalance?.data?.walletBalance
  //   );
  //   const dollarBalance = useSelector(
  //     (state) => state?.dashboard?.halvestBalance?.data?.dollarBalance
  //   );
  //   const _currency = savingGoal?.currency === "USD" ? "$" : "₦";
  //   const balance = _currency === "$" ? dollarBalance : walletBalance;

  //   const formattedWalletBalance = new Intl.NumberFormat("en-NG", {
  //     style: "currency",
  //     currency: "NGN",
  //   }).format(walletBalance || 0);

  //   const usdFormatter = new Intl.NumberFormat("en-US", {
  //     style: "currency",
  //     currency: "USD",
  //   }).format(dollarBalance || 0);

  //   const adminFee = useMemo(() => {
  //     return (amount * +savingGoal?.admin_fee) / 100;
  //   }, [savingGoal, amount]);

  //   const calculatedAmount = useMemo(() => {
  //     return adminFee + +amount;
  //   }, [amount, adminFee]);

  //   useEffect(() => {
  //     if (amount > -1) {
  //       let total = (savingGoal?.interest_rate * amount) / 100;
  //       setExpectedInterest(total);
  //     }
  //   }, [amount, savingGoal?.interest_rate]);

  //   const openConfirmModal = (amount) => {
  //     if (amount > Number(savingGoal?.remaining_amount)) {
  //       return toast.error(
  //         "Sorry! The amount you wish to save exceeds the available goal."
  //       );
  //     }
  //     if (
  //       !walletBalance ||
  //       !dollarBalance ||
  //       calculatedAmount > Number(balance)
  //     ) {
  //       return toast.error("Insufficient balance.");
  //     }

  //     if (!user?.hasSetPin) {
  //       setIsPinModalOpen(true);
  //     } else if (amount < savingGoal?.minimum_amount) {
  //       toast.error(
  //         `The minimum saving amount (${_currency}${formatAmount(
  //           savingGoal?.minimum_amount,
  //           0
  //         )}) is not met by the amount of (${_currency}${formatAmount(
  //           amount,
  //           0
  //         )}) that you wish to save.`
  //       );
  //     } else {
  //       handleNext({
  //         amount,
  //         adminFee,
  //         id: values?.id,
  //         name: values?.name,
  //         calculatedAmount,
  //         savingGoal: values,
  //       });
  //     }
  //   };

  //   useEffect(() => {
  //     dispatch(getBalance());
  //   });

  return (
    <div className="flex flex-col justify-center items-center border-[1px] border-[#F0F0F5] w-[90%] sm:w-[553px] mt-[30px] mx-auto mb-[30px] h-[380px] rounded-[14px] shadow-xl relative bg-white">
      <div className="absolute top-5 right-6 cursor-pointer">
        <img
          src={close}
          alt="Close"
          className="cursor-pointer w-6 h-6"
          onClick={handleClose}
        />
      </div>
      <div className="flex flex-col justify-center items-center w-full h-full">
        <h2 className="text-[16px] text-[#131E3D] font-[400] mb-4">
          Amount to Save
        </h2>

        <CustomInput
          fullWidth
          noBorder
          bg={true}
          autoComplete={"off"}
          height={81}
          autoFocus={true}
          placeholder={``}
          customTextStyle={{}}
          textInputCustomStyles={{
            textAlign: "center",
            fontSize: "64px",
            color: "#131e3d",
          }}
          variant={"standard"}
          selfValue={amount}
          customType="amount"
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />

        <div className=" mb-3 flex items-center gap-[5px] "></div>

        <div className="flex mb-5 gap-[5px] items-center">
          <img src={wallet} alt="" className="w-[14px] h-[14px]" />
          <div className="text-[12px] font-[400] text-[#9c9c9e]">
            Wallet Balance:{"0.00 "}
          </div>
        </div>
        <button
          onClick={handleNext}
          className="w-[203px] h-[45px] rounded-[8px] bg-[#6935D3] shadow-sm justify-center items-center flex font-[500] text-[16px] text-white"
        >
          Continue
        </button>
        <div className=" mt-[30px] flex justify-center items-center gap-[5px] ">
          <MdInfo color="#9c9c9e" />
          <div className=" text-[12px]   text-[#9c9c9e] ">
            30 days minimum holding period in place
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavingAmountModal;

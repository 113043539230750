export const countdown = (rawDate) => {
  const date = new Date(rawDate).getTime();
  const now = new Date().getTime();
  const difference = now - date;

  return {
    difference,
    min: addzero(0 - Math.floor(difference / 1000 / 60)),
    sec: addzero(60 - Math.floor((difference / 1000) % 60)),
  };
};

const addzero = (number) => {
  return number < 10 ? `0${number}` : number;
};

import React from "react";
import Loader from "../Utils/Loader";

const MainButton = ({
  mainClasses,
  title,

  height,
  bg,
  color,
  leftIcon,
  rightIcon,
  textStyles,
  onClick,
  loading,
  disabled,
}) => {
  return (
    <button
      onClick={onClick}
      className={`bg-[#6935D3] rounded-[8px] mt-10 mb-[15px] font-[500] w-full lg:w-[493px] h-[45px] text-white py-2 px-4 focus:outline-none focus:shadow-outline shadow-custom ${mainClasses} ${
        disabled ? "opacity-55" : ""
      }`}
      type="button"
    >
      {loading ? <Loader size={"small"} /> : title}
    </button>
  );
};

export default MainButton;

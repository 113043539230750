import React, { useState } from "react";
import close from "../Assets/Images/close.png";
import { LiaAngleLeftSolid } from "react-icons/lia";

const SavingDateModal = ({ handleClose, handleBack, handleNext }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    { label: "30 Days", value: "30" },
    { label: "3 Months", value: "90" },
    { label: "6 Months", value: "180" },
    { label: "1 Year", value: "365" },
    { label: "Pick a date", value: "custom" },
  ];

  return (
    <div className="flex flex-col justify-center items-center border-[1px] border-[#F0F0F5] w-[90%] sm:w-[553px] mt-[30px] mx-auto mb-[30px] h-auto rounded-[14px] shadow-xl relative bg-white">
      <div className="absolute p-3 top-5 right-6 cursor-pointer">
        <img
          src={close}
          alt="Close"
          className="cursor-pointer w-6 h-6"
          onClick={handleClose}
        />
      </div>

      <div className="flex justify-between items-center w-full px-8 mt-[30px]">
        <button
          onClick={handleBack}
          className="flex items-center gap-2 text-[#9c9c9e] text-[14px] font-[400]"
        >
          <LiaAngleLeftSolid color="#9c9c9e" />
          Back
        </button>

        <h2 className="text-[16px] text-[#131E3D] font-[400] text-center">
          Choose maturity date
        </h2>
        <div className="w-[40px]" />
      </div>

      <div className="mt-[20px] text-[#4CAF50] text-[64px] ">15.0%</div>
      <div className=" text-[14px] font-[400] text-[#9c9c9e] ">
        Interest rate
      </div>

      <hr className="w-[450px] h-[1px] mx-auto border-[1px] border-[#F0F0F5] bg-[#9c9c9e] my-4" />

      <div className="flex justify-center items-center flex-wrap  gap-4 px-8 py-4 w-full">
        {options.map((option) => (
          <div
            key={option.value}
            onClick={() => {
              setSelectedOption(option.value);
            }}
            className={`cursor-pointer w-[121px] h-[60px] flex items-center justify-center border-[1px] rounded-[14px] 
                ${
                  selectedOption === option.value
                    ? "border-[#6935D3] text-[#131E3D]"
                    : "border-[#E0E0E0] text-[#131e3d]"
                } font-[500] text-[16px]`}
          >
            {option.label}
          </div>
        ))}
      </div>

      {selectedOption === "custom" && (
        <div className="w-full px-8 mt-4">
          <label className="text-[14px] font-[400] text-[#131E3D]">
            Pick a date
          </label>
          <input
            type="date"
            className="w-full border-[1px] border-[#E0E0E0] rounded-[8px] h-[45px] px-3 mt-2"
            onChange={(e) => {}}
          />
        </div>
      )}

      <div className="w-full px-8 mt-8 mb-4">
        <button
          onClick={handleNext}
          className="w-[250px] mb-[30px] h-[45px] mx-auto bg-[#6935D3] rounded-[8px] text-white font-[500] text-[16px] flex justify-center items-center"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default SavingDateModal;

import React, { useEffect, useState } from "react";
import Switcher from "./Switcher";
import over from "../Assets/Images/overview.png";
import { useDispatch, useSelector } from "react-redux";
import { getBalance } from "../store/actions/dashboard/manageFunds";
import CustomAreaChart from "./Chart/Line";
import { getMetrics } from "../store/actions/dashboard/getMetrics";
import { formatAmount } from "../utils/formatAmount";
import DashboardSwitcher from "./DashboardSwitcher";
import { dashboardDropdownOptions } from "../utils/data";
import CustomTooltip from "./containers/ToolTip";

const Overview = ({ currency, setCurrency }) => {
  const dispatch = useDispatch();

  const [currentPerfomanceRange, setCurrentPerfomanceRange] = useState(
    dashboardDropdownOptions[3].value
  );

  const balances = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data
  );

  const portfolio_value_usd = balances?.portfolio_value_usd;
  const portfolio_value_ngr = balances?.portfolio_value_ngr;

  const investmentMetrics = useSelector(
    (state) => state?.dashboard?.investmentMetrics
  );

  const formattedWalletBalance = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(portfolio_value_ngr || 0);

  const usdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(portfolio_value_usd || 0);

  useEffect(() => {
    dispatch(getMetrics(currency === "Naira" ? "NGR" : "USD"));
  }, [currency]);
  useEffect(() => {
    dispatch(getBalance());
  }, []);
  const { user, profile, pending } = useSelector((state) => state.onboarding);

  return (
    <div className=" p-3 w-full max-w-[698px] min-h-[390px] rounded-[12px] border-[1px] bg-[#F9FAFA] border-[#F2F2F2] ">
      <div className=" p-6 flex justify-between items-start ">
        <div>
          <div className="flex gap-[2px] items-center ">
            <h4 className=" text-[14px] font-[400] text-[#9C9C9E] ">
              Portfolio Value
            </h4>
            <CustomTooltip
              style={{
                height: "16px",
                width: "16px",

                marginTop: "-4px",
              }}
              message={
                "Reflects the total value of all investments, including the initial investment amount and any returns."
              }
            >
              <span className="cursor-pointer text-[14px] font-[400] text-[#6935D3] ">
                ?
              </span>
            </CustomTooltip>
          </div>
          <div className=" text-[32px] font-[500] text-[#131E3D] ">
            {currency === "Naira"
              ? formattedWalletBalance?.split(".")[0]
              : usdFormatter?.split(".")[0]}
            <span className=" text-[#9C9C9E] text-[16px] font-[500] ">
              .
              {currency === "Naira"
                ? formattedWalletBalance?.split(".")[1]
                : usdFormatter?.split(".")[1]}
            </span>
          </div>
          <div className=" text-[14px] min-h-[18px] font-[400] text-[#2CB171] ">
            {/* {`+${currency === "Naira" ? "₦" : "$"}${
              investmentMetrics
                ?.filter(
                  (item) =>
                    (new Date() - new Date(item?.date)) / 1000 / 60 / 60 / 24 <=
                    currentPerfomanceRange
                )
                ?.reduce((acc, curr) => {
                  return acc + curr?.expected_amount;
                }, 0)
                ? formatAmount(
                    investmentMetrics
                      ?.filter(
                        (item) =>
                          (new Date() - new Date(item?.date)) /
                            1000 /
                            60 /
                            60 /
                            24 <=
                          currentPerfomanceRange
                      )
                      ?.reduce((acc, curr) => {
                        return acc + curr?.expected_amount;
                      }, 0),
                    2
                  )
                : "0.00"
            } expected in returns`} */}
          </div>
        </div>
        <div className="">
          <Switcher handleCurrencyChange={setCurrency} />
        </div>
      </div>
      {user?.hasSetPin && profile?.nok_email && profile?.gender ? (
        <>
          <div className="  mt-[20px] pl-6 pr-0 h-[200px]">
            <CustomAreaChart currentPerfomanceRange={currentPerfomanceRange} />
          </div>
          <div className="px-5  gap-1 cursor-pointer flex py-3 items-center">
            <DashboardSwitcher
              handleCurrencyChange={setCurrentPerfomanceRange}
              options={dashboardDropdownOptions}
            />
          </div>
        </>
      ) : (
        <>
          <div className=" flex  justify-center mt-[20px] ">
            <img src={over} alt="" className=" w-[91px] h-[91px] " />
          </div>
          <div className=" mt-[75px]  px-5 items-end flex justify-start text-[17px] text-[#9C9C9E] font-[400]  ">
            Make your first investment to see some growth
          </div>
        </>
      )}
    </div>
  );
};

export default Overview;

import React from "react";
import duration from "../Assets/Images/duration.png";
import calendar from "../Assets/Images/calendar.png";
import returns from "../Assets/Images/returns.png";
import profit from "../Assets/Images/profit.png";
import pace from "../Assets/Images/pace.png";
import { timeformatter } from "../utils/dateformatter";
import { formatAmount } from "../utils/formatAmount";
import { CiMemoPad } from "react-icons/ci";
import { useSelector } from "react-redux";

const CampaignCard = ({
  title,
  dueDate,
  noButton,
  tenure,
  investment_memo,
  payoutType,
  nReturn,
  onClick,
  invested,
  totalAmount,
  currency,
  showTimelines,
  investors,
}) => {
  const { user } = useSelector((state) => state.onboarding);

  const percentageInvested =
    (invested / totalAmount) * 100 < 100
      ? ((invested / totalAmount) * 100).toFixed(1)
      : (invested / totalAmount) * 100;
  return (
    <div className="mb-[20px] py-5 px-5 w-full max-w-[512px] min-h-[264px] rounded-[14px] border-[1px] shadow-sm border-[#F0F0F5]">
      <div className="text-[16px] font-[500] text-[#131E3D]">{title}</div>
      <div className="mt-[10px]">
        <div className="flex gap-[10px] items-center">
          <div className="  gap-[5px] flex items-center">
            <img src={duration} alt="" className=" w-[14px] h-[14px] " />
            <div className="text-[12px] font-[400] text-[#9C9C9E]">
              Campaign Due {timeformatter(dueDate, "formal", false, true)}
            </div>
          </div>

          <div className="flex gap-[5px] items-center">
            <img src={calendar} alt="" className=" w-[14px] h-[14px] " />
            <div className="text-[12px] font-[400] text-[#9C9C9E]">
              {tenure?.includes("week")
                ? tenure
                : `${tenure} month${Number(tenure) === 1 ? "" : "s"}`}{" "}
              Tenure
            </div>
          </div>

          <div className="flex gap-[5px] items-center">
            <img src={returns} alt="" className=" w-[14px] h-[14px] " />
            <div className="text-[12px] font-[400] text-[#20A43D]">
              {nReturn}% Net Return
            </div>
          </div>
        </div>
        <div className="flex gap-[5px] items-center mt-2">
          <img src={profit} alt="" className="w-[14px] h-[14px] " />
          <div className="text-[12px] capitalize font-[400] text-[#9C9C9E]">
            {payoutType}
          </div>
        </div>
      </div>
      <div className="mt-[40px]">
        <div className="text-[14px] text-[#131E3D] font-[400]">
          {percentageInvested ? percentageInvested : "0.0"}% invested of{" "}
          {currency}
          {formatAmount(totalAmount)}
        </div>
        <div className="w-full bg-[#F2F2F7] h-[4px] overflow-hidden rounded-full mt-2">
          <div
            className="bg-[#20A43D] h-full "
            style={{ width: `${percentageInvested}%` }}
          ></div>
        </div>
      </div>
      <div className=" flex-wrap gap-2 flex justify-between items-center mt-[30px]">
        {noButton ? (
          <></>
        ) : (
          <button
            onClick={onClick}
            className=" text-[14px] font-[500] flex items-center bg-[#6935D3] text-white py-2 px-4 rounded-[8px]"
          >
            {investors &&
            investors?.find(
              (investor) =>
                investor?.user_id === user?.id ||
                investor?.user?.id === user?.id
            )
              ? "Add More Funds"
              : "Invest now"}
          </button>
        )}
        <div
          className={`flex gap-[5px]  items-center ${
            investment_memo
              ? "cursor-pointer"
              : "cursor-not-allowed opacity-[0.7]"
          }`}
        >
          <CiMemoPad className="text-[#6935D3] " />
          <a
            rel="noreferrer"
            href={investment_memo ? investment_memo : null}
            download={`${title} Documents`}
            target="_blank"
          >
            <div className={`text-[14px] font-[400] text-[#6935D3] `}>
              Read Memo
            </div>
          </a>
        </div>

        <div
          onClick={showTimelines}
          className=" flex gap-[5px] cursor-pointer items-center"
        >
          <img src={pace} alt="" className=" w-[14px] h-[14px] " />
          <div className=" text-[14px] font-[400] text-[#6935D3] ">
            Campaign Timeline
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignCard;

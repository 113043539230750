import React, { useEffect, useState } from "react";
import close from "../Assets/Images/close.png";
import TextInput from "./Inputs/TextInput";
import { formHandler } from "../utils/formhandler";
import { useFormik } from "formik";
import SelectField from "./Inputs/SelectField";
import {
  securityQuestionSchema,
  unlockAccountSchema,
} from "../helper/validationSchemas";
import { _securityQuestions } from "../utils/data";
import MainButton from "./Buttons/MainButton";
import { useDispatch, useSelector } from "react-redux";
import { unlockAccount } from "../store/actions/onboarding/unlockAccount";
import { getQuestion } from "../store/actions/onboarding/getQuestion";
import Loader from "./Utils/Loader";

const SecurityQuestionModal = ({
  returningValues,
  next,
  email,
  handleSuccess,
  type,
  handleClose,
  goBackMsg,
}) => {
  const { user } = useSelector((state) => state.onboarding);
  const [securityQuestions, setSecurityQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const form = useFormik({
    initialValues: {
      // q: {
      //   value: "h",
      //   label: "h",
      // },
      q1: returningValues?.q1 || null,
      q2: returningValues?.q2 || null,
    },
    onSubmit: (values, { setSubmitting }) => {
      if (type === "reset-login") {
        dispatch(
          unlockAccount({
            payload: {
              email,
              question: values?.q?.value,
              answer: values?.answer,
            },
            cb: () => {
              handleSuccess();
              setSubmitting(false);
            },
            setSubmitting,
          })
        );
      } else {
        next(values);
        handleClose();
      }
    },
    validationSchema:
      type === "reset-login" ? unlockAccountSchema : securityQuestionSchema,

    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const random = Math.floor(Math.random() * 2);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getQuestion({
        data: { email: email || user?.email },
        cb: (data) => {
          setSecurityQuestions(
            data?.questions?.map(({ question: i }) => ({ value: i, label: i }))
          );

          setLoading(false);
        },
        failed: () => {
          if (goBackMsg) goBackMsg();
          setLoading(false);
        },
      })
    );
  }, []);

  return (
    <div className="relative mx-auto w-full lg:w-[553px] flex flex-col  max-h-[80vh] rounded-[14px] border-[1.2px] bg-[#FFF] border-[#f0f0f5] drop-shadow-custom">
      <div
        onClick={handleClose}
        className="absolute top-3 right-3 cursor-pointer px-3 py-2"
      >
        <img src={close} alt="Close" className="w-6 h-6 " />
      </div>
      <div className="p-4 lg:p-8 pb-6">
        {loading ? (
          <></>
        ) : (
          <div className="text-[16px] text-center font-[500] text-[#131e3d] mb-4">
            {type === "reset-login"
              ? ""
              : user?.hasSecurityQuestion
              ? "Change Security Questions"
              : "Set Security Questions"}
          </div>
        )}
      </div>

      <div className="px-8 w-full  flex-1 overflow-y-scroll flex flex-col gap-8">
        {loading ? (
          <div className="min-h-20">
            <Loader />
          </div>
        ) : type === "reset-login" && random === 1 ? (
          <div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col">
                <label
                  htmlFor="question"
                  className="text-[14px] font-[400] text-[#9c9c9e] mb-[5px]"
                >
                  Security Question
                </label>
                <div className="w-full ">
                  <SelectField
                    id="q"
                    form={form}
                    options={securityQuestions?.filter(
                      (question) => question?.value !== form?.values?.q2?.value
                    )}
                    sx={{
                      width: "100%",
                      height: "50px",
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="answer"
                  className="text-[14px] font-[400] text-[#9c9c9e] mb-[5px]"
                >
                  Answer
                </label>
                <TextInput
                  {...formHandler("answer", form)}
                  mainClasses={"mb-0 mt-0 !w-full max-w-[489px] "}
                />
              </div>
            </div>
          </div>
        ) : type === "reset-login" && random === 0 ? (
          <div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col">
                <label
                  htmlFor="question"
                  className="text-[14px] font-[400] text-[#9c9c9e] mb-[5px]"
                >
                  Security Question
                </label>
                <div className="w-full ">
                  <SelectField
                    id="q"
                    form={form}
                    options={securityQuestions?.filter(
                      (question) => question?.value !== form?.values?.q2?.value
                    )}
                    sx={{
                      width: "100%",
                      height: "50px",
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="answer"
                  className="text-[14px] font-[400] text-[#9c9c9e] mb-[5px]"
                >
                  Answer
                </label>
                <TextInput
                  {...formHandler("answer", form)}
                  mainClasses={"mb-0 mt-0 !w-full max-w-[489px] "}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div>
              <div className="text-[16px] font-[500] text-[#131e3d] mb-[20px]">
                First Question
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="question1"
                    className="text-[14px] font-[400] text-[#9c9c9e] mb-[5px]"
                  >
                    Choose Question 1
                  </label>
                  <div className="w-full ">
                    <SelectField
                      id="q1"
                      form={form}
                      options={
                        user?.hasSecurityQuestion
                          ? securityQuestions?.filter(
                              (question) =>
                                question?.value !== form?.values?.q2?.value
                            )
                          : _securityQuestions
                      }
                      sx={{
                        width: "100%",
                        height: "50px",
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-col">
                  <label
                    htmlFor="answer1"
                    className="text-[14px] font-[400] text-[#9c9c9e] mb-[5px]"
                  >
                    Answer
                  </label>
                  <TextInput
                    {...formHandler("answer1", form)}
                    mainClasses={"mb-0 mt-0 !w-full max-w-[489px] "}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="text-[16px] font-[500] text-[#131e3d] mb-[20px]">
                Second Question
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="question2"
                    className="text-[14px] font-[400] text-[#9c9c9e] mb-[5px]"
                  >
                    Choose Question 2
                  </label>
                  <div className="w-full ">
                    <SelectField
                      id="q2"
                      form={form}
                      options={
                        user?.hasSecurityQuestion
                          ? securityQuestions?.filter(
                              (question) =>
                                question?.value !== form?.values?.q1?.value
                            )
                          : _securityQuestions?.filter(
                              (question) =>
                                question?.value !== form?.values?.q1?.value
                            )
                      }
                      sx={{ width: "100%", height: "50px" }}
                    />
                  </div>
                </div>

                <div className="flex flex-col">
                  <label
                    htmlFor="answer2"
                    className="text-[14px] font-[400] text-[#9c9c9e] mb-[5px]"
                  >
                    Answer
                  </label>
                  <TextInput
                    {...formHandler("answer2", form)}
                    mainClasses={"mb-0 mt-0 !w-full max-w-[489px] "}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {loading ? (
          <></>
        ) : (
          <div>
            <MainButton
              mainClasses="!md:w-[489px] !my-0 !mb-[30px] !mt-[10px] text-white"
              title={type === "reset-login" ? "Submit" : "Confirm with PIN"}
              onClick={form.handleSubmit}
              loading={form.isSubmitting}
              disabled={form.isSubmitting}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SecurityQuestionModal;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate, useLocation } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useFormik } from "formik";
import { loginValidationSchema } from "../helper/validationSchemas";
import TextInput from "./Inputs/TextInput";
import { formHandler } from "../utils/formhandler";
import { login } from "../store/actions/onboarding/login";
import AuthButton from "./Buttons/AuthButton";

const LoginForm = ({ userLocked }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const handleLogin = (values, { setSubmitting }) => {
    const data = {
      ...values,
    };
    dispatch(
      login({
        navigate,
        cb: (msg, email) => {
          if (msg) {
            userLocked(msg, email);
          }
          setSubmitting(false);
        },
        data,
        setSubmitting,
      })
    );
  };

  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: handleLogin,
    validationSchema: loginValidationSchema,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const handleForgotPassword = () => {
    navigate("/forgot-password", { state: { email: form?.values?.email } });
  };

  return (
    <div className=" flex flex-col mt-[3.5%] justify-center  p-4 items-center">
      <h2 className="font-[500] text-center text-[#131E3D] tracking-[-3.1%] text-[50px] leading-[50px] mb-2 lg:text-[71px] lg:leading-[79.2px]  ">
        Good to have <br /> you back
      </h2>
      <div className="flex w-full drop-shadow-[0_10px_14px_0px_rgba(0,0,0,0.25)] justify-center mt-[2.5%]  items-center ">
        <div className="w-full lg:w-[493px] py-8 px-8 bg-[#FFFFFF] border-[#F2F2F2] rounded-[12px] border-[1.1px] drop-shadow-custom shadow-custom">
          <div className="mb-6">
            <label
              className="block tracking-wide text-[#9C9C9E] text-[14px] font-[400] mb-2"
              htmlFor="phone-number"
            >
              Email
            </label>
            <TextInput
              // title={"Email address"}
              {...formHandler("email", form)}
              type={"email"}
              mainClasses={"mb-0 mt-0 w-full md:w-[429px] "}
            />
          </div>
          <div className="mb-3">
            <label
              className="block tracking-wide text-[#9C9C9E] text-[14px] font-[400] mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <TextInput
              // title={"Email address"}
              {...formHandler("password", form)}
              type={"password"}
              mainClasses={"mb-0 mt-0 w-full md:w-[429px]"}
            />
          </div>
          <h4
            onClick={() => handleForgotPassword()}
            className="font-[400] cursor-pointer text-[14px] text-[#6935D3] leading-[16.8px]"
          >
            Forgot Password?
          </h4>
        </div>
      </div>

      <AuthButton
        title={"Submit"}
        onClick={form.handleSubmit}
        loading={form.isSubmitting}
        disabled={form.isSubmitting}
        // mainClasses={" hover:text-[red] hover:border-[#192851] "}
        // textStyles={"group-hover:text-[#192851]"}
      />
      <h4 className="font-[500] text-center mt-3 text-[16px] text-[#9C9C9E] leading-[16.8px]">
        New to Halvest?{" "}
        <span
          onClick={() => {
            navigate("/nationality");
          }}
          className="font-[500] cursor-pointer text-center text-[16px] text-[#6935D3] leading-[16.8px]"
        >
          {" "}
          Sign Up{" "}
        </span>
      </h4>
    </div>
  );
};

export default LoginForm;

import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  API_GET_INTEREST,
  API_GET_REALESTATE_INVESTMENT,
} from "../../../config/api";
// import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";

export const getREInvestments = createAsyncThunk(
  "getREInvestments/GET",
  async () => {
    try {
      const { data } = await Client({
        method: "GET",
        path: API_GET_REALESTATE_INVESTMENT,
      });
      return data?.data;
    } catch (error) {
      // const msg = proccessErrorMessage(error);
    }
  }
);

import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import { API_GET_ACCOUNT_NAME } from "../../../config/api";
import { showErrorModal } from "../../reducers/messageSlice";
import { toast } from "react-toastify";

export const getAccountName = createAsyncThunk(
  "users/getAccountName",
  async (
    { payload, cb, setgettingAccountName, deleteLastDegit },
    { dispatch }
  ) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: API_GET_ACCOUNT_NAME,
        data: payload,
      });

      if (data?.status === true || data?.status === "success") {
        cb(data?.data?.account_name);
      } else {
        toast.error(data?.message || "An error has occurred");

        deleteLastDegit();
        setgettingAccountName(false);
      }

      // return response?.data;
    } catch (error) {
      setgettingAccountName(false);
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      deleteLastDegit();

      throw error;
    }
  }
);

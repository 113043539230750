import { createSlice } from "@reduxjs/toolkit";
import { getRate } from "../actions/wallet/getRate";

export const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    error: null,
    withdrawalAccount: null,
    withdrawalAmount: 0.0,
    conversionRate: null,
    pending: false,
    success: false,
  },

  reducers: {
    setConversionRate: (state, action) => {
      state.conversionRate = action.payload;
    },
    saveWithdrawalAmount: (state, action) => {
      state.withdrawalAmount = action.payload;
    },
    saveWithdrawalAccount: (state, action) => {
      state.withdrawalAccount = action.payload;
    },
    resetwithdrawal: (state, action) => {
      state.withdrawalAmount = 0.0;
      state.withdrawalAccount = null;
    },
    updateCreditCards: (state, action) => {
      state.savedCreditCards = action.payload;
    },

    saveChathandle: (state, action) => {
      state.tawkMessengerRef = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getRate.pending, (state, action) => {
      state.conversionRate = null;
    });
    builder.addCase(getRate.fulfilled, (state, action) => {
      state.conversionRate = action.payload;
    });
    builder.addCase(getRate.rejected, (state, action) => {
      state.conversionRate = null;
    });
  },
});

export const {
  saveWithdrawalAmount,
  setConversionRate,
  resetwithdrawal,
  saveWithdrawalAccount,
} = walletSlice.actions;
export default walletSlice.reducer;

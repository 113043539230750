import React from "react";
import { useDispatch } from "react-redux";

import { useNavigate, useLocation } from "react-router-dom";

import { useFormik } from "formik";
import { forgotValidationSchema } from "../helper/validationSchemas";
import TextInput from "./Inputs/TextInput";
import { formHandler } from "../utils/formhandler";

import AuthButton from "./Buttons/AuthButton";
import { forgotPassword } from "../store/actions/onboarding/forgotPassword";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const email = state?.email;

  const handlSubmit = (values, { setSubmitting }) => {
    dispatch(
      forgotPassword({
        data: values,
        navigate,
        cb: () => {
          setSubmitting(false);
        },
      })
    );
  };

  const form = useFormik({
    initialValues: {
      email: email || "",
    },
    onSubmit: handlSubmit,
    validationSchema: forgotValidationSchema,
  });

  return (
    <div className=" flex flex-col px-4 mt-[3.5%] justify-center items-center">
      <h2 className="font-[500] text-center mb-4 text-[#131E3D] tracking-[-3.1%] text-[71px] leading-[79.2px]  ">
        Reset your
        <br />
        password
      </h2>
      <div className="w-full flex drop-shadow-[0_10px_14px_0px_rgba(0,0,0,0.25)] justify-center mt-[2.5%]  items-center ">
        <div className="w-full lg:w-[493px] py-8 px-6 bg-[#FFFFFF] border-[#F2F2F2] border-[1.1px] drop-shadow-custom shadow-custom">
          <div className="mb-6">
            <label
              className="block tracking-wide text-[#9C9C9E] text-[14px] font-[400] mb-2"
              htmlFor="phone-number"
            >
              Email
            </label>
            <TextInput
              // title={"Email address"}
              {...formHandler("email", form)}
              type={"email"}
              mainClasses={"mb-0 mt-0 w-full md:w-[429px] "}
            />
          </div>
        </div>
      </div>

      <AuthButton
        title={"Send Reset Email"}
        onClick={form.handleSubmit}
        loading={form.isSubmitting}
        disabled={form.isSubmitting}
        // mainClasses={" hover:text-[red] hover:border-[#192851] "}
        // textStyles={"group-hover:text-[#192851]"}
      />
    </div>
  );
};

export default ForgotPasswordForm;

import React, { useEffect, useState } from "react";
import TextInput from "./Inputs/TextInput";
import { formHandler } from "../utils/formhandler";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { oldUsersSchema, setPinSchema } from "../helper/validationSchemas";
import { Country, State } from "country-state-city";
import MainButton from "./Buttons/MainButton";
import OTPInput from "react-otp-input";
import close from "../Assets/Images/close.png";
import id from "../Assets/Icons/id.png";
import country from "../Assets/Icons/country.png";
import res from "../Assets/Icons/res.png";
import SelectField from "./Inputs/SelectField";
import { countries } from "../utils/data";
import { LiaAngleLeftSolid } from "react-icons/lia";
import { updateUserProfile } from "../store/actions/profile/updateProfile";
import { useNavigate } from "react-router-dom";
import IdVerified from "./IdVerified";
import VerifyId from "./VerifyId";
import InProgress from "./InProgress";

const UpdateIdOldUsers = ({ next, handleClose, small, user, profile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const handleSubmit = (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append(
      "country_of_residence",
      values?.country_of_residence?.value
    );

    formData.append("address", form.values?.address);

    formData.append("state", form.values.state?.value || form.values.state);

    formData.append("postal_code", form.values.postal_code);

    dispatch(
      updateUserProfile({
        data: formData,
        navigate,
        formValues: values,
        from: "settings",
        cb: () => {
          setSubmitting(false);
          setStep(3);
        },
        setSubmitting,
      })
    );
  };

  const form = useFormik({
    initialValues: {
      state: { label: profile?.state, value: profile?.state },
      address: profile?.address || "",

      postal_code: profile?.postal_code || "",

      country_of_residence: profile?.country_of_residence
        ? {
            label: profile?.country_of_residence,
            value: profile?.country_of_residence,
          }
        : user?.country_of_residence
        ? {
            label: user?.country_of_residence,
            value: user?.country_of_residence,
            code: Country?.getAllCountries()?.find(
              (country) => country?.name === user?.country_of_residence
            )?.isoCode,
          }
        : { label: "Nigeria", value: "Nigeria" },
    },
    onSubmit: handleSubmit,
    validationSchema: oldUsersSchema,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  useEffect(() => {
    form?.setValues({
      state: { label: profile?.state, value: profile?.state },
      address: profile?.address || "",

      postal_code: profile?.postal_code || "",

      country_of_residence: profile?.country_of_residence
        ? {
            label: profile?.country_of_residence,
            value: profile?.country_of_residence,
          }
        : user?.country_of_residence
        ? {
            label: user?.country_of_residence,
            value: user?.country_of_residence,
            code: Country?.getAllCountries()?.find(
              (country) => country?.name === user?.country_of_residence
            )?.isoCode,
          }
        : { label: "Nigeria", value: "Nigeria" },
    });
  }, [user, profile]);

  return step === 5 ? (
    <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
      <IdVerified
        title={"ID Verified"}
        subTitle={"Your ID has been verified successfully"}
        handleClose={() => {
          handleClose();
        }}
      />
    </div>
  ) : step === 4 ? (
    <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
      <InProgress
        subText={"Your ID is currently being reviewed"}
        buttonTitle="Ok"
        text={"Undergoing Review"}
        handleClose={() => {
          handleClose();
        }}
        next={() => {
          handleClose();
        }}
      />
    </div>
  ) : step === 3 ? (
    <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
      <VerifyId
        handleClose={() => {
          handleClose();
        }}
        next={() => {
          setStep(4);
        }}
      />
    </div>
  ) : (
    <div
      className={`flex max-w-[95%] flex-col relative mx-auto my-6 pt-3 px-6 pb-6 max-h-[85%] md:w-[553px] min-h-[658px] rounded-[14px] border-[1.2px] bg-[#FFF] border-[#f0f0f5] drop-shadow-custom`}
    >
      <div className="  flex justify-between bg-[url(./Assets/Images/pattern-old-users.png)] bg-cover min-h-[100px] w-full cursor-pointer py-2 px-3">
        <div>
          {step === 2 ? (
            <div
              className="flex items-center gap-[3px] mt-5 ml-2 cursor-pointer"
              onClick={() => {
                setStep(1);
              }}
            >
              <LiaAngleLeftSolid size={18} color="#131E3D" className="mr-2" />
              <h3 className="text-[14px] font-[400] text-[#131E3D]">Back</h3>
            </div>
          ) : (
            <></>
          )}
        </div>
        <img
          onClick={handleClose}
          src={close}
          alt="Close"
          className="w-6 h-6 mt-5 mr-2 "
          //  className="w-6 h-6 mt-5 mr-2 hidden"
        />
      </div>

      <div className="flex flex-1 overflow-y-auto items-center flex-col">
        <h3 className="text-[36px] mt-1 font-[500] leading-[38.52px] text-[#131E3D] text-center">
          Welcome to the <br /> New Halvest App!
        </h3>
        {step === 2 ? (
          <></>
        ) : (
          <h5 className="text-[#9C9C9E] font-[400] text-[16px] mt-2 leading-[20.8px] text-center">
            To enhance your experience and ensure <br />
            compliance with regulations, we kindly ask you to:
          </h5>
        )}

        {step === 2 ? (
          <div className="border-[#F0F0F5] border-[1.2px] rounded-[14px] mx-auto my-4 p-5 w-[341px] flex flex-col items-center">
            <div className="mb-6 w-full">
              <label
                className="block tracking-wide text-[#9C9C9E] text-[14px] font-[400] mb-2"
                htmlFor="phone-number"
              >
                Country of residence
              </label>

              <div className="w-full ">
                <SelectField
                  id="country_of_residence"
                  form={form}
                  options={Country.getAllCountries()?.map((i) => ({
                    label: i?.name,
                    value: i?.name,
                    code: i?.isoCode,
                  }))}
                  sx={{ width: "100%", height: "50px" }}
                  clearFields={["state", "address", "postal_code"]}
                />
              </div>
            </div>
            <div className="mb-6 w-full">
              <label
                className="block tracking-wide text-[#9C9C9E] text-[14px] font-[400] mb-2"
                htmlFor="phone-number"
              >
                {form?.values?.["country_of_residence"]?.code === "CA"
                  ? "Provinces"
                  : "State"}
              </label>

              <div className="w-full ">
                <SelectField
                  id="state"
                  form={form}
                  options={State.getAllStates()
                    ?.filter(
                      (state) =>
                        state?.countryCode ===
                        form?.values?.["country_of_residence"]?.code
                    )
                    ?.map((i) => ({
                      label: i?.name,
                      value: i?.name,
                    }))}
                  clearFields={["address", "postal_code"]}
                  sx={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>
            <div className="mb-6 w-full">
              <label
                className="block tracking-wide text-[#9C9C9E] text-[14px] font-[400] mb-2"
                htmlFor="phone-number"
              >
                Residential address
              </label>

              <div className="w-full ">
                <TextInput
                  {...formHandler("address", form)}
                  type={"text"}
                  mainClasses={"mb-0 mt-0 !w-full  "}
                />
              </div>
            </div>
            <div className="mb-6 w-full">
              <label
                className="block tracking-wide text-[#9C9C9E] text-[14px] font-[400] mb-2"
                htmlFor="phone-number"
              >
                Postal Code
              </label>

              <div className="w-full ">
                <TextInput
                  {...formHandler("postal_code", form)}
                  type={"text"}
                  mainClasses={"mb-0 mt-0 !w-full  "}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="border-[#F0F0F5] border-[1.2px] rounded-[14px] mx-auto my-4 px-7 py-4 w-[341px] flex flex-col items-center">
            <div className="flex items-center py-4 gap-2 w-full border-b-[#F2F2F7] border-b-[1px] ">
              <img
                src={country}
                alt="Close"
                className="w-[38px] h-[38px]  mr-2"
              />
              <div>
                <h3 className="text-[#131E3D] font-[500] text-[14px] leading-[16.8px]">
                  Confirm your country of residence
                </h3>
                <h4 className="text-[#9C9C9E] font-[400] mt-1 text-[12px] leading-[14.4px] tracking-[-0.4px]">
                  This is to personlise your Halvest experienc according to your
                  region
                </h4>
              </div>
            </div>
            <div className="flex items-center py-4 gap-2 w-full border-b-[#F2F2F7] border-b-[1px] ">
              <img src={res} alt="Close" className="w-[38px] h-[38px]  mr-2" />
              <div>
                <h3 className="text-[#131E3D] font-[500] text-[14px] leading-[16.8px]">
                  Provide your residential address
                </h3>
                <h4 className="text-[#9C9C9E] font-[400] mt-1 text-[12px] leading-[14.4px] tracking-[-0.4px]">
                  Provide your current address for accuracy.
                </h4>
              </div>
            </div>
            <div className="flex items-center py-4 gap-2 w-full border-b-transparent border-b-[1px] ">
              <img src={id} alt="Close" className="w-[38px] h-[38px]  mr-2" />
              <div>
                <h3 className="text-[#131E3D] font-[500] text-[14px] leading-[16.8px]">
                  Reverify your ID
                </h3>
                <h4 className="text-[#9C9C9E] font-[400] mt-1 text-[12px] leading-[14.4px] tracking-[-0.4px]">
                  Ensure your identity is up-to-date.
                </h4>
              </div>
            </div>
          </div>
        )}
        {step === 2 ? (
          <></>
        ) : (
          <h5 className="text-[#9C9C9E] font-[400] text-[16px]  leading-[18.12px] text-center">
            This quick process helps us keep your
            <br />
            account secure and up-to-date.
          </h5>
        )}

        <MainButton
          mainClasses={`!w-[169px] ${
            step === 2 ? "!my-1" : "!my-4"
          }  text-white`}
          title={step === 2 ? "Proceed" : "Get started"}
          onClick={
            step === 2
              ? form.handleSubmit
              : () => {
                  setStep(2);
                }
          }
          loading={form.isSubmitting}
          disabled={form.isSubmitting}
        />
      </div>
    </div>
  );
};

export default UpdateIdOldUsers;

import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { LiaAngleLeftSolid } from "react-icons/lia";

import { useNavigate, useSearchParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

const VerifyID = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [params] = useSearchParams();
  const type = params.get("type");

  useEffect(() => {
    if (type !== "nin" && type !== "driver's license") {
      navigate(-1);
    }
  }, []);
  const { user, profile } = useSelector((state) => state.onboarding);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://dashboard.qoreid.com/qoreid-sdk/qoreid.js";
    script.async = true;

    document.body.appendChild(script);

    const button = document.getElementById("QoreIDButton");

    // setup callbacks
    button.addEventListener("qoreid:verificationSubmitted", ({ detail }) => {
      console.log("submitted payload", detail);
      localStorage.setItem("verificationStatus", "Pending-Notification");
      window.location = "/";
    });
    button.addEventListener("qoreid:verificationError", ({ detail }) => {
      console.log("error payload", detail);
      // localStorage.setItem("verificationStatus", "No-Response");
      // window.location = "/";
    });
    button.addEventListener("qoreid:verificationClosed", ({ detail }) => {
      console.log("closed payload", detail);
      // localStorage.setItem("verificationStatus", "No-Response");
      // window.location = "/";
    });

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className="flex flex-col bg-[#F9FAFA] gap-6 pt-3 pb-3 items-center min-h-screen">
        <Navbar type={3} />

        <div className="w-[95%] overflow-scroll h-full min-h-[80vh]  rounded-[12px] gap-5 p-5 md:p-10 bg-[#FFF] border-[#F2F2F2] border-[1.1px] drop-shadow-custom  max-w-[1664px] flex">
          <div className="flex flex-col flex-1">
            <div
              onClick={() => navigate(-1)}
              className="flex cursor-pointer items-center mb-4"
            >
              <LiaAngleLeftSolid
                size={18}
                color="#131E3D"
                className="mx-[2px]  "
              />
              <h3 className="text-[14px] font-[400] text-[#131E3D]">Back</h3>
            </div>
            <div
              // className={showVerifyMe ? "" : "hidden"}
              dangerouslySetInnerHTML={{
                __html: `
<qoreid-button
id="QoreIDButton"
clientId="OW0HQ0WN5C9CY0JI2OOO"
productCode="${type === "nin" ? "liveness_nin" : "liveness_drivers_license"}"
customerReference="${user?.id}"
applicantData='{"firstname": "${
                  profile?.first_name || user?.name?.split(" ")[1]
                }", "lastname": "${
                  profile?.last_name || user?.name?.split(" ")[2]
                }", "phone":   "${
                  user?.phone || profile?.phone || ""
                }", "email": "${user?.email || ""}"}'
></qoreid-button>
`,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyID;

import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  API_DELETE_NOTIFICATIONS,
  API_READ_NOTIFICATIONS,
} from "../../../config/api";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import mixpanel from "mixpanel-browser";
import { getAllNotifications } from "./getAllNotifications";

export const deleteNotifications = createAsyncThunk(
  "notifications/deleteNotification",
  async ({ selected, success, cb }, { dispatch }) => {
    try {
      const { data } = await Client({
        method: "DELETE",
        path: API_DELETE_NOTIFICATIONS,
        data: {
          notification_ids: selected,
        },
      });
      dispatch(getAllNotifications());
      toast.success(data.message);
      success();
      mixpanel.track("Success", {
        type: "Notfication",
        subtype: "Delete-Notifications",
      });
      return selected;
    } catch (error) {
      cb();
      const msg = proccessErrorMessage(error);
      mixpanel.track("Error", {
        type: "Notfication",
        subtype: "Delete-Notifications",
        message: msg,
      });
      toast.error(msg);
      throw error;
    }
  }
);

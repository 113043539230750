import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { API_WALLET_TO_WALLET } from "../../../config/api";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import { getBalance } from "../dashboard/manageFunds";
import { getTransactions } from "../transactions/getTransactions";

export const walletToWalletTransfer = createAsyncThunk(
  "wallet/walletToWalletTransfer",
  async ({ data, cb, failed, currency }, { dispatch }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_WALLET_TO_WALLET,
        data,
      });
      dispatch(getBalance());
      dispatch(getTransactions(currency));
      cb();
      return response?.data;
    } catch (error) {
      failed();
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      throw error;
    }
  }
);

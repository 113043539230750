import React, { useState } from "react";
import TextInput from "../Inputs/TextInput";
import { formHandler } from "../../utils/formhandler";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { getUpdateSecurityPINOTP } from "../../store/actions/onboarding/getUpdateSecurityPINOTP";
import { getCreateSecurityPINOTP } from "../../store/actions/onboarding/getCreateSecurityPINOTP";
import {
  pinSchema,
  resetPasswordSchema,
  setPasswordSchema,
  setPinSchema,
} from "../../helper/validationSchemas";
import { getSecurityPasswordOTP } from "../../store/actions/onboarding/getSecurityPasswordOTP";
import MainButton from "../Buttons/MainButton";
import { resetPassword } from "../../store/actions/onboarding/resetPassword";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updatingWhich, setUpdatingWhich] = useState(null);
  const {
    user,
    getingOTP,
    resetPasswordPending: loading,
    pinLoading,
  } = useSelector((state) => state.onboarding);

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const data = { email: user?.email };

    const payload = {
      current_password: values?.current_password,
      new_password: values?.new_password,
      new_confirm_password: values?.new_password,
    };
    dispatch(
      resetPassword({
        payload,
        cb: () => {
          setSubmitting(false);
          resetForm();
        },
      })
    );

    // dispatch(
    //   getSecurityPasswordOTP({
    //     data,
    //     cb: () => {
    //       // setUpdatingWhich(type);
    //     },
    //   })
    // );
  };

  const form = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: handleSubmit,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="flex flex-col gap-2">
        <label className="text-[14px] font-[400] text-[#9C9C9E]">
          Current Password
        </label>
        <TextInput
          // title={"Email address"}
          {...formHandler("current_password", form)}
          type={"password"}
          autoComplete={"off"}
          mainClasses={"mb-0 mt-0 w-full "}
        />
      </div>
      <div className="flex flex-col gap-2">
        <label className="text-[14px] font-[400] text-[#9C9C9E]">
          New Password
        </label>
        <TextInput
          // title={"Email address"}
          {...formHandler("new_password", form)}
          type={"password"}
          autoComplete={"off"}
          mainClasses={"mb-0 mt-0 w-full "}
        />
      </div>
      {/* <div
        onClick={() => {
          navigate("/forgot-password");
        }}
        className="col-span-2 text-[14px] cursor-pointer font-[400] text-[#6935D3]"
      >
        Reset Password
      </div> */}

      <div className=" flex justify-start mt-4">
        <MainButton
          mainClasses="!w-[169px] !my-0 text-white"
          title={"Change Password"}
          onClick={form.handleSubmit}
          loading={form.isSubmitting}
          disabled={form.isSubmitting}
        />
      </div>
    </div>
  );
};

export default ResetPassword;

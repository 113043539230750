import React, { useMemo } from "react";
import calender from "../Assets/Images/calender.png";
import sell from "../Assets/Images/sell.png";
import { formatAmount } from "../utils/formatAmount";
import { timeformatter } from "../utils/dateformatter";

const InvestmentDetailDates = ({ state }) => {
  const opportunity = state?.investment;

  const amount = state?.amount;
  const _currency =
    state?.investment?.currency === "NGR" ||
    state?.cycle_details?.currency === "NGR"
      ? "₦"
      : "$";
  const _roi = opportunity?.roi || state?.cycle_details?.roi;

  const ExpectedRio = useMemo(() => {
    const ROI = Number(_roi) / 100;
    const ROIAmount = ROI * amount;

    return amount && _roi ? ROIAmount : null;
  }, [amount, _roi]);

  const tenure = state?.investment?.duration || state?.cycle_details?.duration;

  const details = [
    {
      icon: calender,
      label: "Tenure ",
      value: !tenure
        ? ""
        : tenure?.includes("week")
        ? tenure
        : `${tenure} month${Number(tenure) === 1 ? "" : "s"}`,
    },
    {
      icon: sell,
      label: "Payout Start Date",
      value: state?.cycle_details?.paymentSchedules?.payout_date
        ? timeformatter(
            state?.cycle_details?.paymentSchedules?.payout_date,
            "formal",
            false,
            true
          )
        : state?.investment?.paymentSchedules?.payout_date
        ? timeformatter(
            state?.investment?.paymentSchedules?.payout_date,
            "formal",
            false,
            true
          )
        : "Not Started",
      //   note: '(?)',
      noteColor: "#6935D3",
    },
    {
      icon: sell,
      label: "Expected Payout ",
      value: ExpectedRio
        ? `${_currency}${formatAmount(ExpectedRio + +amount)}`
        : `${_currency}`,
    },
  ];

  return (
    <div className=" md:p-5 w-full">
      <div className=" text-[16px]  font-[500] mb-3 text-[#131e3d] ">
        Investment Detail
      </div>
      <div className="mb-5 rounded-[12px] border border-[#f0f0f5]  w-full max-w-[698px] p-4 bg-white">
        {details.map((detail, index) => (
          <React.Fragment key={index}>
            <div className="flex justify-between items-center py-4">
              <div className="flex gap-2 items-center">
                <img src={detail.icon} alt={detail.label} className="w-5 h-5" />
                <div className="text-[] font-[400] text-[#9C9C9E]">
                  {detail.label}
                </div>
              </div>
              <div className="text-[] font-[500] text-[#131E3D]">
                {detail.value}
                {detail.note && (
                  <span className="text-[#6935D3]"> {detail.note}</span>
                )}
              </div>
            </div>
            {index < details.length - 1 && (
              <div className="w-full h-px bg-[#F2F2F7]"></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default InvestmentDetailDates;

import React, { useEffect, useState } from "react";
import SEO from "../../config/Seo";
import Navbar from "../../Components/Navbar";
import LoginForm from "../../Components/LoginForm";
import "./Login.css";
import Success from "../../Components/Notification/Success";
import Timeout from "../../Components/Notification/Timeout";
import Error from "../../Components/Notification/Error";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import InProgress from "../../Components/InProgress";
import SecurityQuestionModal from "../../Components/SecurityQuestionsModal";
import IdVerified from "../../Components/IdVerified";

const Login = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  useEffect(() => {
    const message = queryParams.get("message");

    if (message) {
      // toast.error(message);
    }
    return () => {
      if (message) {
        navigate("/");
      }
    };
  }, []);

  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showTimeout, setShowTimeout] = useState(false);

  const handleShowError = () => {
    setShowError(true);
    setTimeout(() => setShowError(false), 3000);
  };

  const handleShowSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 3000);
  };

  const handleShowTimeout = () => {
    setShowTimeout(true);
    setTimeout(() => setShowTimeout(false), 3000);
  };

  const [showMessage, setShowMessage] = useState(null);

  return (
    <>
      <SEO
        title={"Login | Access Your Ethical Finance Investments | Halvest"}
        description={
          "Log in to your Halvest account and explore ethical finance and Islamic banking solutions."
        }
        name={"Halvest"}
        type={"article"}
      />
      <div className="flex flex-col min-h-screen">
        <Navbar type={2} />
        <LoginForm
          userLocked={(msg, email) => {
            setShowMessage({ msg, email });
          }}
        />
      </div>

      {showMessage?.msg === "reset-login" ? (
        <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <SecurityQuestionModal
            handleClose={() => {
              setShowMessage(null);
            }}
            handleSuccess={() => {
              setShowMessage({
                msg: "success",

                buttonTitle: "OK, Got it",
              });
            }}
            goBackMsg={() => {
              setShowMessage({
                msg: "You have not set up security questions. Please contact support",

                buttonTitle: "OK, Got it",
              });
            }}
            email={showMessage?.email}
            type="reset-login"
          />
        </div>
      ) : showMessage?.msg === "success" ? (
        <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <IdVerified
            title={"Account Unlocked"}
            subTitle={
              "Your account has been unlocked successfully. Please login."
            }
            handleClose={() => {
              setShowMessage(null);
            }}
          />
        </div>
      ) : showMessage ? (
        <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <InProgress
            type={2}
            next={() => {
              if (showMessage?.buttonTitle) {
                setShowMessage(null);
              } else {
                setShowMessage({
                  msg: "reset-login",
                  email: showMessage?.email,
                });
              }
            }}
            subText={showMessage?.msg}
            buttonTitle={showMessage?.buttonTitle || "Unlock"}
            text={"Nopes!"}
            handleClose={() => {
              setShowMessage(null);
            }}
          />
        </div>
      ) : (
        <></>
      )}

      {/* {(showError || showSuccess || showTimeout) && (
        <div className="fixed top-0 left-0 w-full h-screen bg-black opacity-40 z-40"></div>
      )}

      <div className="fixed top-[85px] right-4 z-50 flex flex-col-reverse gap-4">
        {showError && (
          <div className={`toast-slide-in ${!showError && "toast-slide-out"}`}>
            <Error />
          </div>
        )}
        {showSuccess && (
          <div
            className={`toast-slide-in ${!showSuccess && "toast-slide-out"}`}
          >
            <Success />
          </div>
        )}
        {showTimeout && (
          <div
            className={`toast-slide-in ${!showTimeout && "toast-slide-out"}`}
          >
            <Timeout />
          </div>
        )}
      </div>

      <div className="fixed bottom-4 left-4 z-50 flex gap-4">
        <button
          onClick={handleShowError}
          className="bg-red-500 text-white p-2 rounded"
        >
          Show Error
        </button>
        <button
          onClick={handleShowSuccess}
          className="bg-green-500 text-white p-2 rounded"
        >
          Show Success
        </button>
        <button
          onClick={handleShowTimeout}
          className="bg-yellow-500 text-white p-2 rounded"
        >
          Show Timeout
        </button>
      </div> */}
    </>
  );
};

export default Login;

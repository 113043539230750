import React from "react";
import { formatAmount } from "../utils/formatAmount";

const Capital = ({ state }) => {
  return (
    <div className="mt-6 w-full">
      <div className=" mx-auto">
        <div className=" text-[36px] leading-[40px] md:text-[54px] md:leading-[59.4px] tracking-[-3.1%] font-[400] text-[#9c9c9e] ">
          Your investment in
          <br />
          <span className=" text-[54px] leading-[59.4px] tracking-[-3.1%] text-[#131E3D] font-[400] ">
            {" "}
            {state?.investment?.name || state?.cycle_details?.name}
          </span>{" "}
        </div>
        <div className="mb-[15px] text-[16px] mt-3 font-[400] text-[#9c9c9e] ">
          by{" "}
          {state?.opportunity?.name || state?.investment_product?.name || "--"}
        </div>
      </div>

      <div className=" mb-[5px]  py-5 text-[16px] font-[500] text-[#131E3D]  ">
        {" "}
        Overview{" "}
      </div>
      <div className="  mb-[20px] w-[85%] md:w-[696px] h-[263px] rounded-[12px] border-[1px] border-[#f2f2f7] flex justify-between flex-col ">
        <div className=" p-5">
          <div className=" text-[14px] font-[400] text-[#9c9c9e] ">Capital</div>
          <div className="text-[32px] font-[500] text-[#131E3D] ">
            {state?.investment?.currency === "NGR" ||
            state?.investment_product?.currency
              ? "₦"
              : "$"}{" "}
            {formatAmount(state?.amount)}
          </div>
          <div className=" text-[#2CB171] font-[400] text-[14px] ">
            +
            {state?.investment?.currency === "NGR" ||
            state?.investment_product?.currency
              ? "₦"
              : "$"}
            {formatAmount(
              (state?.investment?.roi
                ? state?.investment?.roi * state?.amount
                : state?.cycle_details?.roi * state?.amount) / 100
            )}{" "}
            expected returns (
            {state?.investment?.roi || state?.cycle_details?.roi}%)
          </div>
        </div>

        <div className="flex p-5 items-center gap-[6px]">
          {/* <img src={calendar} alt="" className="w-[18px] h-[18px] " /> */}
          {/* <div className=" font-[400] text-[16px] text-[#131E3D] ">
            Matures{" "}
            {timeformatter(state?.investment?.due_date, "formal", false, true)}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Capital;

import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_GET_INTEREST } from "../../../config/api";
// import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";

export const getInvestment = createAsyncThunk("getInvestment/GET", async () => {
  try {
    const { data } = await Client({
      method: "GET",
      path: API_GET_INTEREST,
    });
    return data?.data;
  } catch (error) {
    // const msg = proccessErrorMessage(error);
  }
});

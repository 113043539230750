import React, { useEffect, useMemo, useState } from "react";
import close from "../Assets/Images/close.png";
import leaf from "../Assets/Images/leaf.png";
import wallet from "../Assets/Images/wallet.png";
import sale from "../Assets/Images/sell.png";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "../utils/formatAmount";
import CustomInput from "./Inputs/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getBalance } from "../store/actions/dashboard/manageFunds";

const RealEstateReturnsCalculator = ({ handleClose, next, values }) => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(0.0);
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);
  const [expectedReturn, setExpectedReturn] = useState(0);
  const user = useSelector((state) => state.onboarding?.user);
  // const { opportunity } = useSelector((state) => state.opportunities);
  const opportunity = values;

  const walletBalance = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data?.walletBalance
  );
  const dollarBalance = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data?.dollarBalance
  );
  const _currency = opportunity?.currency === "USD" ? "$" : "₦";
  const balance = _currency === "$" ? dollarBalance : walletBalance;

  const formattedWalletBalance = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(walletBalance || 0);

  const usdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(dollarBalance || 0);

  const adminFee = useMemo(() => {
    return (amount * +opportunity?.admin_fee) / 100;
  }, [opportunity, amount]);

  const calculatedAmount = useMemo(() => {
    return adminFee + +amount;
  }, [amount, adminFee]);

  useEffect(() => {
    if (amount > -1) {
      let total = (opportunity?.annualized_target_return * amount) / 100;
      setExpectedReturn(total);
    }
  }, [amount, opportunity?.annualized_target_return]);

  const openConfirmModal = (amount) => {
    if (amount > Number(opportunity?.leftover_amount)) {
      return toast.error(
        "Sorry! The amount you wish to invest exceeds the units left."
      );
    }
    if (
      !walletBalance ||
      !dollarBalance ||
      calculatedAmount > Number(balance)
    ) {
      return toast.error(
        // "Insufficient balance, click here to fund your wallet."
        "Insufficient balance."
      );
    }

    if (!user?.hasSetPin) {
      setIsPinModalOpen(true);
    } else if (amount < opportunity?.minimum_amount) {
      toast.error(
        `The minimum investment of (${_currency}${formatAmount(
          opportunity?.minimum_amount,
          0
        )}) is not met by the amount of (${_currency}${formatAmount(
          amount,
          0
        )}) that you wish to invest.`
      );
    } else {
      next({
        amount,
        adminFee,
        id: values?.id,
        name: values?.name,
        calculatedAmount,
        investment: values,
      });
    }
  };

  useEffect(() => {
    dispatch(getBalance());
  });

  return (
    <div className="flex flex-col justify-center items-center border-[1px] border-[#F0F0F5] w-[90%] sm:w-[553px] mt-[30px] mx-auto mb-[30px] h-[431px] rounded-[14px] shadow-xl relative bg-white">
      <div className="absolute top-5 right-6 cursor-pointer">
        <img
          src={close}
          alt="Close"
          className=" cursor-pointer w-6 h-6"
          onClick={handleClose}
        />
      </div>
      <div className="flex flex-col justify-center items-center w-full h-full">
        <h2 className="text-[16px] text-[#131E3D] font-[400] mb-4">
          Invest in {values?.name}
        </h2>

        <CustomInput
          fullWidth
          noBorder
          autoComplete={"off"}
          bg={true}
          height={81}
          autoFocus={true}
          placeholder={``}
          customTextStyle={{}}
          textInputCustomStyles={{
            textAlign: "center",
            fontSize: "64px",
            color: "#131e3d",
          }}
          variant={"standard"}
          selfValue={amount}
          customType="amount"
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />

        <div className=" mb-8 flex items-center gap-[5px] ">
          <img src={leaf} alt="" className=" w-[16px] h-[16px] " />
          <div className="  text-[14px] font-[400] text-[#04910A] ">
            Expected ROI: {values?.currency === "USD" ? "$" : "₦"}
            {formatAmount(expectedReturn, 0)}
          </div>
        </div>
        <div className=" h-[1px] mb-3 w-[459px] mx-auto  bg-[#F2F2F7] "></div>
        <div className=" flex justify-between  mb-8 gap-[320px] items-center ">
          <div className="flex items-center gap-[8px]">
            <img src={sale} alt="" className=" w-[16px] h-[16px] " />
            <div className=" text-[14px] font-[400] text-[#9C9C9E] ">
              Admin Fee
            </div>
          </div>
          <div className=" text-[14px] font-[400] text-[#9c9c9e] ">
            {_currency} {adminFee ? formatAmount(adminFee, 0) : "0.00"}
          </div>
        </div>
        <div className="flex mb-5 gap-[5px] items-center ">
          <img src={wallet} alt="" className=" w-[14px] h-[14px] " />
          <div className=" text-[12px] font-[400] text-[#6935D3] ">
            Wallet Balance:{" "}
            {_currency === "$" ? usdFormatter : formattedWalletBalance}
          </div>
        </div>
        <button
          onClick={() => {
            navigate("/investment-summary/2");
          }}
          // onClick={!amount ? null : () => openConfirmModal(amount, adminFee)}
          className=" w-[203px] h-[45px] rounded-[8px] bg-[#6935D3] shadow-sm justify-center items-center flex font-[500] text-[16px] text-white   "
        >
          Pay {formatAmount(calculatedAmount, 0)}
        </button>
      </div>
    </div>
  );
};

export default RealEstateReturnsCalculator;

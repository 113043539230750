import React from "react";
import close from "../Assets/Images/close.png";
import welcome from "../Assets/Images/Frame.png";
import nopes from "../Assets/Images/nopes.png";
import { MdOutlineWarningAmber } from "react-icons/md";

const InProgress = ({
  handleClose,
  next,
  type,
  text,
  subText,
  buttonTitle,
}) => {
  return (
    <div className="flex justify-center items-center w-[90%] sm:w-[553px] mx-auto mt-[30px] mb-[30px] h-[380px] px-8 rounded-[14px] shadow-xl relative bg-white">
      <div className="absolute p-3 top-3 right-3 cursor-pointer">
        <img
          src={close}
          alt="Close"
          className="cursor-pointer w-6 h-6"
          onClick={handleClose}
        />
      </div>

      <div className="flex flex-col justify-center items-center w-full h-full">
        {type === 2 ? (
          <>
            <img
              src={nopes}
              alt="nopes"
              className="w-[110px] h-[110px] mb-[20px]"
            />
          </>
        ) : (
          <img
            src={welcome}
            alt="Under Review"
            className="w-[110px] h-[110px] mb-[20px]"
          />
        )}

        <div className="text-[20px] text-[#131e3d] font-[500] mb-[6px]">
          {text}
        </div>

        <div className="text-[14px] text-center text-[#9c9c9e] font-[400] mb-[35px]">
          {/* <div className="text-[14px] text-center text-[#FF5C00] font-[400] mb-[35px]"> */}
          {subText ===
          "You have not set up security questions. Please contact support" ? (
            <span>
              You have not set up security questions. Please contact{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:support@halvestco.com"
                className="text-[#6935D3]"
              >
                support@halvestco.com
              </a>
              .
            </span>
          ) : (
            subText
          )}
        </div>
        <button
          onClick={next}
          className="min-w-[80px] px-8 h-[45px] rounded-[8px] flex justify-center items-center text-white font-[500] bg-[#6935D3]"
        >
          {buttonTitle}
        </button>
      </div>
    </div>
  );
};

export default InProgress;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import OtpInput from "react-otp-input";
import AuthButton from "./Buttons/AuthButton";
import { resendOTP } from "../store/actions/onboarding/resendotp";
import { verifyAccount } from "../store/actions/onboarding/verifyAccount";

const VerificationForm = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const loading = useSelector((state) => state.onboarding.pending);
  const email = location?.state?.email;

  const handleVerify = () => {
    const data = { email, otp };
    dispatch(verifyAccount({ data, navigate }));
  };

  const handleResendOTP = () => {
    const data = { email };
    dispatch(resendOTP({ data }));
  };

  return (
    <div className=" flex flex-col  flex-1  p-4">
      <div className=" mt-[20px]  lg:p-8">
        <div className=" drop-shadow-[0_10px_14px_0px_rgba(0,0,0,0.25)]  ">
          <div className="w-full lg:w-[511px] h-[120px] md:h-[164px] rounded-[14px] bg-[#FFFFFF] border-[#F2F2F2] border-[1.1px] shadow-custom flex justify-center items-center gap-[10px] p-3 ">
            <div className="hidden lg:block">
              <OtpInput
                value={otp}
                onChange={setOtp}
                inputType="number"
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                placeholder="------"
                containerStyle={{
                  display: "flex",
                  justifyContent: "center",
                  // marginTop: "34px",
                  // marginBottom: "18px",
                }}
                inputStyle={{
                  width: "50px",
                  "-webkit-text-security": "disc",
                  height: "64px",
                  width: "64px",
                  fontWeight: "500",
                  border: "1.1px solid #F2F2F2",
                  borderRadius: "10px",
                  fontWeight: "bold",
                  color: "#131E3D",
                  fontSize: "32px",
                  marginLeft: "6px",
                  marginRight: "6px",
                }}
              />
            </div>
            <div className="block lg:hidden">
              <OtpInput
                value={otp}
                onChange={setOtp}
                inputType="number"
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                placeholder="------"
                containerStyle={{
                  display: "flex",
                  justifyContent: "center",
                  // marginTop: "34px",
                  // marginBottom: "18px",
                }}
                inputStyle={{
                  width: "40px",
                  "-webkit-text-security": "disc",
                  height: "44px",
                  width: "44px",
                  fontWeight: "500",
                  border: "1.1px solid #F2F2F2",
                  borderRadius: "10px",
                  fontWeight: "bold",
                  color: "#131E3D",
                  fontSize: "32px",
                  marginLeft: "6px",
                  marginRight: "6px",
                }}
              />
            </div>
          </div>
        </div>
        <h4 className="font-[400] lg:w-[493px]  mt-7 text-[16px] text-[#9C9C9E] leading-[20.8px]">
          Didn’t get the mail?{" "}
          <span
            onClick={handleResendOTP}
            className="font-[400] cursor-pointer text-center text-[16px] text-[#6935D3] leading-[20.8px]"
          >
            request new code
          </span>{" "}
          or{" "}
          <span
            onClick={handleResendOTP}
            className="font-[400] cursor-pointer text-center text-[16px] text-[#6935D3] leading-[20.8px]"
          >
            get code via WhatsApp
          </span>
        </h4>
        <AuthButton
          title={"Create Halvest account"}
          onClick={handleVerify}
          disabled={otp.length < 4}
          loading={loading}
          // mainClasses={" hover:text-[red] hover:border-[#192851] "}
          // textStyles={"group-hover:text-[#192851]"}
        />
      </div>
    </div>
  );
};

export default VerificationForm;

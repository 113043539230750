import React from "react";
import web from "../Assets/Images/web.png";
import calender from "../Assets/Images/calender.png";
import credit from "../Assets/Images/credit.png";
import sell from "../Assets/Images/sell.png";
import grubs from "../Assets/Images/realinvestment.png";
import cottage from "../Assets/Images/cottage.png";

const AboutRealEstate = () => {
  return (
    <div className=" p-y-5 mb-[20px] w-full max-w-[609px] ">
      <div className="mb-[20px] w-[609px] h-[454px] ">
        <img
          src={grubs}
          alt=""
          className=" w-[609px] h-[454px] rounded-[12px] "
        />
      </div>
      <div className=" flex mb-[10px] justify-between items-center ">
        <div className=" text-[16px] font-[500] text-[#131E3D] ">About</div>
        <div className=" flex items-center gap-[5px] ">
          <div className=" text-[#6935D3] text-[16px] font-[400]">
            Share with friends
          </div>
          <img src={web} alt="" className=" w-[20px] h-[20px] " />
        </div>
      </div>
      <div className=" h-[0.5px] w-full bg-[#F2F2F7] "></div>

      <div className=" mt-[10px] text-[#9C9C9E] text-[16px] font-[400] ">
        This exquisite property combines modern luxury with strategic location,
        offering investors a chance to be part of the lucrative rental market.
        <span className=" text-[#6935D3]">Read full memo.</span>
      </div>

      <div className=" mt-[20px] flex gap-[8px] ">
        <div className="  hover:text-[#6935D3] gap-[5px] bg-[#F2F2F7] w-[104px] font-[400] text-[14px] text-[#131E3D]  h-[33px] rounded-[40px] p-4 flex items-center justify-center ">
          <img src={cottage} alt="" className=" w-[16px] h-[16px] " />
          Apartment
        </div>
        <div className=" hover:text-[#6935D3]  bg-[#F2F2F7] w-[104px] font-[400] text-[14px] text-[#131E3D] h-[33px] rounded-[40px] p-4 flex items-center justify-center ">
          3-bedroom
        </div>
        <div className="  hover:text-[#6935D3] bg-[#F2F2F7] w-[83px] font-[400] text-[14px] text-[#131E3D] h-[33px] rounded-[40px] p-4 flex items-center justify-center ">
          Rental{" "}
        </div>
      </div>

      <div className="  mx-auto w-full  ">
        <div className="flex mt-[40px]  justify-between">
          <div className="flex gap-[6px]  items-center">
            <img src={calender} alt="" className=" w-[18px] h-[18px] " />
            <div className=" text-[16px] font-[400] text-[#9C9C9E] ">
              {" "}
              Listing Date{" "}
            </div>
          </div>
          <div className=" text-[#131E3D] text-[16px] font-[400] ">
            {" "}
            Jul 02, 2023{" "}
          </div>
        </div>
        <div className=" mt-[10px] w-full bg-[#F2F2F7] h-[1.5px]  "></div>
      </div>

      <div className=" mx-auto w-full ">
        <div className="flex mt-[10px] items-center  justify-between">
          <div className=" flex gap-[6px] items-center ">
            <img src={credit} alt="" className=" w-[18px] h-[18px] " />
            <div className=" text-[16px] font-[400] text-[#9C9C9E] ">
              {" "}
              Available Units{" "}
            </div>
          </div>
          <div className=" text-[#131E3D] text-[16px] font-[400] ">
            4{/* <span className=" text-[#6935D3] ">(?)</span> */}
          </div>
        </div>
        <div className=" mt-[10px] w-full bg-[#F2F2F7] h-[1.5px]  "></div>
      </div>

      <div className=" mx-auto w-full ">
        <div className="flex mt-[10px]  justify-between">
          <div className=" flex gap-[6px] items-center ">
            <img src={sell} alt="" className="w-[18px] h-[18px] " />
            <div className=" text-[16px] font-[400] text-[#9C9C9E] ">
              {" "}
              Target annual rental return{" "}
            </div>
          </div>
          <div className=" text-[#131E3D] text-[16px] font-[400] "> 7-10% </div>
        </div>
        <div className=" mt-[10px] w-full bg-[#F2F2F7] h-[1.5px]  "></div>
      </div>
    </div>
  );
};

export default AboutRealEstate;

import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_FUND_WALLET } from "../../../config/api";
import { GET_WALLET_BALANCE } from "../../../config/api";
import { toast } from "react-toastify";
import { showErrorModal } from "../../reducers/messageSlice";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";

export const addFunds = createAsyncThunk(
  "wallet/addFunds",
  async (payload, { dispatch }) => {
    const response = await Client({
      method: "POST",
      path: API_FUND_WALLET,
      data: payload.data,
    });

    toast.success("wallet funded");
    dispatch(getBalance());
    return response.data;
  }
);

export const getBalance = createAsyncThunk(
  "wallet/getBalance",
  async (payload, { dispatch }) => {
    try {
      const response = await Client({
        method: "GET",
        path: GET_WALLET_BALANCE,
      });
      if (payload?.cb) {
        payload.cb();
      }
      return response.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      // dispatch(showErrorModal(msg));
      throw error; // Re-throw the error to be captured by the component or handle it appropriately
    }
  }
);

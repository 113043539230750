import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";

import FilterButton from "../../Components/Buttons/FilterButton";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ResetPin from "../../Components/Profile/ResetPin";
import ResetPassword from "../../Components/Profile/ResetPassword";
import Personal from "../../Components/Profile/Personal";
import NOK from "../../Components/Profile/NOK";
import { fetchUserProfile } from "../../store/actions/profile/getProfile";
import PinConfirmation from "../../Components/PinConfirmation";
import Banks from "../../Components/Profile/Banks";
import Main from "../../Components/Profile/Main";
import { addBankDetails } from "../../store/actions/bank/bankUserActions";
import Loader from "../../Components/Utils/Loader";
import SecurityQuestionModal from "../../Components/SecurityQuestionsModal";
import { updateSecurityQuestions } from "../../store/actions/profile/updateSecurityQuestions";
import Notification from "../../Components/Notification/Notification";
import VerifyId from "../../Components/VerifyId";
import InProgress from "../../Components/InProgress";
import IdVerified from "../../Components/IdVerified";
import BVNModal from "../../Components/BVNModal";

const Profile = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [showBVNModal, setShowBVNModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showSQModal, setShowSQModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const sub = queryParams.get("sub");
  const [activeTab, setActiveTab] = useState(sub || "account");

  const navigate = useNavigate();

  const { user, profile, pending } = useSelector((state) => state.onboarding);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, []);

  const addSecurityQuestions = (values) => {
    const payload = {
      data: {
        questions: [
          {
            question: values?.q1?.value,
            answer: values?.answer1,
          },
          {
            question: values?.q2?.value,
            answer: values?.answer2,
          },
        ],
        transaction_pin: values?.otp,
      },
      failed: () => {
        setShowConfirmationModal(false);
        setShowSQModal(showConfirmationModal);
      },
      cb: () => {
        setShowConfirmationModal(false);
      },
    };

    dispatch(updateSecurityQuestions(payload));
  };

  const handleToggleNotification = () => {
    setShowNotification((prev) => !prev);
  };
  const [isIDModalOpen, setShowIdModal] = useState(false);
  const [idInProgress, setIdInProgress] = useState(false);
  const [idStatus, setIDStatus] = useState(null);

  return (
    <>
      <div className="flex flex-col bg-[#F9FAFA] gap-[10px] pt-3 pb-3 items-center min-h-screen">
        <Navbar type={3} handleNotification={handleToggleNotification} />

        <div className="w-[95%]  flex-1 items-center xl:items-start flex-col flex-wrap xl:flex-row lg:max-w-[1664px] bg-[#FFF] border-[#F0F0F5] border-[1.1px] rounded-[12px] lg:p-10 flex">
          {pending && !profile ? (
            <Loader />
          ) : (
            <>
              <Main
                setShowBVNModal={setShowBVNModal}
                setIDStatus={setIDStatus}
              />
              <div className="flex-1 w-full flex flex-col lg:ml-[70px]">
                <div className="w-min border-[#F0F0F5] drop-shadow-custom mb-4 p-2 h-[49px] border-[1px] rounded-[41px] flex justify-center items-center">
                  <FilterButton
                    items={[
                      { label: "Account", value: "account" },
                      { label: "Security", value: "security" },
                      { label: "Banks", value: "banks" },
                    ]}
                    active={activeTab}
                    setActive={setActiveTab}
                  />
                </div>

                {activeTab === "account" && (
                  <div className="w-full  flex flex-col gap-4">
                    <Personal user={user} profile={profile} />
                    <NOK profile={profile} />
                  </div>
                )}
                {activeTab === "security" && (
                  <div className="flex flex-col gap-4">
                    <div className="w-full lg:w-[642px] min-h-[302px] bg-white border-[1.2px] rounded-[14px] border-[#F0F0F5] drop-shadow-custom  p-[30px]">
                      <div className="p-1 mb-4">
                        <h2 className="text-[20px] font-[500] text-[#131e3d]">
                          Password
                        </h2>
                      </div>
                      <ResetPassword />
                    </div>

                    <ResetPin />
                    <div className="w-full md:w-[642px] mt-[15px] m-h-[171px] bg-white border-[1px] rounded-[14px] border-[#f0f0f5] drop-shadow-custom p-[30px]">
                      <p className=" p-1 text-[20px] font-[500] text-[#131e3d] ">
                        Security Questions
                      </p>
                      <button
                        onClick={() => {
                          setShowSQModal(true);
                        }}
                        className="w-[231px] h-[45px] mt-[20px] rounded-[8px] bg-[#6935D3] text-white font-[500] flex items-center justify-center"
                      >
                        {user?.hasSecurityQuestion
                          ? "Change Security Questions"
                          : "Set Security Questions"}
                      </button>
                    </div>
                  </div>
                )}
                {activeTab === "banks" && <Banks />}
              </div>
            </>
          )}
        </div>
      </div>
      {idStatus === "submit" ? (
        <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <VerifyId
            handleClose={() => {
              setIDStatus(false);
            }}
            next={() => {
              setIDStatus("verified");
            }}
          />
        </div>
      ) : (
        <></>
      )}
      {idStatus === "inprogress" ? (
        <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <InProgress
            subText={"Your ID is currently being reviewed"}
            buttonTitle="Ok"
            text={"Undergoing Review"}
            handleClose={() => {
              setIDStatus(false);
            }}
          />
        </div>
      ) : (
        <></>
      )}
      {showBVNModal ? (
        <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <BVNModal
            handleClose={() => {
              setShowBVNModal(false);
            }}
            next={() => {
              setShowBVNModal(false);
            }}
          />
        </div>
      ) : (
        <></>
      )}
      {idStatus === "verified" ? (
        <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <IdVerified
            title={"ID Verified"}
            subTitle={"Your ID has been verified successfully"}
            handleClose={() => {
              setIDStatus(false);
            }}
          />
        </div>
      ) : (
        <></>
      )}
      {showSQModal ? (
        <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <SecurityQuestionModal
            handleClose={() => {
              setShowSQModal(false);
            }}
            returningValues={showSQModal}
            next={setShowConfirmationModal}
          />
        </div>
      ) : (
        <></>
      )}
      {showConfirmationModal ? (
        <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <PinConfirmation
            handleClose={() => {
              setShowConfirmationModal(false);
            }}
            back={() => {
              setShowConfirmationModal(false);
              setShowSQModal(showConfirmationModal);
            }}
            handleSubmit={(otp) => {
              addSecurityQuestions({ ...showConfirmationModal, otp });
            }}
            buttonTitle={
              user?.hasSecurityQuestion
                ? "Change Security Questions"
                : "Set Security Questions"
            }
            subTitle={
              user?.hasSecurityQuestion
                ? "Confirm your Halvest PIN to update Security Questions"
                : "Confirm your Halvest PIN to set Security Questions"
            }
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Profile;

import React, { useEffect, useState } from "react";
import Notification from "../../Components/Notification/Notification";
import Navbar from "../../Components/Navbar";
import { LiaAngleLeftSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import VentureInvestmentCard from "../../Components/VentureInvestmentCard";
import google from "../../Assets/Images/google (2).png";

const VentureInvesting = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();

  const handleToggleNotification = () => {
    setShowNotification((prev) => !prev);
  };

  const [activeTab, setActiveTab] = useState("button1");

  const cardData = [
    {
      id: 1,
      logo: google,
      location: "Lagos, Nigeria",
      companyName: "Rowvest",
      category: "Finance, Fintech, Halal Investments",
      percentageRaised: 67,
      goalAmount: 10000000,
    },
    {
      id: 2,
      logo: google,
      location: "Lagos, Nigeria",
      companyName: "Manda Fitness",
      category: "Fitness, Sports, HealthTech",
      percentageRaised: 3,
      goalAmount: 10000000,
    },
    {
      id: 3,
      logo: google,
      location: "Lagos, Nigeria",
      companyName: "Argon",
      category: "Property, Homes, Real Estate",
      percentageRaised: 1,
      goalAmount: 10000000,
    },
    {
      id: 4,
      logo: google,
      location: "Lagos, Nigeria",
      companyName: "Sample Company",
      category: "Property, Homes, Real Estate",
      percentageRaised: 1,
      goalAmount: 10000000,
    },
  ];

  return (
    <div className="flex flex-col bg-[#F9FAFA] gap-6 pt-3 pb-3 items-center min-h-screen">
      {/* Navbar */}
      <Navbar type={3} handleNotification={handleToggleNotification} />

      <div className="w-[95%] h-full min-h-[80vh] rounded-[12px] gap-5 p-10 bg-[#FFF] border-[#F2F2F2] border-[1.1px] drop-shadow-custom max-w-[1664px] flex">
        <div className="flex flex-col flex-1">
          <div
            onClick={() => navigate(-1)}
            className="flex cursor-pointer items-center mb-4"
          >
            <LiaAngleLeftSolid
              size={18}
              color="#131E3D"
              className="mx-[2px]  "
            />
            <h3 className="text-[14px] font-[400] text-[#131E3D]">Back</h3>
          </div>
          <h2 className="text-[54px] text-[#131e3d] font-[400] text-center leading-[59.4px] tracking-[-3.1%]">
            Invest in ethically curated <br /> ventures
          </h2>

          <div className="w-[276px] mt-[30px] mx-auto shadow-custom p-2 h-[49px] border-[1px] rounded-[41px] flex justify-center items-center">
            <div className="flex justify-center items-center gap-2">
              <button
                className={`py-1 font-[400] w-[120px] h-[37px] px-1 rounded-[40px] ${
                  activeTab === "button1" ? "bg-[#F1EDFB] text-[#6935D3]" : ""
                }`}
                onClick={() => setActiveTab("button1")}
              >
                Active Rounds
              </button>
              <button
                className={`w-[120px] font-[400] h-[37px] py-1 px-1 rounded-[40px] ${
                  activeTab === "button2" ? "bg-[#F1EDFB] text-[#6935D3]" : ""
                }`}
                onClick={() => setActiveTab("button2")}
              >
                Closed Rounds
              </button>
            </div>
          </div>

          <div className="flex-wrap flex justify-center items-center gap-[30px] mt-[50px]">
            {cardData.map((card) => (
              <VentureInvestmentCard
                key={card.id}
                logo={card.logo}
                location={card.location}
                companyName={card.companyName}
                category={card.category}
                percentageRaised={card.percentageRaised}
                goalAmount={card.goalAmount}
                handleClick={() =>
                  navigate(`/investment/ventureInvesting/${card.id}`)
                }
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VentureInvesting;

import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_GET_INTEREST, API_TIMELINE } from "../../../config/api";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
// import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";

export const getInvestmentTimeline = createAsyncThunk(
  "getInvestmentTimeline/POST",
  async ({ payload, investmentId, cb, failed }) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: `${API_TIMELINE}${investmentId}`,
        data: payload,
      });

      cb(data?.data);
      return data?.data;
    } catch (error) {
      failed();
      const msg = proccessErrorMessage(error);
      toast.error(msg);
    }
  }
);

import React, { useState } from "react";
import RealEstateAmountModal from "./RealEstateAmountModal";

const RealEstateCta = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="shadow-md mt-6 p-6 w-[522px] h-[270px] rounded-[14px] border-[1px] border-[#F0F0F5] flex justify-between flex-col">
        <div>
          <div className="text-[32px] text-[#131e3d] font-[500]">$350,000</div>
          <div className="text-[14px] font-[400] text-[#131e3d]">
            $100 per unit
          </div>
        </div>

        <div>
          <div className="text-[14px] font-[400] text-[#131e3d]">
            7% invested of target
          </div>
          <div className="w-full mt-[10px] bg-[#F2F2F7] h-[4px]">
            <div className="bg-[#6935D3] h-[4px]" style={{ width: "7%" }}></div>
          </div>
        </div>
        <div>
          <button
            className="bg-[#6935D3] w-[458px] h-[45px] rounded-[8px] text-white font-[500] text-[16px]"
            onClick={openModal}
          >
            Start Investing
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-[rgba(19,30,61,0.4)] z-40">
          <RealEstateAmountModal handleClose={handleClose} />
        </div>
      )}
    </div>
  );
};

export default RealEstateCta;

import React from "react";
import close from "../../Assets/Images/close.png";
import sale from "../../Assets/Images/sell.png";
import { useNavigate } from "react-router-dom";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import up from "../../Assets/Images/chevron right.png";
import welcome from "../../Assets/Images/welcome.png";
const Success = ({ handleClose, currency, msg }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-start items-start border-[1px] border-[#F0F0F5] w-[90%] sm:w-[553px] mt-[30px] mx-auto mb-[30px] h-[431px] rounded-[14px] shadow-xl relative bg-white">
      <div className="absolute p-3 top-3 right-3 cursor-pointer">
        <img
          src={close}
          alt="Close"
          className=" cursor-pointer w-6 h-6"
          onClick={handleClose}
        />
      </div>
      <div className="flex mt-[50px] flex-col justify-center items-center w-full h-full">
        <img src={welcome} alt="" className=" w-[110px] h-[110px] mb-[20px] " />
        <div className=" mb-[6px] text-[20px] text-[#131e3d] font-[500] ">
          Awesome!
        </div>
        <div className=" mb-[35px] text-[14px] text-[#9c9c9e] font-[400] ">
          {msg
            ? msg
            : currency === "Naira"
            ? // ? "Your transfer from your USD wallet was successful"
              "Your USD wallet has now been credited."
            : "Your Naira wallet has now been credited."}
          {/* // : "Your transfer from your Naira wallet was successful"} */}
        </div>
        <button
          onClick={handleClose}
          className="  w-[252px] h-[45px] rounded-[8px] flex justify-center items-center text-white font-[500] bg-[#6935D3] "
        >
          OK, Great!
        </button>
      </div>
    </div>
  );
};

export default Success;

import React from "react";
import { MdArrowForwardIos } from "react-icons/md";

const NewPortfolioComponent = ({
  name,
  classes,
  onClick,
  owner,
  percent,
  amount,
}) => {
  return (
    <div
      onClick={onClick}
      className={`bg-[#F2EBFF]  cursor-pointer  transition duration-[800ms] ease-in-out hover:scale-[102%] min-w-[257.48px] min-h-[131.6px] rounded-[10.01px] border-[0.86px] border-[#F0F0F5] p-[18px] ${classes}`}
    >
      <div className="flex justify-between h-[20px] items-center">
        <div></div>
        {/* <div
          className={`h-[20.15px] px-2 gap-1 bg-[#FFFFFF] flex justify-between items-center rounded-[28.61px]`}
        >
          {name === "Real Estate" ? (
            <img
              className="w-[11.44px] h-[11.44px]"
              src={require("../../Assets/Images/roofing.png")}
            />
          ) : (
            <img
              className="w-[11.44px] h-[11.44px]"
              src={require("../../Assets/Images/storefront.png")}
            />
          )}
          <h4 className="text-[#131E3D] tracking-[-0.4%] font-[400] text-[8.58px] ">
            {name}
          </h4>
        </div> */}
      </div>
      <h3 className="text-[20px] font-[500] text-[#131E3D]">
        {amount?.split(".")[0]}
        <span className="text-[12px] font-[500] text-[#9C9C9E]">
          .{amount?.split(".")[1]}
        </span>
      </h3>
      <div className="w-full bg-[#A8A8A8] h-[0.72px] my-2"></div>

      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-[#131E3D] font-[400] text-[11px]">{name}</h3>
          <h3 className="text-[#9C9C9E] font-[400] text-[10px]">
            {percent}% of portfolio
          </h3>
        </div>

        <div className="w-[28.61px] h-[28.61px] rounded-full flex justify-center items-center bg-[#FFF]">
          <MdArrowForwardIos size={14} className="text-[#131E3D]" />
        </div>
      </div>
    </div>
  );
};

export default NewPortfolioComponent;

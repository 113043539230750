import React, { useState } from "react";
import close from "../Assets/Images/close.png";
import TextInput from "./Inputs/TextInput";
import { formHandler } from "../utils/formhandler";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { setPinSchema } from "../helper/validationSchemas";
import { resetTransactionPin } from "../store/actions/onboarding/resetTransactionPin";
import MainButton from "./Buttons/MainButton";
import OTPInput from "react-otp-input";

const UpdatePin = ({ next, handleClose, small }) => {
  const dispatch = useDispatch();

  const handleSubmitNewPin = (values, { setSubmitting, resetForm }) => {
    const payload = {
      transaction_pin: values?.new_pin,
      // otp: otp,
    };
    dispatch(
      resetTransactionPin({
        payload,
        cb: () => {
          resetForm();
          setSubmitting();
        },
      })
    );
  };

  const form = useFormik({
    initialValues: {
      new_pin: null,
      confirm_pin: null,
    },
    validationSchema: setPinSchema,
    onSubmit: handleSubmitNewPin,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div
      className={`relative mx-auto w-full md:w-[553px] ${
        small ? "md:h-[320px]" : "md:h-[388px]"
      } rounded-[14px] border-[1px] flex justify-between flex-col bg-[#FFF] border-[#f0f0f5] drop-shadow-custom`}
    >
      <div
        onClick={handleClose}
        className="absolute top-3 right-3 cursor-pointer py-2 px-3"
      >
        <img src={close} alt="Close" className="w-6 h-6" />
      </div>
      <div>
        <div className="mt-[30px] flex justify-center text-[16px] font-[500] text-[#131e3d]">
          Transaction PIN
        </div>

        <div
          className={`${
            small ? "mt-[40px]" : "mt-[60px]"
          } flex justify-between w-[90%] flex-col md:flex-row mx-auto gap-[10px] items-center`}
        >
          <div className="flex flex-col">
            <label
              htmlFor="accountNumber"
              className="text-[14px]  font-[400] text-[#9c9c9e] mb-[12px]"
            >
              New Transaction PIN
            </label>
            <OTPInput
              {...formHandler("new_pin", form)}
              inputType="number"
              numInputs={4}
              renderInput={(props) => <input {...props} />}
              placeholder="----"
              containerStyle={{
                display: "flex",
                // justifyContent: "center",
              }}
              inputStyle={{
                height: "52px",
                "-webkit-text-security": "disc",
                width: "52px",
                fontWeight: "500",
                border: "1.1px solid #F2F2F2",
                borderRadius: "10px",
                fontWeight: "bold",
                color: "#131E3D",
                fontSize: "32px",
                // marginLeft: "6px",
                marginRight: "6px",
              }}
            />
            {form.errors["new_pin"] && (
              <h6 className=" text-[0.75rem]  mt-[4px] px-[2px] mb-2 text-[#ef5364]  w-full">
                {`${form.errors["new_pin"]}`}
              </h6>
            )}
            {/* <TextInput
              {...formHandler("new_pin", form)}
              mainClasses={"mb-0 mt-0 !w-[236px] "}
              type={"password"}
            /> */}
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="bank"
              className="text-[14px]   font-[400] text-[#9c9c9e] mb-[12px]"
            >
              Confirm Transaction PIN
            </label>
            <OTPInput
              {...formHandler("confirm_pin", form)}
              inputType="number"
              numInputs={4}
              autoComplete={"off"}
              renderInput={(props) => <input {...props} />}
              placeholder="----"
              containerStyle={{
                display: "flex",
                // justifyContent: "center",
              }}
              inputStyle={{
                height: "52px",
                "-webkit-text-security": "disc",
                width: "52px",
                fontWeight: "500",
                border: "1.1px solid #F2F2F2",
                borderRadius: "10px",
                fontWeight: "bold",
                color: "#131E3D",
                fontSize: "32px",
                // marginLeft: "6px",
                marginRight: "6px",
              }}
            />
            {form.errors["confirm_pin"] && (
              <h6 className=" text-[0.75rem]  mt-[4px] px-[2px] mb-2 text-[#ef5364]  w-full">
                {`${form.errors["confirm_pin"]}`}
              </h6>
            )}
          </div>
        </div>
      </div>

      {/* <div className="w-[90%] self-end justify-self-end mx-auto flex justify-end my-5"> */}
      <div className="w-[90%]  justify-center mx-auto  pb-2 flex my-5">
        <MainButton
          mainClasses="!w-[231px] h-[45px] !my-0 text-white"
          title={"Create Transaction PIN"}
          onClick={form.handleSubmit}
          loading={form.isSubmitting}
          disabled={form.isSubmitting}
        />
      </div>
    </div>
  );
};

export default UpdatePin;

import React from "react";
// import {close} from 'react-icons'
import close from "../Assets/Images/close.png";
const BusinessSlide4 = ({ next, handleClose }) => {
  return (
    <div className=" mb-6 p-6 border-[1.2px] border-[#F0F0F5] rounded-xl mx-auto  w-[90%]  md:w-[553px] h-[598px] max-h-[95%]  bg-white flex flex-col shadow-2xl justify-start ">
      <div className=" flex justify-end ">
        <img
          onClick={handleClose}
          src={close}
          alt=""
          className="cursor-pointer w-[24px] h-[24px] "
        />
      </div>
      <div className=" mb-[5px] w-[48px] h-[48px] rounded-full bg-[#F1EDFB] font-[400] text-[20px] flex justify-center items-center text-[#6935D3] ">
        3
      </div>
      <div className="flex-1 overflow-y-scroll">
        <div className=" flex gap-[3px] p-3 mt-[8px] mb-[10px] ">
          <div className=" w-[6px] h-[6px] bg-[#6935D3] rounded-full "></div>
          <div className=" w-[6px] h-[6px] bg-[#6935D3] rounded-full "></div>
          <div className=" w-[6px] h-[6px] bg-[#6935D3] rounded-full "></div>
        </div>

        <div className=" text-[24px] text-[#131E3D] font-[500] ">
          Historical Performance
        </div>

        <div className="  mx-auto w-[466px] ">
          <div className="flex mt-[40px]  justify-between">
            <div className=" text-[16px] font-[400] text-[#9C9C9E] ">2021</div>
            <div className=" text-[#131E3D] text-[16px] font-[400] ">22%</div>
          </div>
          <div className=" mt-[10px] w-full bg-[#F2F2F7] h-[1.5px]  "></div>
        </div>

        <div className=" mx-auto w-[466px] ">
          <div className="flex mt-[10px] items-center  justify-between">
            <div className=" text-[16px] font-[400] text-[#9C9C9E] ">2022 </div>
            <div className=" text-[#131E3D] text-[16px] font-[400] ">30%</div>
          </div>
          <div className=" mt-[10px] w-full bg-[#F2F2F7] h-[1.5px]  "></div>
        </div>

        <div className=" mx-auto w-[466px] ">
          <div className="flex mt-[10px]  justify-between">
            <div className=" text-[16px] font-[400] text-[#9C9C9E] ">2023 </div>
            <div className=" text-[#131E3D] text-[16px] font-[400] "> 38% </div>
          </div>
          <div className=" mt-[10px] w-full bg-[#F2F2F7] h-[1.5px]  "></div>
        </div>

        <div className="mt-[22px] text-[12px] text-[#9C9C9E] font-[400] ">
          As a member of Halvest, you invest through Halvest Multipurpose
          Co-operative Society Limited for our business financing campaigns.
          Halvest raises funds to invest in inventory financing projects owned
          by Halcredit Technologies Ltd, a registered digital money lender in
          Nigeria. Halcredit executes cost-plus profit financing contracts with
          businesses for buying and selling inventory items.
        </div>

        <div className=" gap-[7px] mt-[13px] p-2  flex justify-end items-center ">
          <button
            onClick={next}
            className=" w-[149px] h-[45px] rounded-[8px] bg-[#6935D3]  text-white  font-[500] text-[16px] "
          >
            Start Investing
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessSlide4;

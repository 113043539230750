import React, { useState } from "react";
import { Box, Slider } from "@mui/material";
import { styled } from "@mui/material/styles";
import close from "../Assets/Images/close.png";
import { formatAmount } from "../utils/formatAmount";

// Custom Slider Component
const CustomSlider = styled(Slider)(({ theme }) => ({
  color: "#6935D3",
  height: 8,
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#6935D3",
    border: "2px solid #fff",
    "&:focus, &:hover, &$active": {
      boxShadow: "0 0 0 8px rgba(105, 91, 252, 0.16)",
    },
  },
  "& .MuiSlider-rail": {
    height: 8,
    backgroundColor: "#F0F0F5",
  },
  "& .MuiSlider-track": {
    height: 8,
    backgroundColor: "#6935D3",
  },
}));

const RealEstateReturnsCalculator = () => {
  const [rangeValue, setRangeValue] = useState(30); // Adjust initial value as needed

  const handleChange = (event, newValue) => {
    setRangeValue(newValue);
  };

  // Determine the index of the line that should be purple
  const getLineColor = (lineIndex) => {
    const threshold = (100 / 10) * lineIndex;
    return rangeValue >= threshold && rangeValue < threshold + 100 / 10
      ? "#6935D3"
      : "#9c9c9e";
  };

  return (
    <div className="relative rounded-lg mb-[20px] flex flex-col items-center justify-center  w-[553px] h-[685px] border border-[#F0F0F5] ">
      <div className="flex justify-between items-center w-full mb-8">
        {/* <button className="absolute top-5 right-5 text-gray-500">
          <img src={close} alt="close" className="w-[24px] h-[24px]" />
        </button> */}
      </div>
      <span className="text-[16px] font-[400] text-[#131e3d]">
        Projected returns
      </span>
      <div className="mb-8 text-[64px] font-[400] text-[rgb(25,154,14)]">
        ${formatAmount((rangeValue * 1000 * 8) / 100 + rangeValue * 1000, 0)}
      </div>

      <div className="mb-10 text-center">
        <div className="text-[14px] font-[400] text-[#9C9C9E]">
          Initial Investment
        </div>
        <div className="text-[20px] font-[400] text-[#131e3d]">
          ${formatAmount(rangeValue * 1000, 0)}
        </div>
      </div>

      <Box sx={{ width: 410 }}>
        <CustomSlider
          aria-label="Investment Range"
          value={rangeValue}
          onChange={handleChange}
          min={0}
          max={100}
          valueLabelDisplay="auto"
          getAriaValueText={(value) => `${value}%`}
        />
      </Box>

      <div className="relative w-[410px] mt-4">
        <div className="absolute w-full h-full flex justify-between items-center">
          {Array.from({ length: 11 }, (_, i) => (
            <div
              key={i}
              className="w-[2px] h-[22px]"
              style={{ backgroundColor: getLineColor(i) }}
            ></div>
          ))}
        </div>
      </div>

      <div className="mt-4 mx-auto w-[466px]">
        <div className="flex justify-between mt-[40px]">
          <div className="text-[16px] font-[400] text-[#9C9C9E]">
            Projected return
          </div>
          <div className="text-[#131E3D] text-[16px] font-[400]">8%</div>
        </div>
        <div className="mt-[10px] w-full bg-[#F2F2F7] h-[1.5px]"></div>
      </div>

      <div className="mx-auto w-[466px]">
        <div className="flex justify-between mt-[10px] items-center">
          <div className="text-[16px] font-[400] text-[#9C9C9E]">
            Projected rent yield
          </div>
          <div className="text-[#131E3D] text-[16px] font-[400]">3.5%</div>
        </div>
        <div className="mt-[10px] w-full bg-[#F2F2F7] h-[1.5px]"></div>
      </div>

      <div className="mx-auto w-[466px]">
        <div className="flex justify-between mt-[10px]">
          <div className="text-[16px] font-[400] text-[#9C9C9E]">
            5-year return
          </div>
          <div className="text-[#131E3D] text-[16px] font-[400]">48%</div>
        </div>
        <div className="mt-[10px] w-full bg-[#F2F2F7] h-[1.5px]"></div>
      </div>

      <div className="mt-[30px] mb-[20px]">
        <button className="w-[203px] h-[45px] rounded-[8px] bg-[#6935D3] font-[500] text-white">
          Start Investing
        </button>
      </div>

      <div className="text-[#9c9c9e] px-2 font-[400] text-center text-[12px]">
        The figures provided are estimates and not guarantees. Real estate
        markets are subject to fluctuations and changes due to various factors.
      </div>
    </div>
  );
};

export default RealEstateReturnsCalculator;

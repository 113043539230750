import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import Switcher from "../../Components/Switcher";
import BalanceCard from "../../Components/Wallet/BalanceCard";
import { FiMoreVertical } from "react-icons/fi";
import Payouts from "../../Components/Wallet/Carousel";
import WithdrawalOption from "../../Components/Wallet/WithdrawalOption";
import NoDebitCard from "../../Components/Wallet/NoDebitCard";
import ChooseBank from "../../Components/Wallet/ChooseBank";
import WithdrawalSubmittion from "../../Components/Wallet/WithdrawalSubmittion";
import { useNavigate } from "react-router-dom";
import PaymentMethod from "../../Components/Wallet/PaymentMethod";
import FundingFromCard from "../../Components/Wallet/FundingFromCard";
import AddDebitCard from "../../Components/Wallet/AddDebitCard";
import Success from "../../Components/Wallet/Sucess";
import BankTransferDetails from "../../Components/Wallet/BankTransferDetails";

import FundFromOtherWallet from "../../Components/Wallet/FundFromOtherWallet";
import BVNModal from "../../Components/BVNModal";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Utils/Loader";
import { allDashboard } from "../../store/actions/dashboard/allDashboard";
import { getBalance } from "../../store/actions/dashboard/manageFunds";
import TransactionTable from "../../Components/TransactionTable";
import PaymentMethodUsd from "../../Components/Wallet/PaymentMethodUsd";
import AccountDetails from "../../Components/Wallet/AccountDetails";
import FundFromNairaWallet from "../../Components/Wallet/FundFromNairaWallet";
import PayNaira from "../../Components/Wallet/PayNaira";
import {
  resetwithdrawal,
  saveWithdrawalAmount,
} from "../../store/reducers/walletSlice";

const Wallet = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [showNotification, setShowNotification] = useState(false);
  const [showBVNModal, setShowBVNModal] = useState(false);
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [back, setBack] = useState(null);
  const [showSlide, setShowSlide] = useState("");
  const [showChooseBank, setShowChooseBank] = useState(false);

  const [currency, setCurrency] = useState("Naira");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const dashboard = useSelector((state) => state?.dashboard);

  const halvestBalance = useSelector(
    (state) => state?.dashboard?.halvestBalance
  );

  const { profile } = useSelector((state) => state.onboarding);

  const handleShowDepositSlide = () => {
    setShowSlide("deposit");
    setCurrentStep(1);
  };

  const handleShowUSDDepositSlide = () => {
    setShowSlide("deposit");
    setCurrentStep(9);
    // Ameer add the code to show USd Deposit Slide here
  };

  const handleShowWithdrawSlide = () => {
    setShowSlide("withdraw");
    setCurrentStep(1);
  };

  const handleCloseSlide = () => {
    setShowSlide("");
    setCurrentStep(1);
    setShowChooseBank(true);
  };

  const handleNextSlide = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : 1));
  };

  const handleMoveToNairaWallet = () => {
    setShowChooseBank(false);
    dispatch(saveWithdrawalAmount(0.0));
    setCurrentStep(2);
  };
  useEffect(() => {
    dispatch(getBalance());
  }, []);
  useEffect(() => {
    dispatch(
      allDashboard(() => {
        setLoading(false);
      })
    );
  }, []);

  const handleToggleNotification = () => {
    setShowNotification((prev) => !prev);
  };

  return (
    <div className="flex flex-col bg-[#F9FAFA] gap-6 pt-3 pb-3 items-center min-h-screen">
      <Navbar type={3} handleNotification={handleToggleNotification} />

      <div className="w-[95%] flex-1 h-full flex flex-col rounded-[12px] gap-10 p-10 bg-[#FFF] border-[#F2F2F2] border-[1.1px] drop-shadow-custom max-w-[1664px]">
        {(loading && !profile) || (loading && !halvestBalance) ? (
          <Loader />
        ) : (
          <div className="flex w-full md:w-auto justify-center flex-wrap gap-[25px] 3xl:gap-[40px] items-start">
            <div className="w-full md:w-[698px]  py-4">
              <div className="flex justify-between mb-[49px] items-center">
                <div className="text-[59px] font-[400] text-[#131e3d]">
                  Wallet
                </div>
                {/* <Switcher handleCurrencyChange={setCurrency} /> */}
              </div>
              <div className="text-[16px] font-[500] text-[#131e3d] mb-[16px]">
                Overview
              </div>
              <BalanceCard
                currency={currency}
                setCurrency={setCurrency}
                setShowBVNModal={setShowBVNModal}
                handleShowDepositSlide={
                  currency === "Naira"
                    ? handleShowDepositSlide
                    : handleShowUSDDepositSlide
                }
                handleShowWithdrawSlide={handleShowWithdrawSlide}
              />
              <div className="mt-[63px]">
                <div className="mb-[16px] flex justify-between items-center">
                  <div className="text-[16px] font-[500] text-[#131e3d]">
                    Recent Transactions
                  </div>
                  <FiMoreVertical
                    color="#131e3d"
                    className="w-[20px] h-[20px]"
                  />
                </div>
                <TransactionTable currency={currency} />
              </div>
            </div>
            <div className=""></div>
            <div className="w-full md:w-auto flex flex-col mt-[40px] ">
              <div className=" text-[14px] font-[500] text-[#131e3d]  mb-[16px] ">
                {" "}
                Upcoming Payouts{" "}
              </div>
              <div className="w-full lg:w-[520px] border-[1px]  rounded-[8px] ">
                <Payouts currency={currency} />
              </div>
            </div>
          </div>
        )}
      </div>
      {(showSlide === "withdraw" || showSlide === "deposit") && (
        <div className="fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center bg-[rgba(19,30,61,0.4)]">
          <div className="relative w-full   rounded-lg overflow-hidden">
            <div className="absolute top-0 right-0 p-4">
              {/* <button onClick={handleCloseSlide} className="text-[#131E3D]">Close</button> */}
            </div>
            <div className="flex justify-center items-center h-full">
              <div className="w-full">
                {showSlide === "withdraw" && (
                  <>
                    {currentStep === 1 && (
                      <WithdrawalOption
                        next={() => {
                          setShowChooseBank(true);
                          dispatch(saveWithdrawalAmount(0.0));
                          setCurrentStep(2);
                        }}
                        currency={currency}
                        move={handleMoveToNairaWallet}
                        handleClose={handleCloseSlide}
                      />
                    )}
                    {currentStep === 2 && (
                      <NoDebitCard
                        handleBack={handleBack}
                        currency={currency}
                        showChooseBank={showChooseBank}
                        next={() => {
                          if (showChooseBank) {
                            setCurrentStep(3);
                          } else {
                            setBack(2);
                            setCurrentStep(4);
                          }
                        }}
                        handleClose={handleCloseSlide}
                      />
                    )}
                    {currentStep === 3 && showChooseBank && (
                      <ChooseBank
                        currency={currency}
                        handleBack={handleBack}
                        next={() => {
                          setBack(3);
                          setCurrentStep(4);
                        }}
                        handleClose={handleCloseSlide}
                      />
                    )}
                    {currentStep === 4 && (
                      <WithdrawalSubmittion
                        currency={currency}
                        handleBack={() =>
                          showChooseBank ? setCurrentStep(3) : setCurrentStep(2)
                        }
                        type={showChooseBank ? "bank" : "other-wallet"}
                        handleClose={handleCloseSlide}
                        next={() =>
                          setCurrentStep({
                            id: 5,
                            msg: showChooseBank
                              ? "Your withdrawal request has been sent successfully"
                              : "Your withdrawal was successfull",
                          })
                        }
                      />
                    )}
                    {currentStep?.id === 5 && (
                      <Success
                        currency={currency}
                        msg={currentStep?.msg}
                        handleClose={() => {
                          handleCloseSlide();
                          dispatch(getBalance());
                        }}
                      />
                    )}
                  </>
                )}

                {showSlide === "deposit" && (
                  <>
                    {currentStep === 1 && (
                      <PaymentMethod
                        openDebitCard={() => setCurrentStep(2)}
                        fromUSDWallet={() => {
                          dispatch(resetwithdrawal());
                          setCurrentStep(8);
                        }}
                        openBankTransfer={() => setCurrentStep(6)}
                        handleClose={handleCloseSlide}
                      />
                    )}
                    {currentStep === 2 && (
                      <FundingFromCard
                        handleBack={handleBack}
                        next={() => {
                          setBack(2);
                          setCurrentStep(4);
                        }}
                        addCard={() => setCurrentStep(3)}
                        handleClose={handleCloseSlide}
                      />
                    )}
                    {currentStep === 3 && (
                      <AddDebitCard
                        handleBack={handleBack}
                        next={() => setCurrentStep(2)}
                        handleClose={handleCloseSlide}
                      />
                    )}
                    {currentStep === 4 && (
                      <WithdrawalSubmittion
                        handleBack={() => {
                          setCurrentStep(back);
                          setBack(null);
                        }}
                        next={() =>
                          setCurrentStep({
                            id: 5,
                            msg: "Your wallet was funded successfully",
                          })
                        }
                        currency={currency}
                        handleClose={handleCloseSlide}
                      />
                    )}
                    {currentStep?.id === 5 && (
                      <Success
                        // handleNext={handleNextSlide}
                        handleClose={() => {
                          handleCloseSlide();
                        }}
                      />
                    )}
                    {currentStep === 6 && (
                      <BankTransferDetails
                        handleBack={() => setCurrentStep(1)}
                        handleClose={handleCloseSlide}
                      />
                    )}

                    {currentStep === 8 && (
                      <FundFromOtherWallet
                        handleBack={() => setCurrentStep(1)}
                        next={() => {
                          setBack(8);
                          setCurrentStep(4);
                        }}
                        currency={currency}
                        handleClose={handleCloseSlide}
                      />
                    )}
                    {currentStep === 9 && (
                      <PaymentMethodUsd
                        fromNairaWallet={() => {
                          dispatch(resetwithdrawal());
                          setCurrentStep(8);
                        }}
                        openTransfersNotFromNigeria={() => setCurrentStep(10)}
                        openTransfersFromNigeria={() => setCurrentStep(13)}
                        handleClose={handleCloseSlide}
                      />
                    )}
                    {currentStep === 10 && (
                      <AccountDetails
                        handleBack={handleBack}
                        currency={currency}
                        handleCloseModal={handleCloseSlide}
                      />
                    )}
                    {currentStep === 13 && (
                      <AccountDetails
                        handleBack={() => {
                          setCurrentStep(9);
                        }}
                        from="Nigeria"
                        currency={currency}
                        handleCloseModal={handleCloseSlide}
                      />
                    )}
                    {currentStep === 11 && (
                      <FundFromNairaWallet
                        handleBack={handleBack}
                        next={() => setCurrentStep(12)}
                        handleClose={handleCloseSlide}
                      />
                    )}
                    {currentStep === 12 && (
                      <PayNaira
                        handleBack={handleBack}
                        next={() => {
                          setBack(12);
                          setCurrentStep(4);
                        }}
                        handleClose={handleCloseSlide}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showBVNModal ? (
        <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <BVNModal
            handleClose={() => {
              setShowBVNModal(false);
            }}
            next={() => {
              setShowBVNModal(false);
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Wallet;

import React from 'react'
import { MdInfo } from 'react-icons/md'

const SavingsOverview = () => {
  return (
    <div className=' w-[550px] h-[200px] border-[1px] drop-shadow-custom border-[#F2F2F2] rounded-[10px] p-[35px] '>
      <div className=" flex justify-start items-start gap-[30px] ">
        <div className=" flex-col justify-start  ">
            <div className=" text-[14px] font-[400] text-[#9c9c9e] ">Total Balance</div>
            <div className=" text-[32px] font-[500] text-[#131e3d]  "> ₦ 450,000<span className=" text-[14px] font-[400] text-[#9c9c9e] ">.77</span>  </div>
            <div className=" mt-[7px]  text-[14px] font-[400] text-[#2CB171] ">N5,345 in returns</div>
        </div>
        <div className=" flex flex-col ">
            <div className=" text-[14px] font-[400] text-[#9c9c9e]" >Annual Returns  </div>
            <div className=" text-[32px] font-[500] text-[#2CB171] ">16.7%</div>
        </div>
      </div>
      <div className=" mt-[30px]  flex items-center gap-[8px] ">
        <MdInfo size={14} color='#9c9c9e' />
        <div className=" text-[14px] font-[400] text-[#9c9c9e] ">30 days minimum holding period in place</div>
      </div>
    </div>
  )
}

export default SavingsOverview

import calender from '../Assets/Images/calender.png';
import credit from '../Assets/Images/credit.png';
import sell from '../Assets/Images/sell.png';

const SavingsDetails = () => {
  return (
    <div className="border-[1px] border-[#F0F0F5] p-[20px] rounded-[10px] drop-shadow-custom">
      <div className="w-full mb-[20px]">
        <div className="flex justify-between items-center">
          <div className="flex gap-[8px] items-center">
            <img src={calender} alt="Listing Date" className="w-[20px] h-[20px]" />
            <span className="text-[16px] font-[400] text-[#9C9C9E]">Start  Date</span>
          </div>
          <span className="text-[#131E3D] text-[16px] font-[500]">Jul 02, 2023</span>
        </div>
        <div className="mt-[8px] h-[1px] bg-[#F2F2F7] w-full"></div>
      </div>

      <div className="w-full mb-[20px]">
        <div className="flex justify-between items-center">
          <div className="flex gap-[8px] items-center">
            <img src={calender} alt="Available Units" className="w-[20px] h-[20px]" />
            <span className="text-[16px] font-[400] text-[#9C9C9E]">Maturity Date </span>
          </div>
          <span className="text-[#131E3D] text-[16px] font-[500]">Jul 02, 2023</span>
        </div>
        <div className="mt-[8px] h-[1px] bg-[#F2F2F7] w-full"></div>
      </div>

      <div className="w-full">
        <div className="flex justify-between items-center">
          <div className="flex gap-[8px] items-center">
            <img src={sell} alt="Target Return" className="w-[20px] h-[20px]" />
            <span className="text-[16px] font-[400] text-[#9C9C9E]">Profit Sharing </span>
          </div>
          <span className="text-[#131E3D] text-[16px] font-[500]">65/30%</span>
        </div>
        <div className="mt-[8px] h-[1px] bg-[#F2F2F7] w-full"></div>
      </div>
    </div>
  );
};

export default SavingsDetails;

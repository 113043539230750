import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { API_GET_RATE } from "../../../config/api";
import { Client } from "../../../client";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";

export const getRate = createAsyncThunk(
  "wallet/getRate",
  async (cb, { rejectWithValue }) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: API_GET_RATE,
        data: {
          from_currency: "USD",
          to_currency: "NGN",
          amount: 1,
        },
      });

      cb(data?.value?.rate);
      return data?.value?.rate;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      // toast.error(msg);
      cb();
      throw error;
    }
  }
);

import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  API_UPDATE_USER_PROFILE,
  API_USER_BANK_DETAILS,
  API_USER_USD_BANK_DETAILS,
} from "../../../config/api";
import { logout } from "../user/logout";

export const allDashboard = createAsyncThunk(
  "all/get",
  async (cb, { dispatch }) => {
    try {
      const userResponse = await Client({
        method: "GET",
        path: API_UPDATE_USER_PROFILE,
      });

      const { data: bankData } = await Client({
        method: "GET",
        path: API_USER_BANK_DETAILS,
      });
      const { data: USDData } = await Client({
        method: "GET",
        path: API_USER_USD_BANK_DETAILS,
      });
      cb();
      return {
        profile: userResponse?.data?.data,
        banks: [...bankData?.data, ...USDData?.data],
      };
    } catch (error) {
      cb();
      if (
        error?.response?.data?.message === "Authentication required." ||
        error?.response?.data?.message === "Token is Invalid"
      ) {
        dispatch(logout());
      }
      // toast.error(
      //   error?.response?.data?.errors?.BVN || "An error has occurred"
      // );
    }
  }
);

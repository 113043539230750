import { createSlice } from "@reduxjs/toolkit";
import { getTransactions } from "../actions/transactions/getTransactions";
import { getPayouts } from "../actions/payouts/getPayouts";
import { switchCurrency } from "../actions/dashboard/switchCurrency";
import { getPayout } from "../actions/payouts/getPayout";
import { autoFetchTransactions } from "../actions/transactions/autoFetchTransactions";
import { autoFetchPayout } from "../actions/payouts/autoFetchPayout";
import { login } from "../actions/onboarding/login";
import { registerUsers } from "../actions/onboarding/registerUsers";

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState: {
    error: null,
    profileDetails: {},
    pending: false,
    success: false,
    transactions: [],
    transactionsData: null,
    payOuts: [],
    totalpayOut: null,
    payOutsPending: false,
  },

  reducers: {
    updateTransactions: (state, action) => {
      state.transactions = [...state.transactions, ...action.payload];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.transactions = [];
      state.payOuts = [];
      state.autoFetchPayout = [];
      state.totalpayOut = null;
    });
    builder.addCase(registerUsers.fulfilled, (state, action) => {
      state.autoFetchPayout = [];
      state.transactions = [];
      state.payOuts = [];
      state.totalpayOut = null;
    });

    builder.addCase(switchCurrency.fulfilled, (state, action) => {
      state.transactions = [];
      state.payOuts = [];
      state.payOutsPending = false;
    });
    builder.addCase(getTransactions.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(getTransactions.fulfilled, (state, action) => {
      state.pending = false;
      state.transactionsData = action.payload;
      state.transactions = action.payload?.data || [];
    });
    builder.addCase(getTransactions.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(autoFetchTransactions.fulfilled, (state, action) => {
      state.pending = false;
      state.transactions = action.payload;
    });
    builder.addCase(getPayouts.pending, (state, action) => {
      state.payOutsPending = true;
    });
    builder.addCase(getPayouts.fulfilled, (state, action) => {
      state.payOutsPending = false;
      state.payOuts = action.payload;
    });
    builder.addCase(getPayouts.rejected, (state, action) => {
      state.payOutsPending = false;
    });
    builder.addCase(getPayout.pending, (state, action) => {
      state.payOutsPending = true;
    });
    builder.addCase(getPayout.fulfilled, (state, action) => {
      state.payOutsPending = false;
      state.totalpayOut = action.payload;
    });
    builder.addCase(getPayout.rejected, (state, action) => {
      state.payOutsPending = false;
    });
    builder.addCase(autoFetchPayout.fulfilled, (state, action) => {
      state.autoFetchPayout = action.payload;
    });
  },
});

export const { updateTransactions } = transactionsSlice.actions;

export default transactionsSlice.reducer;

import React, { useEffect } from "react";
import close from "../../Assets/Images/close.png";
import wallet from "../../Assets/Images/wallet2.png";
import sale from "../../Assets/Images/sell.png";
import { LiaAngleLeftSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import CustomInput from "../Inputs/CustomInput";
import { formatAmount } from "../../utils/formatAmount";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRate } from "../../store/actions/wallet/getRate";
import { countdown } from "../../utils/countdown";

const PayNaira = ({ next, handleClose, handleBack }) => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(0.0);
  const { conversionRate } = useSelector((state) => state?.wallet);

  const dispatch = useDispatch();

  const [startTime, setTimeStart] = useState(null);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    dispatch(
      getRate(() => {
        setTimeStart(new Date());
      })
    );
  }, []);

  const handleRefresh = () => {
    setTimer(null);
    dispatch(
      getRate(() => {
        setTimeStart(new Date());
      })
    );
  };

  useEffect(() => {
    let timerId;
    if (startTime) {
      timerId = setInterval(() => {
        const _times = countdown(startTime);
        setTimer(_times);
      }, 1000);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [startTime]);

  return (
    <div className="flex flex-col justify-center items-center border-[1px] border-[#F0F0F5] w-[90%] sm:w-[553px] mt-[30px] mx-auto mb-[30px] h-[431px] rounded-[14px] shadow-xl relative bg-white">
      <div className="absolute p-3 top-3 right-3 cursor-pointer">
        <img
          src={close}
          alt="Close"
          className="w-6 h-6"
          onClick={handleClose}
        />
      </div>
      <div className="flex gap-[95px] items-center justify-start w-full px-8 mt-[50px]">
        <div
          className="flex items-center gap-[3px] cursor-pointer"
          onClick={handleBack}
        >
          <LiaAngleLeftSolid size={18} color="#131E3D" className="mr-2" />
          <h3 className="text-[14px] font-[400] text-[#131E3D]">Back</h3>
        </div>
        <div className="text-[16px] text-[#131e3d] font-[400]">
          {" "}
          Fund from Naira Wallet{" "}
        </div>
      </div>
      <div className="flex flex-col justify-center items-center w-full h-full px-4">
        <CustomInput
          fullWidth
          noBorder
          bg={true}
          autoComplete={"off"}
          height={81}
          autoFocus={true}
          placeholder={``}
          customTextStyle={{}}
          textInputCustomStyles={{
            textAlign: "center",
            fontSize: "64px",
            color: "#131e3d",
          }}
          variant={"standard"}
          selfValue={amount}
          customType="amount"
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />
        <div className="mb-8 text-[14px] font-[400] text-[#9c9c9e]">
          1$ ~ ₦
          {conversionRate
            ? !conversionRate
              ? "--"
              : formatAmount(conversionRate)
            : "--"}
          {"  "}
          {timer?.difference > 60000 && timer?.sec ? (
            <span
              onClick={handleRefresh}
              className="text-[#6935D3] cursor-pointer"
            >
              Refresh
            </span>
          ) : (
            <span className="text-[#6935D3]">
              Expires in{" "}
              {timer?.difference > 60000
                ? "00"
                : timer?.min
                ? timer?.min
                : "--"}
              :
              {timer?.difference > 60000
                ? "00"
                : timer?.sec
                ? timer?.sec
                : "--"}
            </span>
          )}
        </div>
        {/* <div className="h-[1px] mb-3 w-full bg-[#F2F2F7]"></div>
                <div className="flex justify-between mb-8 items-center w-full px-4">
                    <div className="flex items-center gap-[8px]">
                        <img src={sale} alt="Admin Fee" className='w-[16px] h-[16px]' />
                        <div className="text-[14px] font-[400] text-[#9C9C9E]">Admin Fee</div>
                    </div>
                    <div className="text-[14px] font-[400] text-[#9c9c9e]">$50</div>
                </div> */}
        <div className="flex mt-[30px] mb-5 items-center gap-[5px]">
          <img src={wallet} alt="Wallet" className="w-[14px] h-[14px]" />
          <div className="text-[12px] font-[400] text-[#9c9c9e]">
            Naira Wallet Balance: ₦4,560.00
          </div>
        </div>

        <button
          onClick={next}
          className="w-[203px] h-[45px] rounded-[8px] bg-[#6935D3] shadow-sm flex justify-center items-center font-[500] text-[16px] text-white"
        >
          Pay {formatAmount(amount)}
        </button>
      </div>
    </div>
  );
};

export default PayNaira;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { logoutUser } from "../../reducers/onboardingSlice";
import { removeAccessToken } from "../../../helper/manageAccessToken";

export const logout = createAsyncThunk(
  "users/logout",
  async (data, { dispatch }) => {
    removeAccessToken();
    dispatch(logoutUser());
  }
);

import React, { useEffect, useMemo, useState } from "react";

import funding from "../Assets/Images/funding.png";
import withdraw from "../Assets/Images/withdrawal.png";

// import { LiaAngleLeftSolid, LiaAngleRightSolid } from "react-icons/lia";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import RecentTransaction from "./RecentTransaction";
import Loader from "./Utils/Loader";
import { useDispatch, useSelector } from "react-redux";
import { timeformatter } from "../utils/dateformatter";
import { useNavigate } from "react-router-dom";
import { getTransactions } from "../store/actions/transactions/getTransactions";
import { toast } from "react-toastify";
import { Client } from "../client";
import { updateTransactions } from "../store/reducers/transactionsSlice";
import { formatAmount } from "../utils/formatAmount";

// TableRow Component
const TableRow = ({
  name,
  description,
  date,
  transaction_side,
  success,
  amount,
}) => {
  return (
    <div className="flex items-center flex-wrap cursor-pointer gap-2 p-2 border-b">
      <div className="flex items-center gap-2">
        {transaction_side === "CREDIT" || transaction_side === "Credit" ? (
          <img src={funding} alt="icon" className="w-[38px] h-[38px]" />
        ) : (
          <img src={withdraw} alt="icon" className="w-[38px] h-[38px]" />
        )}
        <div className="flex flex-col flex-[5] w-[280px] ">
          <span className="block  font-[500] text-[14px]  text-[#131E3D]">
            {name}
          </span>
          {/* <span className="block text-[12px] font-[400] text-[#9C9C9E]">
            {description}
          </span> */}
          <span className="block text-[12px] font-[400] text-[#9C9C9E]">
            {date}
          </span>
        </div>
      </div>
      <div className="flex items-center justify-between flex-[4] gap-6">
        {success === "success" ? (
          <span className=" text-[14px] items-center capitalize  flex justify-center font-[400] bg-[#E4FFC2] px-[14px] h-[27px] p-1 rounded-[40px]  text-[#344616] ">
            {success}
          </span>
        ) : (
          <span className=" text-[14px] items-center capitalize flex justify-center font-[400] bg-[#F2F2F7] px-[14px] h-[27px] p-1 rounded-[40px]  text-[#131E3D] ">
            {success}
          </span>
        )}
        <span className="ml-4 text-[#131E3D] font-[500] text-[14px] ">
          {amount}
        </span>{" "}
      </div>
    </div>
  );
};

// DummyTable Component
const TransactionTable = ({ tableStyle, currency = "Naira" }) => {
  // const transactions =
  //   useSelector((state) => state.transactions.transactions) || [];
  const { pending, transactionsData, transactions } = useSelector(
    (state) => state.transactions
  );

  const navigate = useNavigate();
  const tableData = transactions?.map((transaction) => ({
    name: transaction?.transaction_type,
    date: timeformatter(
      transaction?.transaction_date,
      "formal",
      "withInThreeDays",
      true
    ),
    description: transaction?.description,
    transaction_side: transaction?.transaction_side,
    success: transaction?.status,
    amount: `${transaction?.currency === "NGR" ? "₦" : "$"}${formatAmount(
      transaction?.amount
    )}`,
  }));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (pending === false) {
      setLoading(false);
    }
  }, [pending]);

  const [currentPage, setCurrentPage] = useState(1);

  const isPrevValid = currentPage > 1;
  const isNextValid = useMemo(
    () => transactions.length > currentPage * 5,
    [transactions, currentPage]
  );

  const [loadingNewData, setLoadingData] = useState(false);

  const loadNewData = async ({ url, type }) => {
    try {
      setLoading(true);

      const { data } = await Client({
        method: "GET",
        path: `${url}&&currency=${currency === "Naira" ? "NGR" : "USD"}`,
        type: "full-path",
      });

      dispatch(updateTransactions(data?.data));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Data");
    }
  };

  useEffect(() => {
    if (!isNextValid && tableData?.length < transactionsData?.total) {
      loadNewData({
        url: transactionsData?.links[Math.floor(tableData?.length / 50 + 1)]
          ?.url,
      });
    }
  }, [isNextValid]);

  const dispatch = useDispatch();
  useEffect(() => {
    setCurrentPage(1);
    dispatch(getTransactions(currency === "Naira" ? "NGR" : "USD"));
  }, [currency]);
  return (
    <>
      {loading || pending ? (
        <div className="w-full border-[1px] border-[#F0F0F5] items-center justify-center flex rounded-[14px] max-w-[698px]  h-[401px]">
          <Loader />
        </div>
      ) : transactions.length > 0 ? (
        <div
          className={`w-full flex-col flex justify-between max-w-[689px] min-h-[401px] border-[1px] rounded-xl mb-[30px] border-[#f2f2f7]  p-4 ${tableStyle}`}
        >
          <div className="flex flex-col gap-2">
            {tableData
              ?.slice((currentPage - 1) * 5, currentPage * 5)
              .map((row, index) => (
                <TableRow
                  key={index}
                  name={row.name}
                  transaction_side={row?.transaction_side}
                  description={row.description}
                  date={row.date}
                  success={row.success}
                  amount={row.amount}
                />
              ))}
          </div>
          <div className="flex items-center gap-5 pt-5 pb-2">
            <div
              onClick={
                isPrevValid
                  ? () => {
                      setCurrentPage((prev) => prev - 1);
                    }
                  : null
              }
              className="flex cursor-pointer items-center gap-[0px]"
            >
              <FiChevronLeft
                size={"14px"}
                color={isPrevValid ? "#9C9C9E" : "#131E3D"}
                className="mt-[1px]"
              />
              <h4
                className={` font-[400] text-[13px]  tracking-[-0.4%] ${
                  isPrevValid ? "text-[#9C9C9E}" : "text-[#131E3D]"
                }`}
              >
                Previous
              </h4>
            </div>
            <div
              onClick={
                isNextValid
                  ? () => {
                      setCurrentPage((prev) => prev + 1);
                    }
                  : () => {}
              }
              className="flex cursor-pointer items-center gap-[0px]"
            >
              <h4
                className={` font-[400] text-[13px] tracking-[-0.4%] ${
                  isNextValid ? "text-[#9C9C9E}" : "text-[#131E3D]"
                }`}
              >
                Next
              </h4>
              <FiChevronRight
                size={"14px"}
                color={isNextValid ? "#9C9C9E" : "#131E3D"}
                className="mt-[1px]"
              />
            </div>
          </div>
        </div>
      ) : (
        <RecentTransaction
          onClick={() => {
            navigate(`/investment?currency=${currency}`);
          }}
        />
      )}
    </>
  );
};

export default TransactionTable;

import React, { useState } from "react";
import close from "../../Assets/Images/close.png";
import up from "../../Assets/Images/chevron right.png";
import { useNavigate } from "react-router-dom";

import usdWallet from "../../Assets/Icons/usd-wallet.png";
import debitCard from "../../Assets/Icons/debit-card.png";
import bankTransfer from "../../Assets/Icons/bank-transfer.png";

const PaymentMethod = ({
  handleClose,
  openDebitCard,
  openBankTransfer,
  fromUSDWallet,
}) => {
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (index) => {
    setSelectedCard(index);

    switch (index) {
      case 0:
        // openDebitCard();
        openBankTransfer();
        break;
      case 1:
        // fromUSDWallet();
        break;
      case 2:
        break;
      default:
        break;
    }
  };

  const getCardStyle = (index) => {
    if (selectedCard === null) {
      return "opacity-100";
    }
    return selectedCard === index
      ? "opacity-100 scale-105 shadow-xl"
      : "opacity-50";
  };

  return (
    <div className="flex flex-col justify-start items-start border-[1px] border-[#F0F0F5] w-[90%] sm:w-[553px] mt-[30px] mx-auto mb-[30px] h-[456px] rounded-[14px] shadow-xl relative bg-white">
      <div className="absolute p-3 top-3 right-3 cursor-pointer">
        <img
          src={close}
          alt="Close"
          className="cursor-pointer w-6 h-6"
          onClick={handleClose}
        />
      </div>
      <div className="flex mt-[55px] flex-col justify-start items-center w-full h-full">
        <h2 className="text-[16px] text-[#9c9c9e] font-[400] mb-[30px]">
          Fund Naira Wallet
        </h2>
        <div className="flex w-[90%] md:w-auto flex-col gap-[26px]">
          {["Bank Transfer", "From USD Wallet", "Debit Card"].map(
            (text, index) => (
              <div
                key={index}
                className={`w-full md:w-[489px]  opacity-50  h-[70px] items-center flex justify-between p-5 rounded-[14px] border-[1px] border-[#f0f0f5] shadow-md   ${
                  index === 2 || index === 1 ? "" : getCardStyle(index)
                } ${
                  index === 2 || index === 1
                    ? "cursor-not-allowed"
                    : "hover:shadow-xl transition duration-[500ms] ease-in-out  hover:opacity-100 hover:scale-102 cursor-pointer"
                }`}
                onClick={() => handleCardClick(index)}
              >
                <div className="flex gap-4">
                  {text === "Bank Transfer" ? (
                    <img
                      src={bankTransfer}
                      alt=""
                      className="w-[30px] h-[30px]"
                    />
                  ) : text === "Debit Card" ? (
                    <img src={debitCard} alt="" className="w-[30px] h-[30px]" />
                  ) : text === "From USD Wallet" ? (
                    <img src={usdWallet} alt="" className="w-[30px] h-[30px]" />
                  ) : (
                    <img src={usdWallet} alt="" className="w-[30px] h-[30px]" />
                  )}

                  <div className="text-[16px] font-[500] text-[#131E3D]">
                    {text}
                  </div>
                </div>
                <img src={up} alt="" className="w-[20px] h-[20px]" />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;

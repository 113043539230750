import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import { TbCaptureFilled } from "react-icons/tb";

const Selfie = ({ form, id }) => {
  const [file, setFile] = useState(null);
  const [showWebCam, setShowWebcam] = useState(false);
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };

  const webcamRef = useRef(null);
  const capture = useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot();
    console.log(imageSrc, "imageSrc");
    // const a = document.createElement("a");
    // a.download = "my-file.txt";
    // a.href = URL.createObjectURL(imageSrc);
    // a.addEventListener("click", (e) => {
    //   setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    // });
    // a.click();
    // setFile()

    form?.setFieldValue(id, imageSrc);
    setShowWebcam(false);
  }, [webcamRef]);

  const handleToggleBackDrop = () => {
    setShowWebcam((prev) => !prev);
  };
  console.log(form.values?.photo, "file");

  return (
    <>
      {showWebCam ? (
        <div
          onClick={() => {
            handleToggleBackDrop();
          }}
          className="w-screen h-screen z-[2] fixed top-0 left-0 bg-transparent"
        ></div>
      ) : (
        <></>
      )}

      <label className="text-[14px] font-[400] mt-3 text-[#9C9C9E]">
        ID Photo
      </label>
      <div
        onClick={() => {
          if (!showWebCam) {
            setShowWebcam(true);
          }
        }}
        className="border-[1.3px] cursor-pointer rounded-[8px] justify-center items-center flex border-[#F2F2F7] h-[137px] w-full"
      >
        {/* <div>{file}</div> */}
        {showWebCam ? (
          <div className="flex flex-col bg-[rgb(241,241,241,0.8)] rounded-[4px] relative items-center">
            <Webcam
              audio={false}
              height={720}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={1280}
              className="z-50 rounded-[4px]"
              videoConstraints={videoConstraints}
            />
            <TbCaptureFilled
              onClick={capture}
              color="#FFF"
              className="w-[36px] cursor-pointer absolute bottom-3 z-50 h-[36px] "
            />
          </div>
        ) : form.values?.photo ? (
          // <div
          //   className="text-[14px] tracking-wide font-[400] mt-3 text-[#9C9C9E]"
          //   // className="w-[24px] h-[24px] bg-[#222]"
          // >
          //   {form.values?.photo ? "Uploaded" : "Click Here"}
          // </div>
          <img src={form.values?.photo} className="h-full" />
        ) : (
          <div className="text-[14px] tracking-wide font-[400] mt-3 text-[#9C9C9E]">
            Click Here
          </div>
        )}
      </div>
    </>
  );
};

export default Selfie;

import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERIFY } from "../../../config/api";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import mixpanel from "mixpanel-browser";

export const verifyAccount = createAsyncThunk(
  "users/verify",
  async (payload) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_VERIFY,
        data: payload.data,
      });

      payload.navigate("/welcome", { replace: true });

      return response?.data?.data;
    } catch (error) {
      const message = proccessErrorMessage(error);

      mixpanel.track("Error", {
        type: "onboarding",
        subtype: "verify-account",
        message: message,
      });

      toast.error(message || "Invalid user ID or token");
      throw error;
    }
  }
);

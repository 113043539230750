import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  useNavigate,
  useLocation,
  useSearchParams,
  useParams,
} from "react-router-dom";

import { useFormik } from "formik";
import { resetPasswordValidationSchema } from "../helper/validationSchemas";
import TextInput from "./Inputs/TextInput";
import { formHandler } from "../utils/formhandler";

import AuthButton from "./Buttons/AuthButton";
import { changePassword } from "../store/actions/onboarding/changePassword";
import { toast } from "react-toastify";

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useParams();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();

  const handlSubmit = useCallback(
    (values, { setSubmitting }) => {
      dispatch(
        changePassword({
          data: { ...values, token },
          cb: () => {
            setSubmitting(false);
            toast.success("Password changed successfully. Please login.");
            navigate("/", { replace: true });
          },
          setSubmitting,
        })
      );
    },
    [token]
  );

  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
      password_confirmation: "",
    },
    onSubmit: handlSubmit,
    validationSchema: resetPasswordValidationSchema,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  useEffect(() => {
    form.setFieldValue("email", searchParams?.get("email"));
  }, [searchParams]);

  return (
    <div className=" flex px-4 flex-col mt-[3.5%] justify-center items-center">
      <h2 className="font-[500] text-center text-[#131E3D] tracking-[-3.1%] text-[50px] leading-[50px] lg:text-[71px] mb-4 lg:leading-[79.2px]  ">
        Set new
        <br /> password
      </h2>
      <div className="w-full flex drop-shadow-[0_10px_14px_0px_rgba(0,0,0,0.25)] justify-center mt-[2.5%]  items-center ">
        <div className="w-full  lg:w-[493px] py-8 px-6 bg-[#FFFFFF] border-[#F2F2F2] border-[1.1px] drop-shadow-custom shadow-custom">
          <div className="mb-3">
            <label
              className="block tracking-wide text-[#9C9C9E] text-[14px] font-[400] mb-2"
              htmlFor="password"
            >
              New Password
            </label>
            <TextInput
              // title={"Email address"}
              {...formHandler("password", form)}
              type={"password"}
              mainClasses={"mb-0 mt-0 w-full md:w-[429px]"}
            />
          </div>
          <div className="mb-3">
            <label
              className="block tracking-wide text-[#9C9C9E] text-[14px] font-[400] mb-2"
              htmlFor="password"
            >
              Confirm New Password
            </label>
            <TextInput
              // title={"Email address"}
              {...formHandler("password_confirmation", form)}
              type={"password"}
              mainClasses={"mb-0 mt-0 w-full md:w-[429px]"}
            />
          </div>
        </div>
      </div>

      <AuthButton
        title={"Change Password"}
        onClick={form.handleSubmit}
        loading={form.isSubmitting}
        disabled={form.isSubmitting}
        // mainClasses={" hover:text-[red] hover:border-[#192851] "}
        // textStyles={"group-hover:text-[#192851]"}
      />
    </div>
  );
};

export default ResetPasswordForm;

import React, { useEffect, useState } from "react";
import AddNewBankModal from "../AddNewBankModal";
import bankImage from "../../Assets/Images/bank.png";
import more from "../../Assets/Images/morebanks.png";
import PinConfirmation from "../PinConfirmation";
import { addBankDetails } from "../../store/actions/bank/bankUserActions";
import { useDispatch, useSelector } from "react-redux";
import { getUserBanks } from "../../store/actions/bank/getUserBanks";
import { deleteUserBank } from "../../store/actions/bank/deleteUserBank";
import Loader from "../Utils/Loader";
import AddDollarBank from "../AddDollarbank";
import { bankUSDUserActions } from "../../store/actions/bank/bankUSDUserActions";

const Banks = () => {
  const [currency, setCurrency] = useState("Naira");

  const [deleteLoading, setDeleteLoading] = useState(false);

  const { pending } = useSelector((state) => state?.bank);

  const allBanksList = useSelector((state) => state?.bank?.banks) || [];
  const { userBanks } = useSelector((state) => state?.bank) || [];

  const [isAddBankModalOpen, setIsAddBankModalOpen] = useState(false);

  const closeAddBankModal = () => {
    setIsAddBankModalOpen(false);
  };

  const openAddBankModal = () => {
    setIsAddBankModalOpen(true);
  };

  const handleRemoveBank = (bankId, currency) => {
    setDeleteLoading(bankId);
    dispatch(
      deleteUserBank({
        bankId,
        setDeleteLoading,
        currency,
        cb: () => {
          dispatch(getUserBanks());
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getUserBanks());
  }, []);

  const user = useSelector((state) => state.onboarding?.user);

  const [showAddBankModal, setShowAddBankModal] = useState(false);
  const [showAddDollarBank, setShowAddDollarBank] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [activeCurrency, setActiveCurrency] = useState("naira");
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const addBank = (values) => {
    if (values?.data?.currency === "USD") {
      const _values = values;
      _values.data.transaction_pin = _values?.otp;
      const { otp, ...rest } = _values;

      dispatch(
        bankUSDUserActions({
          data: values?.data,
          failed: () => {
            setShowConfirmationModal(false);
            setShowAddDollarBank(true);
          },
          cb: () => {
            setShowConfirmationModal(false);
          },
        })
      );
    } else {
      const payload = {
        data: {
          account_name: values.account_name,
          account_number: values.account_number,
          bank_name: values?.bankName?.name,
          currency: "NGR",
          transaction_pin: values?.otp,
        },
        failed: () => {
          setShowConfirmationModal(false);
          setShowAddBankModal(true);
        },
        cb: () => {
          setShowConfirmationModal(false);
        },
      };

      dispatch(addBankDetails(payload));
    }
  };

  const NGN_banks = userBanks?.filter(
    (bank) => bank.currency?.toLowerCase() === "ngr"
  );

  const USD_banks = userBanks?.filter(
    (bank) => bank?.currency?.toLowerCase() === "usd" && bank?.bank_type
  );
  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="w-full md:w-[642px] flex flex-col h-[450px] bg-white border-[#f0f0f5] border-[1px] drop-shadow-custom p-4">
          <div className="flex justify-between items-center p-1">
            <div className="text-[20px] font-[500] text-[#131e3d]">
              Bank Accounts
            </div>

            <div
              onClick={() => {
                if (activeCurrency === "naira") {
                  setShowAddBankModal(true);
                } else if (activeCurrency === "dollar") {
                  setShowAddDollarBank(true);
                }
              }}
              className={`w-[169px] !h-[45px] rounded-[8px] flex justify-center items-center text-white font-[500] cursor-pointer ${
                activeCurrency === "naira" ? "bg-[#6935D3]" : "bg-[#6935D3]"
              }`}
            >
              {activeCurrency === "naira"
                ? "Add Naira Bank"
                : "Add Dollar Bank"}
            </div>
          </div>

          <div className="w-[157px] shadow-md border-[1px] min-h-[49px] flex justify-center items-center rounded-full mb-4">
            <div className="flex justify-center items-center gap-2">
              <button
                className={`py-1 w-[65px] h-[37px] px-1 rounded-full ${
                  activeCurrency === "naira"
                    ? "bg-[#F1EDFB] text-[#6935D3]"
                    : ""
                }`}
                onClick={() => setActiveCurrency("naira")}
                aria-selected={activeCurrency === "naira"}
                type="button"
              >
                Naira
              </button>
              <button
                className={`py-1 w-[65px] h-[37px] px-1 rounded-full ${
                  activeCurrency === "dollar"
                    ? "bg-[#F1EDFB] text-[#6935D3]"
                    : ""
                }`}
                onClick={() => setActiveCurrency("dollar")}
                aria-selected={activeCurrency === "dollar"}
                type="button"
              >
                Dollar
              </button>
            </div>
          </div>

          {pending ? (
            <div className="w-full h-full flex">
              <Loader />
            </div>
          ) : activeCurrency === "naira" ? (
            <div className="mt-4 flex-1  overflow-y-scroll">
              <div className="space-y-4 mt-[40px] ">
                {NGN_banks?.map((bank, index) => {
                  return (
                    <div
                      key={bank.id}
                      className={`flex  items-center justify-between ${
                        NGN_banks?.length === index + 1
                          ? ""
                          : "border-b border-[#F2F2F7]"
                      } pb-4`}
                    >
                      <div className=" items-center flex">
                        <img
                          src={bankImage}
                          alt="Bank Icon"
                          className="w-[38px] h-[38px]"
                        />
                        <div className="flex flex-col flex-grow mx-4">
                          <h3 className="text-[16px] font-[500] text-[#131e3d]">
                            {bank?.account_name}
                          </h3>
                          <p className="text-[14px] font-[400] text-[#9C9C9E]">
                            {bank?.bank_name || bank?.bank_type} -{" "}
                            {bank?.account_number}
                          </p>
                        </div>
                      </div>

                      <div
                        className={` group relative    transition-all duration-500 ease-in-out cursor-pointer`}
                      >
                        <img
                          onClick={() => {
                            setShowModal((prev) => !prev);
                          }}
                          src={more}
                          alt="More Options"
                          className="w-[28px] h-[28px] items-center "
                        />

                        {showModal ? (
                          <div className="absolute overflow-hidden rounded-[10px] transition-all ease-in-out duration-[300ms]  flex flex-col gap-1 justify-center items-center top-10 right-0 w-[120px]   bg-[#FFF] border ">
                            {deleteLoading ? (
                              <div className="w-full h-[40px] p-4 flex">
                                <Loader />
                              </div>
                            ) : (
                              <>
                                {/* <div className="w-full hover:bg-[#F2F2F7] py-[10px] flex justify-center items-center">
                            <p
                              onClick={() => {
                                // navigate("/profile");
                              }}
                              className={` transition-all ease-in-out duration-[300ms] hover:text-[#131E3D] justify-center items-center text-[14px] font-[500] tracking-wide w-[61px]     text-center      `}
                            >
                              Profile
                            </p>
                          </div> */}
                                <div className="w-full hover:bg-[#FEF3F2] py-[10px] flex justify-center items-center">
                                  <p
                                    onClick={() => {
                                      handleRemoveBank(bank.id, bank?.currency);
                                    }}
                                    className={` transition-all ease-in-out duration-[300ms] text-[#B42318] justify-center items-center text-[14px] font-[500] tracking-wide w-[61px]     text-center      `}
                                  >
                                    Remove
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="mt-4 flex-1  overflow-y-scroll">
              <div className="space-y-4 mt-[40px] ">
                {USD_banks?.map((bank, index) => {
                  return (
                    <div
                      key={bank.id}
                      className={`flex items-center justify-between ${
                        USD_banks?.length === index + 1
                          ? ""
                          : "border-b border-[#F2F2F7]"
                      } pb-4`}
                    >
                      <div className=" items-center flex">
                        <img
                          src={bankImage}
                          alt="Bank Icon"
                          className="w-[38px] h-[38px]"
                        />
                        <div className="flex flex-col flex-grow mx-4">
                          <h3 className="text-[16px] font-[500] text-[#131e3d]">
                            {bank?.account_name}
                          </h3>
                          <p className="text-[14px] font-[400] text-[#9C9C9E]">
                            {bank?.bank_name || bank?.bank_type} -{" "}
                            {bank?.account_number}
                          </p>
                        </div>
                      </div>

                      <div
                        className={` group relative    transition-all duration-500 ease-in-out cursor-pointer`}
                      >
                        <img
                          onClick={() => {
                            setShowModal((prev) => !prev);
                          }}
                          src={more}
                          alt="More Options"
                          className="w-[28px] h-[28px] items-center "
                        />

                        {showModal ? (
                          <div className="absolute overflow-hidden rounded-[10px] z-10 transition-all ease-in-out duration-[300ms]  flex flex-col gap-1 justify-center items-center top-10 right-0 w-[120px]   bg-[#FFF] border  ">
                            {deleteLoading ? (
                              <div className="w-full h-[40px] p-4 flex">
                                <Loader />
                              </div>
                            ) : (
                              <>
                                {/* <div className="w-full hover:bg-[#F2F2F7] py-[10px] flex justify-center items-center">
                            <p
                              onClick={() => {
                                // navigate("/profile");
                              }}
                              className={` transition-all ease-in-out duration-[300ms] hover:text-[#131E3D] justify-center items-center text-[14px] font-[500] tracking-wide w-[61px]     text-center      `}
                            >
                              Profile
                            </p>
                          </div> */}
                                <div className="w-full hover:bg-[#FEF3F2] py-[10px] flex justify-center items-center">
                                  <p
                                    onClick={() => {
                                      handleRemoveBank(bank.id, bank?.currency);
                                    }}
                                    className={` transition-all ease-in-out duration-[300ms] text-[#B42318] justify-center items-center text-[14px] font-[500] tracking-wide w-[61px]     text-center      `}
                                  >
                                    Remove
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      {showAddBankModal ? (
        <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <AddNewBankModal
            handleClose={() => {
              setShowAddBankModal(false);
            }}
            next={(value) => {
              setShowConfirmationModal(value);
              setShowAddBankModal(false);
            }}
            currency={activeCurrency}
          />
        </div>
      ) : (
        <></>
      )}

      {showAddDollarBank ? (
        <div className="top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <AddDollarBank
            handleClose={() => {
              setShowAddDollarBank(false);
            }}
            next={(value) => {
              setShowConfirmationModal(value);
              setShowAddDollarBank(false);
            }}
          />
        </div>
      ) : null}

      {showConfirmationModal ? (
        <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <PinConfirmation
            handleClose={() => {
              setShowConfirmationModal(false);
            }}
            back={() => {
              setShowConfirmationModal(false);
              setShowAddBankModal(true);
            }}
            handleSubmit={(otp) => {
              addBank({ ...showConfirmationModal, otp });
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Banks;

import React, { useState } from "react";
import TextInput from "../Inputs/TextInput";
import { formHandler } from "../../utils/formhandler";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { getUpdateSecurityPINOTP } from "../../store/actions/onboarding/getUpdateSecurityPINOTP";
import { getCreateSecurityPINOTP } from "../../store/actions/onboarding/getCreateSecurityPINOTP";
import { pinSchema, setPinSchema } from "../../helper/validationSchemas";
import MainButton from "../Buttons/MainButton";
import { resetTransactionPin } from "../../store/actions/onboarding/resetTransactionPin";
import OTPInput from "react-otp-input";

const ResetPin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updatingWhich, setUpdatingWhich] = useState(null);
  const {
    user,
    getingOTP,
    resetPasswordPending: loading,
    pinLoading,
  } = useSelector((state) => state.onboarding);

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const { current_pin, new_pin } = values;
    const payload = {
      current_pin,
      transaction_pin: new_pin,
    };
    dispatch(
      resetTransactionPin({
        payload,
        cb: () => {
          resetForm();
          setSubmitting();
        },
      })
    );
  };
  const handleSubmitNewPin = (values, { setSubmitting, resetForm }) => {
    const payload = {
      transaction_pin: values?.new_pin,
      // otp: otp,
    };
    dispatch(
      resetTransactionPin({
        payload,
        cb: () => {
          resetForm();
          setSubmitting();
        },
      })
    );
  };

  const form = useFormik({
    initialValues: {
      current_pin: "",
      new_pin: "",
    },
    validationSchema: user?.hasSetPin ? pinSchema : setPinSchema,
    onSubmit: user?.hasSetPin ? handleSubmit : handleSubmitNewPin,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div className="w-full md:w-[642px] min-h-[302px] bg-white border-[1.2px] rounded-[14px] border-[#F0F0F5] drop-shadow-custom  p-[30px]">
      <div className="p-1 mb-4">
        <h2 className="text-[20px] font-[500] text-[#131e3d]">
          {" "}
          Transaction PIN{" "}
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {user?.hasSetPin ? (
          <div className="flex flex-col gap-2">
            <label className="text-[14px] font-[400] text-[#9C9C9E]">
              Current Transaction PIN
            </label>
            <OTPInput
              {...formHandler("current_pin", form)}
              inputType="number"
              numInputs={4}
              renderInput={(props) => <input {...props} />}
              placeholder="----"
              containerStyle={{
                display: "flex",
              }}
              inputStyle={{
                height: "52px",
                "-webkit-text-security": "disc",
                width: "52px",
                fontWeight: "500",
                border: "1.1px solid #F2F2F2",
                borderRadius: "10px",
                fontWeight: "bold",
                color: "#131E3D",
                fontSize: "32px",
                // marginLeft: "6px",
                marginRight: "6px",
              }}
            />
            {form.errors["current_pin"] && (
              <h6 className=" text-[0.75rem]  mt-[4px] px-[2px] mb-2 text-[#ef5364]  w-full">
                {`${form.errors["current_pin"]}`}
              </h6>
            )}
            {/* <TextInput
              form={form}
              {...formHandler("current_pin", form)}
              type={"password"}
              autoComplete={"off"}
              mainClasses={"mb-0 mt-0 w-full "}
            /> */}
          </div>
        ) : (
          <></>
        )}
        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            {" "}
            New Transaction PIN{" "}
          </label>
          <OTPInput
            {...formHandler("new_pin", form)}
            inputType="number"
            numInputs={4}
            renderInput={(props) => <input {...props} />}
            placeholder="----"
            containerStyle={{
              display: "flex",
            }}
            inputStyle={{
              height: "52px",
              "-webkit-text-security": "disc",
              width: "52px",
              fontWeight: "500",
              border: "1.1px solid #F2F2F2",
              borderRadius: "10px",
              fontWeight: "bold",
              color: "#131E3D",
              fontSize: "32px",
              // marginLeft: "6px",
              marginRight: "6px",
            }}
          />
          {form.errors["new_pin"] && (
            <h6 className=" text-[0.75rem]  mt-[4px] px-[2px] mb-2 text-[#ef5364]  w-full">
              {`${form.errors["new_pin"]}`}
            </h6>
          )}
          {/* <TextInput
            // title={"Email address"}
            {...formHandler("new_pin", form)}
            type={"password"}
            autoComplete={"off"}
            mainClasses={"mb-0 mt-0 w-full"}
          /> */}
        </div>
        {!user?.hasSetPin ? (
          <div className="flex flex-col gap-2">
            <label className="text-[14px] font-[400] text-[#9C9C9E]">
              Confirm Transaction PIN
            </label>
            <OTPInput
              {...formHandler("confirm_pin", form)}
              inputType="number"
              numInputs={4}
              autoComplete={"off"}
              renderInput={(props) => <input {...props} />}
              placeholder="----"
              containerStyle={{
                display: "flex",
              }}
              inputStyle={{
                height: "52px",
                "-webkit-text-security": "disc",
                width: "52px",
                fontWeight: "500",
                border: "1.1px solid #F2F2F2",
                borderRadius: "10px",
                fontWeight: "bold",
                color: "#131E3D",
                fontSize: "32px",
                // marginLeft: "6px",
                marginRight: "6px",
              }}
            />
            {form.errors["confirm_pin"] && (
              <h6 className=" text-[0.75rem]  mt-[4px] px-[2px] mb-2 text-[#ef5364]  w-full">
                {`${form.errors["confirm_pin"]}`}
              </h6>
            )}
            {/* <TextInput
              form={form}
              {...formHandler("confirm_pin", form)}
              type={"password"}
              autoComplete={"off"}
              mainClasses={"mb-0 mt-0 w-full "}
            /> */}
          </div>
        ) : (
          <></>
        )}

        {/* {user?.hasSetPin ? (
          <div className=" cursor-pointer col-span-2 text-[14px] font-[400] text-[#6935D3]">
            Reset PIN?
          </div>
        ) : (
          <></>
        )} */}

        <div className=" flex justify-start mt-4">
          <MainButton
            mainClasses="!w-[231px] !my-0 text-white"
            title={
              user?.hasSetPin
                ? "Change Transaction PIN"
                : "Create Transaction PIN"
            }
            onClick={form.handleSubmit}
            loading={form.isSubmitting}
            disabled={form.isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPin;

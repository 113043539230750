import React, { useEffect, useMemo, useState } from "react";
import close from "../../Assets/Images/close.png";
import wallet from "../../Assets/Images/wallet2.png";
import { LiaAngleLeftSolid } from "react-icons/lia";
import CustomInput from "../Inputs/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import MainButton from "../Buttons/MainButton";
import { saveWithdrawalAmount } from "../../store/reducers/walletSlice";
import { getRate } from "../../store/actions/wallet/getRate";
import { countdown } from "../../utils/countdown";
import { formatAmount } from "../../utils/formatAmount";
import sale from "../../Assets/Images/sell.png";
import toggleIcon from "../../Assets/Images/toggle-currency.png";
import usd from "../../Assets/Icons/usd-flag.png";
import ngr from "../../Assets/Icons/ngr-flag.png";

const NoDebitCard = ({
  showChooseBank,
  currency,
  next,
  handleClose,
  handleBack,
}) => {
  const { withdrawalAmount, conversionRate } = useSelector(
    (state) => state?.wallet
  );

  const [amountType, setToggleAmountType] = useState(true);

  const [amount, setAmount] = useState(
    withdrawalAmount?.amount
      ? Number(withdrawalAmount?.amount)?.toFixed(2)
      : withdrawalAmount
      ? Number(withdrawalAmount)?.toFixed(2)
      : 0.0
  );

  const dispatch = useDispatch();

  const walletBalance = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data?.walletBalance
  );
  const dollarBalance = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data?.dollarBalance
  );

  const formattedWalletBalance = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(walletBalance || 0);

  const usdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(dollarBalance || 0);

  const [startTime, setTimeStart] = useState(null);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    dispatch(
      getRate(() => {
        setTimeStart(new Date());
      })
    );
  }, []);

  useEffect(() => {
    let timerId;
    if (startTime) {
      timerId = setInterval(() => {
        const _times = countdown(startTime);
        setTimer(_times);
      }, 1000);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [startTime]);

  const handleRefresh = () => {
    setTimer(null);
    dispatch(
      getRate(() => {
        setTimeStart(new Date());
      })
    );
  };

  const processingFee = useMemo(() => {
    return amountType
      ? amount * 0.05
      : currency === "Naira"
      ? amount * conversionRate * 0.05
      : (amount / conversionRate) * 0.05;
  }, [amount, currency, conversionRate, amountType]);

  return (
    <div
      className={`flex flex-col justify-center items-center border-[1px] border-[#F0F0F5] w-[90%] sm:w-[553px]  mx-auto  rounded-t-[14px] shadow-xl relative bg-white ${
        showChooseBank ? "min-h-[371px]" : "min-h-[571px] "
      }`}
    >
      <div className="absolute p-3 top-3 right-3 cursor-pointer">
        <img
          src={close}
          alt="Close"
          className="w-6 h-6"
          onClick={handleClose}
        />
      </div>
      <div className="flex justify-between items-center  mb-4   w-full px-8 mt-[45px]">
        <div
          className="flex items-center gap-[3px] cursor-pointer"
          onClick={handleBack}
        >
          <LiaAngleLeftSolid size={18} color="#9c9c9e" className="mr-2" />
          <h3 className="text-[14px] font-[400] text-[#9c9c9e]">Back</h3>
        </div>
        <div className="text-[16px] text-[#131e3d] font-[500]">Send</div>
        <div className="w-[20px]"></div>
      </div>
      <div className="flex flex-col justify-center items-center w-full h-full px-8 mb-2">
        <div className="flex bg-[#F8F8F8]  flex-col rounded-[8.92px] h-[121px] w-full justify-center items-center">
          <div className="flex gap-2 mb-1 mt-1 justify-center items-center">
            <img
              src={
                amountType
                  ? currency === "Naira"
                    ? ngr
                    : usd
                  : currency === "Naira"
                  ? usd
                  : ngr
              }
              className="w-[16.42px] h-[16.42px] cursor-pointer"
              alt=""
            />
            <h5>
              {amountType
                ? currency === "Naira"
                  ? "NGN"
                  : "USD"
                : currency === "Naira"
                ? "USD"
                : "NGN"}
            </h5>
          </div>

          <CustomInput
            fullWidth
            noBorder
            bg={true}
            height={81}
            autoComplete={"off"}
            autoFocus={true}
            placeholder={
              amountType
                ? currency === "Naira"
                  ? "₦0.00"
                  : "$0.00"
                : currency === "Naira"
                ? "$0.00"
                : "₦0.00"
            }
            customTextStyle={{}}
            textInputCustomStyles={{
              textAlign: "center",
              fontSize: "48px",
              color: "#131e3d",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
            variant={"standard"}
            selfValue={amount}
            customType="amount"
            prefix={
              amountType
                ? currency === "Naira"
                  ? "₦"
                  : "$"
                : currency === "Naira"
                ? "$"
                : "₦"
            }
            onChange={(e) => {
              setAmount(e.target.value);
            }}
          />
        </div>

        {showChooseBank ? (
          <div className="h-12 w-full"></div>
        ) : (
          <>
            {" "}
            <img
              onClick={() => {
                // if (amountType) {
                //   setAmount(
                //     currency === "Naira"
                //       ? (amount / conversionRate).toFixed(2)
                //       : (amount * conversionRate).toFixed(2)
                //   );
                // } else {
                //   setAmount(
                //     currency === "Naira"
                //       ? (amount * conversionRate).toFixed(2)
                //       : (amount / conversionRate).toFixed(2)
                //   );
                // }
                setToggleAmountType((prev) => !prev);
              }}
              src={toggleIcon}
              className="w-[35.2px] h-[35.2px] z-10 -my-3 cursor-pointer"
              alt=""
            />
            <div className="mb-0 w-full">
              <div className="flex mb-5 bg-[#F8F8F8] gap-2 flex-col rounded-[8.92px] h-[121px] w-full justify-center items-center">
                <div className="flex gap-2 justify-center mt-1 items-center">
                  <img
                    src={
                      amountType
                        ? currency !== "Naira"
                          ? ngr
                          : usd
                        : currency !== "Naira"
                        ? usd
                        : ngr
                    }
                    className="w-[16.42px] h-[16.42px] cursor-pointer"
                    alt=""
                  />
                  <h5>
                    {amountType
                      ? currency !== "Naira"
                        ? "NGN"
                        : "USD"
                      : currency !== "Naira"
                      ? "USD"
                      : "NGN"}
                  </h5>
                </div>

                <h3 className="text-center  text-[48px] font-[400] leading-[52.8px] text-[#131E3D]">
                  {`${
                    amountType
                      ? currency !== "Naira"
                        ? "₦"
                        : "$"
                      : currency !== "Naira"
                      ? "$"
                      : "₦"
                  }${
                    !conversionRate
                      ? "--"
                      : formatAmount(
                          amountType
                            ? currency === "Naira"
                              ? amount / conversionRate
                              : amount * conversionRate
                            : currency === "Naira"
                            ? amount * conversionRate
                            : amount / conversionRate
                        )
                  }`}
                </h3>
              </div>

              <div className="flex  justify-between items-center ">
                <h3 className="   text-[14px] font-[400] text-[#9c9c9e]">
                  1$ ~ ₦{conversionRate ? formatAmount(conversionRate) : "--"}{" "}
                  <span className="text-[14px] font-[400] cursor-pointer text-[#6935D3] ">
                    (?)
                  </span>
                </h3>
                <h3 className="  text-[14px] font-[400] text-[#9c9c9e]">
                  {timer?.difference > 60000 && timer?.sec ? (
                    <span
                      onClick={handleRefresh}
                      className="text-[#6935D3] cursor-pointer"
                    >
                      Refresh
                    </span>
                  ) : (
                    <span className="text-[#6935D3]">
                      Expires in{" "}
                      {timer?.difference > 60000
                        ? "00"
                        : timer?.min
                        ? timer?.min
                        : "--"}
                      :
                      {timer?.difference > 60000
                        ? "00"
                        : timer?.sec
                        ? timer?.sec
                        : "--"}
                    </span>
                  )}
                </h3>
              </div>
              <div className="h-[1.5px] w-full my-2 bg-[#F2F2F7]"></div>

              <div className=" flex justify-between  mb-6  items-center ">
                <div className="flex items-center  gap-[8px]">
                  <img src={sale} alt="" className=" w-[16px] h-[16px] " />
                  <div className=" text-[14px] font-[400] text-[#9C9C9E] ">
                    Processing Fee
                  </div>
                </div>
                <div className=" text-[14px] font-[400] text-[#9c9c9e] ">
                  {currency === "Naira" ? "₦" : "$"}{" "}
                  {processingFee
                    ? formatAmount(processingFee?.toFixed(2), 0)
                    : "0.00"}
                </div>
              </div>
            </div>
          </>
        )}
        <div className="flex mt-[30px] items-center gap-[5px]">
          <img src={wallet} alt="Wallet" className="w-[14px] h-[14px]" />
          <div className="text-[12px] font-[400] text-[#9c9c9e]">
            {currency} Wallet Balance:{" "}
            {currency === "Naira"
              ? formattedWalletBalance?.split(".")[0]
              : usdFormatter?.split(".")[0]}
          </div>
        </div>
        <MainButton
          // mainClasses="!w-[203px] !my-0 !mt-2  !mb-[20px] text-white"
          mainClasses="!w-auto !min-w-[203px] !h-[45px] !mt-2 !mb-5 text-white"
          title={`Send ${currency === "Naira" ? "₦" : "$"}${
            !conversionRate && !showChooseBank
              ? "--"
              : formatAmount(
                  amountType
                    ? amount || 0
                    : currency === "Naira"
                    ? amount * conversionRate
                    : amount / conversionRate
                )
          }`}
          type="submit"
          onClick={
            amount < 1 ||
            (currency === "Naira" && walletBalance < Number(amount)) ||
            (currency !== "Naira" && dollarBalance < Number(amount))
              ? null
              : () => {
                  dispatch(
                    saveWithdrawalAmount({
                      amount: amountType
                        ? amount || 0
                        : currency === "Naira"
                        ? amount * conversionRate
                        : amount / conversionRate,
                      type: "withdrawal",
                    })
                  );
                  next();
                }
          }
          disabled={
            amount < 1 ||
            (currency === "Naira" && walletBalance < Number(amount)) ||
            (currency !== "Naira" && dollarBalance < Number(amount))
          }
        />
      </div>
    </div>
  );
};

export default NoDebitCard;

import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_CALC_AMOUNT_PER_UNIT } from "../../../config/api";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";

export const investNow = createAsyncThunk(
  "investNow/Post",
  async ({ data, cb, failed }, { dispatch }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_CALC_AMOUNT_PER_UNIT,
        data,
      });
      cb();

      return response?.data?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      // const msg = proccessErrorMessage(error);

      // dispatch(
      //   showErrorModal(
      //     msg || error?.response?.data?.message || "An error has occurred"
      //   )
      // );
      failed();
    }
  }
);
